@import "product-list-menu-variables";

.product-list-item-menu {
    &__content {
        background-color: $product-list-menu__background-color;
        box-shadow: $product-list-menu__shadow;
        padding: $product-list-menu__padding;
        position: absolute;
        left: 0;
        z-index: $z-index-low;
    }

    &__items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include mq($screen-s) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include mq($screen-m) {
            grid-template-columns: repeat(6, 1fr);
        }
        @include mq($screen-l) {
             grid-template-columns: repeat(8, 1fr);
        }
        @include mq($screen-xl) {
            grid-template-columns: repeat(10, 1fr);
        }
        a {
            display: block;
        }
    }

    &__item {
        padding: $product-list-menu-item__padding;

        .ratio-container:after {
            background-color: transparent !important;
        }
    }

    &__product-name {
        display: block;
        font-size: $font-size-small;
        margin-top: $spacer;
    }

    &__pager {
        .toolbar {
            &--secondary {
                border-bottom: 0;
                padding: $product-list-menu-item-pager__padding;
            }
        }
    }
}
