$nosto_title                                         : $heading-font-size--page;
$nosto_title\@screen-m                               : $font-size-large;
$nosto_element-margin-top                            : $spacer--small;
$nosto_element-margin-top\@screen-m                  : $spacer--extra-large;
$nosto_element-margin-bottom                         : $spacer--small;
$nosto_element-margin-bottom\@screen-m               : $spacer--extra-large;
$nosto-item__addtocart-top                           : calc(50% - 54px*2 + $spacer--large) !default;
$nosto-item__wrapper-padding                         : $spacer--small  $spacer--medium;
$nosto-item__info-wrapper--font-size                 : $spacer--small;
$nosto-item__slide--padding                          : 0 $spacer--extra-small/2;
$nosto-item__slide-list--margin                      : 0 -$spacer;

$nosto-item__slick-slider-bg                         : $lighter-brown;
$nosto-item__info-wrapper-addtocart--font-size       : $product-grid-item__primary-action--addtocart-fontsize;
$nosto-item__info-wrapper--name--font-size           : $product-grid-item__name-font-size;
$nosto-item__info-wrapper--name-font-weight          : $product-grid-item__name-font-weight;
$nosto-item__info__price-label-font-weight           : $product-grid-item__price-label-font-weight ;

$nosto-item__img-margin-top                          : $spacer--large;
