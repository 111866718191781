$header-style__light-color: $white;
$header-style__light-color-link: $color-primary;

.cms-index-index {
    .header {
        &--light:not(.header--active):not(.header--search_open) {
            color: $header-style__light-color;

            .header__wrapper > .logo {
                svg {
                    path,
                    ellipse {
                        fill: $white;
                    }
                }
            }

            .header__buttons,
            .header__search-toggler,
            .header__search-wrapper {
                // .button,
                // .button--icon,
                .fa-search,
                .header-button,
                .authorization-link a {
                    color: $header-style__light-color;
                }

                .icon-user {
                    fill: $header-style__light-color;
                }
                .icon-bag,
                .button--icon-light,
                .button--icon:hover {
                    svg,
                    [class*="fa-"] {
                        fill: $header-style__light-color;
                        color: $header-style__light-color;
                    }
                }

                .header__dropdown-switcher {
                    .dropdown-list {
                        &__label {
                            color: $header-style__light-color;
                            svg,
                            [class*="fa-"] {
                                fill: $header-style__light-color;
                                color: $header-style__light-color;
                            }
                            &:hover {
                                svg,
                                [class*="fa-"] {
                                    fill: $header-style__light-color;
                                    color: $header-style__light-color;
                                }
                            }
                        }
                        &__content{
                            a{
                                color: $header-style__light-color-link;
                            }
                        }
                    }

                }
            }

            .side-menu {
                .side-menu__trigger,
                .side-menu__trigger:hover {
                    svg,
                    [class*="fa-"] {
                        fill: $header-style__light-color;
                        color: $header-style__light-color;
                    }
                }
            }

            .search-form {
                &__button {
                    color: $header-style__light-color;
                }
                &__input {
                    border-color: $header-style__light-color;
                    @include mq($screen-m) {
                        color: $header-style__light-color;
                        &::placeholder {
                            color: $header-style__light-color;
                        }
                    }
                }

                .button--icon,
                .button--icon:hover {
                    svg,
                    [class*="fa-"] {
                        fill: $header-style__light-color;
                        color: $header-style__light-color;
                    }
                }
            }

            // minicart
            .header-button {
                &__counter,
                &__total {

                    @include mq($screen-m) {
                        border-color: $header-style__light-color;
                        color: $header-style__light-color;
                    }
                }
            }

            .account-links,
            #customer-dropdown .dropdown-list__list {
                &:before {
                    color: $header-style__light-color;
                }
            }
        }
    }
}
