@import "widget-product-variables";

.widget {
    &.block {
        margin: $widget-product-block-margin;

        .block-title {
            text-align: center;
            span {
                font-size: $widget-product-block-title-font-size;
            }
        }

        .block-content {
            width: 100%;
        }

        .banner-items {
            list-style: none;
            padding: 0;
        }
        &-products-list {
            &.grid {
                display        : flex;
                flex-direction : row;
                justify-content: center;

                .product {

                    &-item {
                        &-photo {
                            min-height: $widget-product-list-photo-min-height;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }

                        &-actions {
                            padding: 0;
                            @include mq($screen-m) {
                                padding: $widget-product-list-item-actions-padding;
                            }
                            .button{
                                min-width: $widget-product-list-item-actions-button-min-width ;
                            }

                        }
                    }
                }
            }
        }
    }
}
[data-content-type='products'] {
    .slick-initialized {
        li.product-item {
            display: block;

            &:first-child {
                visibility: visible;
            }

        }
    }
    .widget-product-carousel {
        padding: 0;
    }
    .widget-product-grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        grid-column-gap: 0;
        grid-row-gap: $spacer--extra-small;
        .product-item {
            display: flex;
            justify-content: center;
            padding: 0;
            width: 49%;
            @include mq($screen-m) {
                width: 48%;
            }
            @include mq($screen-l) {
                width: 370px;
            }
            &-photo {
                min-width: 100%;
            }
            &-details {
                text-align: center;
                .price-box {
                    display: flex;
                    padding: $widget-product-list-item-price-padding;
                    min-height: unset;
                    @include mq($screen-s) {
                        min-height: 60px;
                    }
                    .price__wrapper {
                        display: flex !important;
                    }
                }
            }
            &-info {
                width: 100%;
                .ratio-container {
                    padding-bottom: 125% !important;
                    @include mq($screen-xl) {
                        min-height: auto;
                    }
                }
            }
        }
        .product-image-photo {
            width: 90%;
        }
        .actions-secondary {
            display: none !important;
        }
    }

    .product-item {
        &:hover {
            .hover_image {
                display: block;
            }
            .product-item-inner,
            .product-grid-item__addtocart,
            .nosto-add-to-cart-wrapper {
                @include mq($screen-l) {
                    opacity: 0.8;
                }
            }

        }
        &.slick-slide {
            justify-content: center;
            align-items: center;
            text-align: center;
        }
        &-photo {
            min-height: auto;
            width: 150px;
            @include mq($screen-l) {
                width: 240px;
            }
            @include mq($screen-xxl) {
                width: 310px;
            }
        }
        &-details {
            min-height: 95px;
            text-align: center;
            padding   : $widget-product-list-item-details-padding;
            @include mq($screen-s) {
                min-height: 114px;
            }
            @include mq($screen-m) {
                min-height: 75px;
            }
            @include mq($screen-l) {
                min-height: 113px;
            }
            @include mq($screen-xl) {
                min-height: 108px;
                padding: $widget-product-list-item-details-padding\@xl;
            }
            .price-box {
                display: none;
            }
        }
        &-inner{
            display: none;
            opacity: 1;
            min-height: 38px;
            transition: opacity .2s;
            margin-bottom: $spacer--small;
            @include mq($screen-l) {
                display: block;
                opacity: 0;
                position: relative;
                top: -15px;
            }
            @include mq($screen-m) {
                margin-bottom: 0;
            }
            .stock.unavailable {
                padding-top: $spacer;
            }

        }
        &-info {
            background-color: $lighter-brown-alt;
            margin: 0 $spacer--extra-small/2;
            width: auto;
            .ratio-container {
                padding-bottom: 150% !important;
                @include mq($screen-xl) {
                    min-height: 465px;
                }
            }
        }
        &:first-child {
            .product-item-info {
                margin-left: 0;
            }
        }
        &:last-child {
            .product-item-info {
                margin-right: 0;
            }
        }
        &-actions {
            .button{
                min-width: $widget-product-list-item-actions-button-min-width ;
            }

            [data-role='add-to-links'] {
                @include mq($screen-l) {
                    display: none;
                }
            }
            .button--add-to {
                background: unset;
                border: 0 solid transparent;
                font-size : $font-size-small;
                font-weight: $product-grid-item__name-font-weight;
                letter-spacing: 1.5px;
                text-transform: uppercase;
                span {
                    position: relative;
                    &:after {
                        background-color: $color-primary;
                        bottom: -5px;
                        content: '';
                        height: 2px;
                        left: 0;
                        position: absolute;
                        width: 100%;
                        @include mq($screen-m) {
                            height: 1px;
                        }
                    }
                }
            }

            .actions-secondary {
                display: none;
            }

        }
        &-name {
            font-weight: $product-grid-item__name-font-weight;
            font-size: $product-grid-item__name-font-size;
            padding: 0;
            margin: 0;
            height: inherit;
            text-transform: $product-grid-item__name-text-decoration;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            white-space: normal;
            &:hover {
                text-decoration: unset;
            }
            @include mq($screen-m) {
                padding: $widget-product-list-item-name-padding;
            }
            @include mq($screen-l) {
                width: 100%;
            }
            .product-item-link {
                height: 52px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                &:hover {
                    text-decoration: unset;
                }
            }

        }
        &-bottom {
            display: flex;
            height: auto;
            justify-content: space-between;
            flex-direction: column;
            text-align: left;
            min-height: 44px;
            @include mq($screen-m) {
                flex-direction: row;
            }
            > div > .price__value {
                display: none;
            }
            > div:nth-child(2) {
                min-height: 19.5px;
            }
            .price-box {
                * {
                    font-weight: $product-grid-item__name-font-weight;
                }
            }
            span {
                font-size: $product-grid-item__name-font-size;
                text-align: left;
                color: $color-secondary;
                font-weight: $font-weight-light;
                &.product-item-bottom-print {
                    width: 100%;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    white-space: normal;
                }
            }
        }
        .price-box.price-final_price {
            display: flex;
            .price {
                &__wrapper {
                    display: flex !important;
                    flex-flow: row nowrap;
                    @include mq($screen-s) {
                        flex-flow: column nowrap;
                    }
                }
                &__value {
                    font-size: $font-size-small-xs;
                    font-weight: $product-grid-item__price-label-font-weight;
                    margin: 0;
                    text-align: right;
                    padding-right: $spacer--extra-small;
                    @include mq($screen-s) {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

