
.customer-account-login,
.customer-account-create {
    .header__wrapper {
        width: 200px;
        justify-content: center;

        .header__logo {
            margin-left: 0px;
        }
    }
}

.customer-account-login,
.customer-account-create {
    .page-main.container {
        margin-left: 30px;
        margin-right: 30px;
        @include mq($screen-xl) {
            margin: 0px auto;
        }
        @include mq($screen-m) {
            margin: 0px auto;
        }
    }

    .account-form {
        &__info {
            &.clientes-registrados,
            &.customer-register {
                font-size: $font-size-medium;
                margin-top: 100px;
                margin-bottom: 0px;
                @include mq($screen-m) {
                    font-size: $font-size-extra-large;
                    margin-top: 200px;
                    margin-bottom: 0px;
                }
            }
        }

        &__info {
            &.sign-in {
                font-size: $font-size-small;
                margin-top: 0px;
                margin-bottom: 45px;

                @include mq($screen-xl) {
                    font-size: $font-size-base;
                    margin-top: 24px;
                    margin-bottom: 38px;
                }
                @include mq($screen-m) {
                    font-size: $font-size-base;
                    margin-top: 24px;
                    margin-bottom: 38px;
                }

            }

        }

        input {
            color: $font-color-base;
            border-color: $color-primary;
        }

        label {
            color: $font-color-base;
            font-size: $font-size-small ;
            @include mq($screen-xl) {
                font-size: $font-size-base;
            }
            @include mq($screen-m) {
                font-size: $font-size-base;
            }

            &:after {
                color: $color-primary !important;
            }
        }

        &__actions {
            .button {
                color: white;
                font-size: $font-size-small;
                text-transform: uppercase;
                min-width: unset;
                width: 80%;
                &:before {
                    background-color: $brown;
                    transition: none;
                }

                @include mq($screen-xl) {
                    margin-right: 70px;
                }
                @include mq($screen-m) {
                    margin-right: 70px;
                    min-width: 129px;
                    width: 129px;
                }
            }

            @include mq($screen-xl) {
                flex-direction: row;
            }
            @include mq($screen-m) {
                flex-direction: row;
            }
            margin-top: 0px;

        }

        button {
            background-color: $color-primary ;
            border: 1px solid $color-primary ;

            &.button--icon {
                &:hover {
                    .icon {
                        fill: $color-primary-light;
                    }
                }
            }

        }

        &__fieldset {
            margin: 0px 0px 20px;
        }

        &__forgot-password {
            color: $font-color-base;
            font-size: $font-size-extra-small;
            font-weight: normal;
        }

        &__validation-tips {
            @include mq($screen-xl) {
                margin-right: 250px;
            }
            @include mq($screen-m) {
                margin-right: 250px;
            }
            font-size: $font-size-extra-small;
        }

    }
}

.customer-account-create {
    .account-form {
        &__actions {
            .button {
                @include mq($screen-m) {
                    width: auto !important;
                    max-width: 200px !important;
                }

            }
        }
        .input {
            .input__button-pass {
                top: 26px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                position: absolute;
                @include mq($screen-m) {
                    top: 30px;
                }
            }
        }
    }
}
