.footer {
    margin: $spacer--semi-medium $spacer--semi-medium 0 $spacer--semi-medium ;
    @include mq($screen-xl) {
       margin: $spacer--semi-medium 0 0 0;
    }
    &__social {
        margin-bottom: $spacer;
        order: 0;
        text-align: center;
        width: 100%;
        @include mq($screen-m) {
            margin-bottom: 0;
        }
        @include mq($screen-xl) {
            order: 1;
        }
        .pagebuilder-column-group {
            flex-wrap: nowrap;
            z-index: 0;
            .pagebuilder-column {
                max-width: unset;
                z-index: 9999;
                @include mq($screen-m) {
                    max-width: 250px;
                }
                &:hover {
                    background-color: $color-primary;
                    transition: opacity 0.3s ease-in-out;
                    a {
                        color: $lighter-brown-alt;
                    }
                }
                a {
                    text-decoration: none;
                    padding: $spacer $spacer--small;
                    text-align: center;
                    font-weight: $font-weight-light;
                }
            }

        }
    }

    &__links {

        .dropdown-list {
            justify-content: space-around;
            padding: 0;
            &__label {
                font-size: $font-size-base;
                font-weight: $font-weight-medium;
                padding: 0 0 10px 0;
                text-transform: none;
                &:hover{
                    color: $color-primary;
                }
            }
            &__item {
                @include mq($screen-m) {
                    width: $footer__links-dropdown-list-item-width-first-child\@medium;
                }
            }

            &__icon {
                text-align: right;
                transform: unset !important;
            }
            a.footer__link,
            .footer__links-list p
            {
                text-transform: lowercase;
                font-weight: $font-weight-light;
            }
            &__list {
                @include mq($max-screen: $screen-xl - 1) {
                    flex-flow: row wrap !important;
                }
            }
        }
    }

    .logo-image-footer {
        width: 146px;
        height: 28px;
    }

    &__handler {
        padding: 0;
    }

    &__newsletter {
        border: unset;
        border-width: unset;
        margin: 0;
        //display: none;
        @include mq($screen-m) {
            display: block;
            width: 100%;
            padding: 0;
            margin: 0 0 $spacer--semi-medium 0 $spacer--semi-medium*2;
        }
    }

    &__logo {
        display: flex;
        flex-direction: column-reverse;
        margin: $spacer--medium 0;
        justify-content: center;
        align-items: center;
        margin-bottom: $spacer--large;
        gap: $spacer--semi-medium;
        @include mq($screen-m) {
            flex-direction: row;
            justify-content: flex-start;
            margin-bottom: $spacer--medium *2 ;
            white-space: pre-wrap;
            gap: $spacer--extra-large + $spacer;
        }
    }
    .dropdown-list__item {
        border-bottom: 0px;
    }
    &__bottom-bar {
        order: 1;
    }
    &__bottom-bar-handler{
        .footer_link {
            font-size: $font-size-small;
        }

        &.container {
            margin: 0 $spacer--medium;
            justify-content: center;
            @include mq( $screen-m) {
                margin: 0px 44px;

            }
        }
    }

    &__bottom_links-list a,p {
        font-size: $font-size-small;
        @include mq($screen-xl) {
            font-size: $font-size-base;
        }
        @include mq($screen-m) {
            font-size: $font-size-base;
        }

    }

    &__bottom_links-list {
        &.list{
            &--horizontal{
                flex-flow: row wrap;
            }

            .list__item {
                padding-top: 0px;
                padding-bottom: 0px;
                &:last-child {
                    width: 100%;
                    text-align: center;

                    @include mq($screen-xl) {
                        width: auto;
                    }
                    @include mq($screen-m) {
                        width: auto;
                    }

                }

                @include mq($screen-xl) {
                    padding: 0px;
                    &:nth-child(2){
                        padding-right: 0px ;
                        a {
                            &:after{
                                content: "|";
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                        }
                    }
                    &:nth-child(4){
                        padding-right: 0px ;
                        a {
                            &:after{
                                content: "|";
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                @include mq($screen-m) {
                     padding: 0px;
                    &:nth-child(2){
                        padding-right: 0px ;
                        a {
                            &:after{
                                content: "|";
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                        }
                    }
                    &:nth-child(4){
                        padding-right: 0px ;
                        a {
                            &:after{
                                content: "|";
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                        }
                    }

                }

                &:nth-child(1){
                    padding-right: 0px ;
                    p{
                        white-space: nowrap;
                    }
                }
                &:nth-child(2){
                    padding-left: 0px ;
                }
                &:nth-child(3){
                    padding-right: 0px ;
                    a {
                        &:after{
                            content: "|";
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                    }
                }
                &:nth-child(4){
                    padding-left: 0px ;
                }

                &:first-child p  {
                    &:after{
                        content: "|";
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                }



            }
        }
    }

    &__link {
        font-size: $font-size-base;
        font-weight: $font-weight-medium ;
    }

    &__bottom_links-list {
        justify-content: center;
    }
    &__contact-sede{
        margin-bottom: $spacer;
        &-vat,
        &-schedule{
            font-weight: $font-weight-extra-light;
            font-size: $font-size-small;
        }
    }
    @include mq($screen-m) {
        .dropdown-list__content {
            .list__item {
                padding: 0 0 10px;
            }
        }
    }

    @include mq($screen-xl) {
        .dropdown-list__content {
            .list__item {
                padding: 0 0 10px;
            }
        }
    }
    &.margin-top-sm {
        @include mq($max-screen: $screen-m ) {
            display: flex;
            flex-direction: column;
            .footer__logo {
                order: 0;
                .container-logo {
                    order: 1;
                }
            }
            .footer__handler {
                order: 1;
            }
        }
    }
}
body{
    >small.copyright{
        display: none !important;
    }
}
