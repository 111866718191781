$product-list-item-padding-left  : $spacer--semi-medium;
$product-list-item-padding-right : $spacer--semi-medium;

$product-list-item-padding-left\@medium  : $spacer--semi-large;
$product-list-item-padding-right\@medium : $spacer--semi-large;

$product-list-item-info-left  : $spacer 0;
$product-list-item-info-right : $spacer 0 $spacer $spacer--medium;

$product-list-item--in-stock__color : #1c6913;

$product-list-item-info-right__font-size        : $font-size-base;
$product-list-item__child-name-font-size        : $font-size-small;

$product-list-item-products__padding\@xlarge: $spacer--medium*2 $spacer--medium*4;
$product-list-item-products__padding\@medium: $spacer--medium $spacer--medium*2;
$product-list-item-products__padding        : 0;

$product-list-item-product__padding : $spacer--medium*2 $spacer--medium*4;

$product-list-item-product__width     : auto;
$product-list-item-product__min-width : auto;

$product-list-item__description-margin-bottom: 0; //$spacer--medium*2;

$product-list-item-product__img-max-height : 712px;
$product-list-item-product__stage-img-max-height : $product-list-item-product__img-max-height;
$product-list-item-product__stage-img-min-height : auto;

$product-list-item-product__block__data-img-width\@medium   : 45%;
$product-list-item-product__block__products-width\@medium   : 55%;

$product-list-item-products__details-padding\@xlarge         : 0 36px 0 0;
$product-list-item-products__details-padding\@medium         : 0 $spacer--large 0 0;
$dropdown-list__content-container-box-shadow                 : $shadow ;// 1px 4px 9px 5px rgba(0,0,0,0.32);

$value-img-tooltip-width                                     : 135px;