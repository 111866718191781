@import "extend-variables";
@import "message/message";
$radio__label-color: $brown;
.checkout-index-index {

    button:not(.primary),
    button.action:not(.primary),
    button.action.action-cancel,
    button.action.button--secondary,
    .button {
        color: $button__color-hover--link;
        background: $white;
        border-color: $button__color-hover--link;

        &:hover,
        &.focus-visible {
            color: $button__color-hover--link;
            background:  $button__background-hover--link;
            border-color: $button__color-hover--link;
            &:before {
                display: $button__before-display-hover--link;
            }
            &:after {
                display: $button__after-display-hover--link;
            }
        }
    }
    .primary button.primary {
        color: $button__color--secondary;
        background:  $button__background--secondary;
        &:hover {
            &:before {
                display: none;
            }
        }
    }
    .field-select-billing {
        .control {
            @extend .select;
            @extend .select--native;
        }
    }
    select {
        @extend .select;
        @extend .select--native;
        border: 1px solid $color-primary !important;
    }
    .payment-method-title{
        color: $radio__label-color              ;
    }
    .payment-method-content > p > br:nth-child(1) {
        display: none;
    }
    #checkout-payment-method-load {
        & > div > div > div.payment-method._active {
            & > div.payment-method-content > p {
                margin: $spacer--medium 0;
            }
        }
        .payment-methods {
            @include mq($screen-l) {
                border-top: 1px solid $color-primary;
                margin-top: 40px;
            }
        }
    }
    textarea{
        border: 1px solid $color-primary ;
        font-family: $font-family-sans-serif;
    }

    .header__buttons{
        justify-content: flex-end;
        @include mq($max-screen: $screen-s) {
            padding-top: $spacer--small;
        }
        a.header-button.button{
            display: none;
        }
        .header__switchers {
            @include mq($max-screen: $screen-s) {
                margin-left: $spacer--small;
            }
        }
        .header__dropdown-switcher {
            @include mq($max-screen: $screen-s) {
                margin-right: 0 !important;
            }
        }
        .dropdown-list__label {
            background: transparent;
            @include mq($max-screen: $screen-s) {
                padding-bottom: 0;
                padding-top: 0;
                padding-right: $spacer--small;
            }
            .dropdown-list__icon {
                @include mq($max-screen: $screen-s) {
                    right: -10px;
                }
            }
        }
    }
    .header--search_open{
        box-shadow: unset;
    }
    .modal-custom{
      &.opc-summary-wrapper.sidebar-checkout{
          &._show{
              z-index: 1001 !important;
          }
      }
    }
    .shipping-address__form-shipping,
    .modals-wrapper {
        .account-form__fieldset {
            max-width: unset;
        }

    }
    .step-title{
        font-size: $font-size-medium;
        margin: $spacer--medium 0;
    }

    .label{
        color: $color-primary ;
    }
    .radio{
        &__label {
            &:before {
                width: $radio-label__size;
                min-width: $radio-label__size;
                height: $radio-label__size;
            }

            &:after {
                width: $radio-label__dot-size;
                height: $radio-label__dot-size;
                border-radius: $radio-label__dot-size;
                top: 5px;
                left: 5px;
            }
        }
    }
    .progress-bar{
        &__item {
            &--active {
                .progress-bar__line{
                    &:after{
                        color: $color-primary ;
                    }
                }
            }
            &--complete{
                .progress-bar__line{
                    &:after{
                        color: $gray-light ;
                    }
                }
            }
        }

        &__label{
            font-size: $font-size-extra-large;
        }
    }

    #checkout-step-shipping{
        .shipping-address{
            &-item{
                &:not(.not-selected-item){
                    .action-select-shipping-item{
                        display: none;
                    }
                }
            }

        }
    }

    .estimated-section{
        &__counter{
            background-color: $color-primary ;
        }
        .estimated-section__trigger-button{
            background-color: $bg-color-body;
        }
    }

    .order-summary {
        background-color: transparent;
        &__list-item{
            .order-summary__label{
                font-size: $font-size-base;
            }
        }

        &__total{
            .order-summary{
                &__title-total,
                &__amount{
                    font-size: $font-size-medium;
                }
            }
        }
        .totals-tax{
            width: 100%;
            display: inline-flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__product-qty{
            color: $color-primary ;
            span{
                font-size: $font-size-small;
            }
        }

        &__tab-icon{
            color: $color-primary;
        }
        .minicart-product__attributes-list{

            .list__label,
            .list__value{
                color: $color-primary;
            }
        }
        .action.viewcart{
            margin-top: $spacer--large;
        }
    }

    #discount-form{
        .actions-toolbar{
            .action-cancel{
                border: 1px solid $color-primary !important;
                color: $color-primary !important;
            }
        }
    }

    .table-checkout-shipping-method{
        tbody td{
            padding: $spacer ;
            &.col-method{
                display: none;
            }
        }
    }
    #onepage-checkout-shipping-method-additional-load{
        margin-top: $spacer--semi-large;
    }

    .shipping-information__title{
        .button--icon{
            border-color: transparent;
            background: transparent;
        }
    }
    .discount-code__button-text,
    .shipping-information__title-text{
        font-size: $font-size-medium;
    }

    #dropdown-customer-content,
    #dropdown-language-content{
        position: absolute;
        padding: $spacer;
        background-color: $gray-white;
    }

    svg{
        &.modal__close-button-icon,
        &.estimated-section__icon,
        &.order-summary__tab-icon,
        &.discount-code__button-icon{
            filter: invert(56%) sepia(29%) saturate(640%) hue-rotate(342deg) brightness(88%) contrast(85%);
        }
    }

    .footer__bottom_links-list{
        .list__item{
            &:first-child,
            &:last-child{
                padding-top: 0 ;
                padding-bottom: 0;
            }
        }
    }

    #backsoon-type{
        td{
            &.mage-error-container{
                border: none;
            }
        }
    }

    .opc-wrapper .edit-address-link {
        position: relative;
        right: unset;
        top: unset;
    }

    .form-shipping-address, .billing-address-form {
        #shipping-new-address-form, form {
            .control._with-tooltip {
                input {
                    width: 100%;
                }
                .field-tooltip.toggle {
                    display: none;
                }
            }
        }
    }

    .billing-address-details .label{
        font-weight: bold;
    }

    .header__wrapper.full-width {
        z-index: 1;
    }
}


//
//  Shipping Methods
//  _____________________________________________

.checkout-shipping-method {
    .actions-toolbar {
        margin-top: $spacer--semi-medium;
    }
}

.form.payments {
    .fieldset > hr {
        display: none;
    }
}
