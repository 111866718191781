@import 'product-view-variables';

.product-view {
    padding: $product-view__padding;

    @include mq($screen-m) {
        padding: $product-view__padding\@medium;
    }

    @include mq($screen-xl) {
        padding: $product-view__padding\@xl;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin: $product-view__wrapper-margin;
        max-width: $product-view__wrapper-max-width;

        @include mq($screen-m) {
            display: grid;
            grid-template-columns: $product-view__wrapper-grid-template-columns\@medium;
            grid-template-rows: $product-view__wrapper-grid-template-rows\@medium;
            //column-gap: $product-view__column-gap\@medium;
        }
    }

    &__description,
    &__details {
        position: relative;
        @include mq($screen-m) {
            grid-column-start: $product-view__details-desc-grid-column-start\@medium;
            width: $product-view__details-desc-width\@medium;
        }

        @include mq($screen-l) {
            width: $product-view__details-desc-width\@large;
        }

        @include mq($screen-xl) {
            width: $product-view__details-desc-width\@xl;
        }
    }

    &__description {
        order: $product-view__desc-order;
    }

    &__details {
        order: $product-view__details-order;
        @include mq($screen-m) {
            grid-row-start: $product-view__details-grid-row-start\@medium;
            &.product-view__sticky {
                height: 100vh;
                display: flex;
            }
            &-content {
                position: absolute;
                top: calc(50% - 64px);
                transform: translateY(-50%);
                width: 100%;
            }
        }
        &-content {
            &-price-stock {
                display: flex;
                align-items: center;
                padding: $spacer 0 $spacer--medium 0;
                @include mq($screen-m) {
                    padding: 0;
                }

                > div {
                    margin-right: $spacer--medium;
                    &.extra {
                        font-weight: $font-weight-light;
                        display: flex;
                        align-items: center;
                        &.nob_salable_info {
                            color: $color-secondary;
                        }
                        .check-icon {
                            display: none;
                            width: $spacer--small;
                            height: $spacer--small;
                            margin-bottom: $spacer--extra-small;
                            margin-right: $spacer--extra-small;
                            @include mq($screen-m) {
                                width: $spacer--medium;
                                height: $spacer--medium;
                            }
                        }
                    }
                }
                .price__wrapper {
                    display: flex;
                }
                .back-date {
                    font-weight: $font-weight-light;
                }
            }
            &-color {
                text-transform: lowercase;
                font-size: $font-size-medium;
                padding: 0;
                font-weight: $font-weight-light;
                color: $color-secondary;
            }
            &-info {
                @include mq($max-screen: $screen-s) {

                    .product-view__main-actions {
                        padding: $spacer;
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        z-index: 1;
                        background-color: $bg-color-body;
                        border-top: 1px solid;

                        div.select {
                            padding-left: $spacer--semi-medium ;
                            margin-bottom: 0;
                            .label {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    &__gallery {
        position: relative;
        margin: $product-view__gallery-margin;
        order: $product-view__gallery-order;

        @include mq($screen-m) {
            grid-column-start: $product-view__gallery-grid-column-start\@medium;
            grid-row-start: $product-view__gallery-grid-row-start\@medium;
            grid-row-end: $product-view__gallery-grid-row-end\@medium;
        }
        .gallery {
            ul {
                li {
                    background-color: $bg-color-alt;
                }
                .slick-arrow {
                    display: none !important;
                }
            }
        }
    }

    &__badges {
        z-index: $product-view__badges-z-index;
        top: $product-view__badges-top;
        left: $product-view__badges-left;

        @include mq($screen-m) {
            top: $product-view__badges-top\@medium;
            left: $product-view__badges-left\@medium;
        }

        @include mq($screen-l) {
            top: $product-view__badges-top\@large;
            left: $product-view__badges-left\@large;
        }

        @include mq($screen-xl) {
            left: $product-view__badges-left\@xl;
        }
    }

    &__title {
        &.heading--page {
            margin: $product-view__title-margin;
            text-transform: uppercase;
            font-size: $product-view__title-font-size;
            @include mq($screen-m) {
                font-size: $product-view__title-font-size\@medium;
                letter-spacing: $product-view__title-letter-spacing;
            }

            @include mq($screen-l) {
                font-size: $product-view__title-font-size\@large;
            }
        }
    }

    &__sku {
        display: $product-view__sku-display;
        font-weight: $product-view__sku-font-weight;
        color: $product-view__sku-color;

        @include mq($screen-m) {
            display: block;
        }
    }

    &__stock {
        border: 0 solid;
        min-width: $product-view__stock-min-width;
        min-height: $product-view__stock-min-height;
        padding: $product-view__stock-padding;
        font-weight: $product-view__stock-font-weight;
        white-space: $product-view__stock-white-space;
        font-size: $product-view__stock-font-size;
        color: $product-view__stock-color;
        text-transform: $product-view__stock-text-transform;
        text-decoration: $product-view__stock-text-decoration;

        &:before,
        &:after {
            display: none;
        }

        &:hover {
            color: $product-view__stock-color-hover;
            text-decoration: $product-view__stock-text-decoration-hover;
        }

        @include mq($screen-m) {
            font-size: $product-view__stock-font-size\@medium;
        }
    }

    &__rating {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: $product-view__rating-padding;
        border: $product-view__rating-border;
        border-width: $product-view__rating-border-width;
    }

    &__price {
        // display: $product-view__price-display;
        padding: 0;
        @include mq($screen-m) {
            padding: $product-view__price-padding;
        }
        @include mq($product-view__price-display-mq) {
            display: $product-view__price-display\@display-mq;
            min-width: 70px;
        }
        .price__value,
        .price__value--special {
            font-size: $font-size-medium ;
        }

        .price__value--old {
            font-size: $font-size-medium;
        }
    }

    .price--between {
        display: $product-view__price-display--between;
        flex-direction: $product-view__price-flex-direction--between;
        line-height: $product-view__price-line-height--between;

        .price__text {
            display: $product-view__price-text-display--between;
            min-width: $product-view__price-text-min-width--between;
        }
    }

    &__short-description {
        text-align: justify;
        text-align-last: left;
        font-weight: $font-weight-light;

        &-heading {
            font-family: $product-view__short-description-heading-font-family;
            font-size: $product-view__short-description-heading-font-size;
            font-weight: $product-view__short-description-heading-font-weight;
            color: $product-view__short-description-heading-color;
            text-transform: $product-view__short-description-heading-text-transform;
        }
        p {
            text-align: justify;
            text-align-last: left;
            font-weight: $font-weight-light;
        }
    }

    &__buttons-wrapper {
        &--ee {
            .product-view__extra-actions {
                display: flex;
            }

            .product-view__update-wishlist {
                order: 4;
            }

            .towishlist {
                display: none;
                min-width: 10%;
                width: auto;
                @include mq($screen-m) {
                    display: flex;
                }
                &:hover {
                    .icon {
                        stroke: $button__border;
                    }
                }
                .icon {
                    margin: 0;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        width: 100%;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: center;
        @include mq($screen-s) {
            justify-content: flex-start;
        }
        .dropdown-list__label {
            &.button--icon-filled {
                &:after {
                    @include mq($screen-m) {
                        left: $product-view__dropdown-wishlist-button-after-left;
                    }
                }
            }
        }

        .paypal {
            margin: 0 $spacer--medium 0 0;
        }
        &-wrapper {
            display: flex;
            justify-content: flex-start;
        }
    }

    &__extra-actions {
        display: none;
        align-items: center;
        justify-content: space-between;
        width: $product-view__extra-actions-width;

        @include mq($screen-m) {
            display: flex;
            width: $product-view__extra-actions-width\@medium;
        }
    }

    &__button {
        width: $product-view__button-width;
        background-color: $product-view__button-bg;
        order: 2;

        @include mq($screen-m) {
            margin: $product-view__button-margin\@medium;
            width: $product-view__button-width\@medium;
            min-width: $product-view__button-min-size;
            min-height: $product-view__button-min-size;
        }

        .button__text {
            @include mq($screen-m) {
                display: none;
            }
        }

        &--wishlist {
            display: none;
            @include mq($screen-m) {
                display: block;
            }
            &.button--icon-filled {
                @extend .button--icon-fill;
                @include button-fill-bg();
                background-color: $product-view__button-bg;

                &:hover {
                    &:before {
                        background-color: $product-view__button-wishlist-filled-bg-color-hover;
                    }

                    &:after {
                        display: block;
                        background-image: $product-view__button-wishlist-filled-bg-image-after-hover;
                    }

                    .icon {
                        fill: $color-primary;
                    }
                }
            }
        }

    }

    &__extra-actions {
        order: $product-view__extra-actions-order;
    }

    &__addtowishlist {
        display: block;
        width: $product-view__button-wishlist-width;
        min-height: $product-view__button-wishlist-min-height;
        min-width: $product-view__button-wishlist-min-width;
        background-color: $product-view__button-wishlist-background-color;
        order: $product-view__update-wishlist-order;

        @include mq($screen-m) {
            display: flex;
            width: $product-view__button-wishlist-width\@medium;
            margin: $product-view__button-wishlist-margin\@medium;
        }

        .button__text {
            display: none;
        }

        &--update {
            display: flex;

            .button__text {
                display: inline-block;
            }
            &:hover {
                .button__text {
                    color: $color-primary;
                }
            }
        }
    }

    &__update-wishlist {
        width: $product-view__update-wishlist-width;
        height: $product-view__update-wishlist-height;
        margin: $product-view__update-wishlist-margin;
        order: $product-view__update-wishlist-order;

        @include mq($screen-m) {
            margin: $product-view__update-wishlist-margin\@medium;
        }
    }

    &__requisition,
    &__multiwishlist {
        width: $product-view__dropdown-wishlist-width;
        margin: $product-view__dropdown-wishlist-margin;

        @include mq($screen-m) {
            margin: $product-view__dropdown-wishlist-margin\@medium;
        }

        .button {
            width: $product-view__dropdown-wishlist-button-width;
            &__text {
                color: $color-primary !important;
                line-height: normal;
                &:hover {
                    color: $color-primary !important;
                }
            }
            &__icon {
                fill: $color-primary;
                &:hover {
                    color: $color-primary !important;
                }
            }

        }

        .dropdown-list {
            &__text {
                color: $color-primary;
            }
            &__icon {
                fill: $color-primary;
            }
            &__label {
                background-color: transparent;
                justify-content: flex-start;

                @include mq($screen-m) {
                    justify-content: center;
                }
            }
            &--buttons {
                .dropdown-list__label {
                    border: 1px solid transparent;
                    &:hover .icon {
                        fill: $color-primary !important;
                    }
                    &[aria-expanded="true"]{
                        background-color: transparent !important;
                        border: 1px solid $color-primary !important;
                        .button__icon,
                        .button__text {
                            color: $color-primary!important;
                            fill: $color-primary!important;
                        }
                    }
                }
            }

            &__content {
                border: 1px solid transparent;
                @include mq($screen-m) {
                    min-width: $product-view__dropdown-wishlist-content-min-width\@medium;
                }

                @include mq($screen-l) {
                    min-width: $product-view__dropdown-wishlist-content-min-width\@large;
                }
            }
        }
    }

    &__requisition {
        order: $product-view__requistion-dropdown-order;

        @include mq($screen-m) {
            width: $product-view__requistion-dropdown-width\@medium;
        }

        @include mq($screen-l) {
            width: $product-view__requistion-dropdown-width\@large;
        }

        .dropdown-list {
            &__content {
                right: $product-view__requistion-dropdown-content-right;
                left: auto;
            }
        }
    }

    &__multiwishlist {
        order: $product-view__multiwishlist-dropdown-order;

        @include mq($screen-m) {
            width: $product-view__multiwishlist-dropdown-width\@medium;
        }

        @include mq($screen-l) {
            width: $product-view__multiwishlist-dropdown-width\@large;
        }

        .dropdown-list {
            &__item {
                @include mq($screen-l) {
                    padding: $product-view__multiwishlist-dropdown-item-padding\@large;
                }
            }
        }
    }

    &__add-to-cart,
    &__update-cart {
        @extend .button--secondary;
        display: $product-view__add-to-cart;
        order: $product-view__order;
        flex-grow: $product-view__flex-grow;
        height: $product-view__add-to-cart-height;
        padding: $product-view__add-to-cart-padding;

        @include mq($product-view__buttons-mq-display) {
            display: $product-view__add-to-cart\@buttons-mq-display;
            max-width: 320px;
        }
        .button__text {
            font-size: $font-size-extra-medium;
            font-weight: $font-weight-medium;
            letter-spacing: $product-view__title-letter-spacing;
            text-transform: uppercase;
            padding-top: $spacer--extra-small;
            @include mq($screen-m) {
                padding-top: $spacer--small/2;
            }
        }
    }
    &__add-to-cart {
        .button__text {
            display: block;
        }
    }
    &__select-option {
        .select__field-list--single,
        .select__field-item {
            height: $product-view__select-option-height;
            line-height: $product-view__select-option-height;
        }
    }

    &__options {
        margin: $product-view__options-margin;
        .select {
            width: 100%;
            @include mq($screen-m) {
                max-width: 50%;
            }
            > label {
                display: none;
            }
        }
        .swatch{
            &.print {
                .swatch__title {
                    display: none;
                }
            }
        }

        &.margin-bottom-m {
            margin-bottom: 0;
            .select {
                margin-bottom: 0;
            }
            padding: $product-view__content-padding;
            @include mq($screen-m) {
                margin-bottom: initial;
                padding: 0;
            }
        }
    }

    &__qty {
        margin: $product-view__qty-margin;
        min-height: $product-view__qty-height;

    }

    &__stock-status {
        margin: $product-view__stock-status-margin;
        @include mq($screen-m) {
            margin: $product-view__stock-status-margin\@medium;
        }
    }

    &__brief {
        margin: $product-view__brief-margin;

        @include mq($screen-m) {
            margin: $product-view__brief-margin\@medium;
        }
        .brief-info {
            display: block;

            &__title {
                text-transform: $product-view__brief-title-text-transform;
                font-weight: $product-view__brief-title-font-weight;
                font-size: $product-view__brief-title-font-size;
            }

            &__subtitle {
                font-size: $product-view__brief-subtitle-font-size;
                text-transform: none;
            }

            &__item {
                margin: $product-view__brief-item-margin;
            }
        }
    }

    &__form {
        padding: 0;
        border: $product-view__form-border;
        border-width: $product-view__form-border-width;
        @include mq($screen-m) {
            padding: $product-view__form-padding;
        }

        .select2 {
            .select2-selection {
                &__rendered {
                    background-color: $bg-color-body;
                    color: $color-primary;
                    padding: $product-view__select-rendered-padding;
                }
                &--single {
                    border: unset;
                    @include mq($screen-m) {
                        background-color: $bg-color-body;
                    }
                }
                &__arrow {
                    b {
                        border-width: $product-view__select-label-arrow-border-width;
                        margin-top: $product-view__select-label-arrow-margin-top;
                    }
                }
            }
            &-container {
                &--open {
                    .select2-selection {
                        &__arrow {
                            b {
                                border-width: $product-view__select-label-arrow-border-width;
                                margin-top: $product-view__select-label-arrow-margin-top;
                            }
                        }
                        &--single {
                            outline: unset;
                        }
                    }
                }
            }
        }
    }

    &__summary {
        width: $product-view__summary-width;
        padding: $product-view__summary-padding;
        margin: $product-view__summary-margin;
        font-size: $font-size-medium;


        @include mq($screen-m) {
            margin: $product-view__summary-margin\@medium;
        }
    }

    &__summary-price {
        display: flex;
        align-items: center;
        margin: $product-view__summary-price-margin;
        font-weight: $product-view__summary-price-font-weight;
        .price__value {
            font-size: $product-view__summary-price-font-size;
        }
    }

    &__summary-unit {
        padding: $product-view__summary-unit-padding;
        font-size: $product-view__summary-unit-font-size;
    }

    &__summary-list {
        font-size: $product-view__summary-list-font-size;
    }

    &__summary-title {
        margin: $product-view__summary-title-margin;
        font-family: $product-view__summary-title-font-family;
        font-size: $product-view__summary-title-font-size;
        font-weight: $product-view__summary-title-font-weight;
        text-transform: $product-view__summary-title-text-transform;
    }

    &__summary-item-title {
        display: block;
        font-weight: $product-view__summary-list-item-title-font-weight;
        text-transform: $product-view__summary-list-item-title-text-transform;
    }

    &__summary-item-value {
        color: $product-view__summary-list-item-value-color;
    }

    &__customization {
        border: $product-view__customization-border;
        border-width: $product-view__customization-border-width;
        padding: $product-view__customization-padding;
        margin: $product-view__customization-margin;
    }

    // grouped product, overwrite default table styles
    &__grouped-table {
        margin: $product-view__grouped-table-margin;
        border-width: $product-view__grouped-table-border-width;

        & th,
        & td {
            border-right: $product-view__grouped-cell-border-right;
            justify-content: flex-end;

            &.product-view__grouped-product-name-cell {
                justify-content: space-between;
                @include mq($screen-m) {
                    display: table-cell;
                }
                &:before {
                    display: none;
                }
            }
        }

        & th,
        & td:before {
            padding: $product-view__grouped-th-padding;
            font-size: $product-view__grouped-th-font-size;
            font-weight: $product-view__grouped-th-font-weight;
            background-color: $product-view__grouped-th-bg-color;
            text-align: $product-view__grouped-th-align;
            @include mq($screen-m) {
                text-align: $product-view__grouped-th-align\@medium;
            }
        }

        & td {
            padding: $product-view__grouped-td-padding;
            vertical-align: $product-view__grouped-td-vertical-align;
        }
    }

    &__grouped-product-qty-td {
        padding-left: $product-view__grouped-td-qty-padding;
    }

    &__grouped-product-name {
        display: block;
        font-weight: $product-view__grouped-td-name-font-weight;
        @include mq($screen-m) {
            margin: $product-view__grouped-td-name-margin\@medium;
        }
    }

    &__grouped-qty {
        max-width: $product-view__grouped-qty-field-max-width;
        .input__field {
            text-align: $product-view__grouped-qty-field-align;
            border-color: $product-view__grouped-qty-field-border-color;
        }
    }

    // tabs
    &__tabs {
        max-width: $product-view__wrapper-max-width;
        margin: $product-view__tabs-margin;

        @include mq($screen-m) {
            margin: $product-view__tabs-margin\@medium;
            padding: $product-view__tabs-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $product-view__tabs-padding\@large;
        }

        .tab__title {
            height: $product-view__tabs-title-height;
            padding: $product-view__tabs-title-padding;
            text-align: $product-view__tabs-title-text-align;

            @include mq($screen-m) {
                padding: $product-view__tabs-title-padding\@medium;
            }

            @include mq($screen-l) {
                text-align: $product-view__tabs-title-text-align\@large;
                padding: $product-view__tabs-title-padding\@large;
            }
        }
    }

    &__tabs-sticky-wrap {
        display: none;
        @include mq($product-view__tabs-sticky-show-mq) {
            display: block;
            position: fixed;
            visibility: hidden;
            top: 0;
            width: 100vw;
            left: 50vw;
            right: auto;
            margin-left: -50vw;
            margin-right: auto;
            background-color: $product-view__tabs-sticky-bg;
            border: $product-view__tabs-sticky-border;
            border-width: $product-view__tabs-sticky-border-width;
            box-shadow: $product-view__tabs-sticky-shadow;
            z-index: $product-view__tabs-sticky-z-index;
            &.aos-animate {
                visibility: visible;
            }
        }
    }

    &__tabs-sticky {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 100%;
        max-width: $product-view__tabs-sticky-max-width;
        margin: $product-view__tabs-sticky-margin;

        .tab__title--sticky {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $product-view__tabs-sticky-label-height;
            border-width: $product-view__tabs-sticky-label-border-width;
            text-decoration: $product-view__tabs-sticky-label-decoration;
        }
    }

    &__table {
        .table {
            td {
                @include mq($screen-m) {
                    text-align: right;
                }
            }
        }
    }

    // styles for related / upsells
    .slider--products {
        margin: $product-view__slider-margin;
    }

    // styles for addtocart-sticky EE version
    .add-to-cart--ee {
        .add-to-cart__wrapper {
            padding: $product-view__add-to-cart-ee-wrapper-padding;

            @include mq($screen-m) {
                padding: $product-view__add-to-cart-ee-wrapper-padding\@medium;
            }

            @include mq($screen-l) {
                padding: $product-view__add-to-cart-ee-wrapper-padding\@large;
            }
        }

        .add-to-cart__action  {
            position: absolute;
            bottom: $product-view__add-to-cart-ee-action-bottom;
            right: $product-view__add-to-cart-ee-action-right;

            @include mq($screen-m) {
                position: static;
            }
        }

        .price-box {
            display: flex;
            flex-direction: column;
            padding: $product-view__add-to-cart-ee-price-box-padding;

            @include mq($screen-m) {
                flex-direction: row;
                align-items: center;
            }

            @include mq($screen-l) {
                padding: $product-view__add-to-cart-ee-price-box-padding\@large;
            }
        }

    }


    // Bloque
    &__right {
        order: 2;
        padding: 0 $spacer--medium;
        @include mq($screen-m) {
            grid-column-start: $product-view__right-grid-column-start\@medium;
            grid-row-start: $product-view__right-grid-row-start\@medium;
            width: 80%;
            margin: 0 auto;
        }
        @include mq($screen-xl) {
            padding: $spacer--large * 2;
        }
    }
    //Detailed
    &__info-detailed {
        @include mq($screen-m) {
            width: 40%;
        }
        .data {
            &.titles {
                display: flex;
                flex-direction: column;
                gap: $spacer--small;
                margin-top: $spacer--medium;
                @include mq($screen-m) {
                    margin-top: $spacer--extra-large;
                }
            }
            &.switch {
                align-items: center;
                justify-content: start;
                text-decoration: none;
                @include mq($screen-m) {
                    justify-content: space-between;
                }
                .fa {
                    font-weight: $font-weight-light;
                    font-size: $font-size-small-xs;
                    padding-left: $spacer--medium;
                }
            }
        }

        table#product-attribute-specs-table {
            border: none;
            tr:nth-child(even) {
                th, td {
                    background-color: $product-view____info-detailed-table-td-th-bg-color;
                }
            }
            tr, th {
                border: none;
                padding: 0;
                @include mq($screen-m) {
                    padding: $spacer;
                }
            }
            th {
                white-space: normal;
                width: 50%;
                @include mq($screen-m) {
                    width: 20%;
                }
            }
            td {
                flex-flow: row nowrap;
                text-align: right;
                white-space: normal;
                padding: $spacer;
                @include mq($screen-m) {
                    padding: $spacer--medium;
                    flex-flow: row wrap;
                    text-align: left;
                    width: auto;
                }
                &:before {
                    max-width: unset;
                    text-align: left;
                    width: 40%;
                }
            }
        }
    }
    &__sticky {
        position: sticky;
        top: $product-view__sticky-top;
        margin: 0;
        width: auto;
        z-index: 1;
        @include mq($screen-l) {
            margin: $product-view__sticky-padding;
        }
    }

    &__modal {
        .product {
            &.attribute {
                padding-top: $spacer--medium;
                @include mq($screen-m) {
                    padding-top: $spacer--large;
                }
                .type {
                    text-transform: uppercase;
                    font-weight: $font-weight-medium;
                    letter-spacing: $product-view__title-letter-spacing;
                }
                .value, .value p {
                    font-weight: $font-weight-light;
                    font-size: $font-size-small;
                    padding-top: $spacer--extra-small;
                }
            }
        }
        .payment-shipping-container {
            h4 {
                text-transform: uppercase;
                font-size: $font-size-extra-medium;
                font-weight: $font-weight-medium;
                letter-spacing: $product-view__title-letter-spacing;
            }
            [data-content-type="row"] {
                &:first-of-type {
                    [data-enable-parallax="0"] {
                        flex-direction: row !important;
                        align-items: center;
                        figure {
                            margin: $spacer--extra-small;
                        }
                    }
                }
                [data-content-type="text"] {
                    p {
                        font-size: $font-size-small;
                        font-weight: $font-weight-light;
                    }
                    table {
                        tr {
                            td {
                                font-size: $font-size-small;
                                padding: $spacer--extra-small;
                            }
                        }
                    }
                }
            }
        }
    }
}

.catalog-product-view {
    @include mq($screen-m) {
        nav.breadcrumbs {
            z-index: 99;
            top: 100px;
        }
    }
    .header {
        &--active {
            background: transparent;
            box-shadow: unset;
            @include mq($screen-m) {
                ~ nav {
                    position: fixed;
                }
            }
        }
        //@include mq($screen-m) {
            &:not(.header--active) {
                position: absolute;
                ~ nav {
                    position: absolute;
                }
            }
            &__search-wrapper {
                margin-left: unset;
                .search-form__input {
                    &:-webkit-input-placeholder {
                        color: transparent;
                    }
                    &:-moz-placeholder {
                        color: transparent;
                    }
                    &::-moz-placeholder {
                        color: transparent;
                    }
                    &:-ms-input-placeholder {
                        color: transparent;
                    }
                }
            }
        //}
    }
    .breadcrumbs {
        display: none;
        @include mq($screen-m) {
            display: block;
        }
    }


    .product-view {
        &__buttons-wrapper {
            &--ee {
                .product-view__update-wishlist {
                    display: none;
                }
            }
        }
    }

    &.wishlist-index-configure {
        .product-view {
            &__buttons-wrapper {
                &--ee {
                    .product-view__update-wishlist {
                        display: flex;
                    }
                }
            }
        }
    }

    .grecaptcha-badge {
        // sass-lint:disable-block no-important
        bottom: $product-view__grecaptcha-badge-bottom !important;

        @include mq($screen-m) {
            bottom: $product-view__grecaptcha-badge-bottom\@medium !important;
        }
    }

    .related {
        .product-related {
            margin: 2.5px auto;
            display: inline-block;
            height: $product-view__related-size;
            width: $product-view__related-size;
            border: 1px solid $pale-brown;
            border-radius: 50%;
            img {
                height: $product-view__related-size;
                width: $product-view__related-size;
            }
            &__link {
                border-radius: 50%;
                width: $product-view__related-hover-size;
                height: $product-view__related-hover-size;
                border: 2px solid transparent;
                &:hover {
                    border-color: $color-primary;
                }

            }
        }
        &.section {
            margin: 0;
        }
    }

    .select2 {
        //width: 100%;
        //@include mq($screen-m) {
            width: auto;
        //}
        &-results__option[aria-selected=true] {
            display: none;
        }
        &-dropdown {
            border-width: 1px;
            width: auto !important;
            min-width: 103px;
        }
    }
    &._has-modal {
        .header {
            z-index: 99;
        }
    }
    .footer, iframe#launcher {
        @include mq($max-screen: $screen-s) {
            z-index: -1 !important;
        }
    }
    .footer {
        @include mq($max-screen: $screen-s) {
           padding-bottom: 180px;
        }
    }
}
