
.account {
    .dashboard {
        &__content-heading {
            font-weight: $dashboard__subtitle-font-weight;
        }
        &__subtitle{
            font-weight: $dashboard__subtitle-font-weight;
        }
        .information__text{
            font-size: $dashboard__subtitle-info-font-size;
            font-weight: $dashboard__subtitle-info-font-weight;
            a{
                font-weight: $dashboard__subtitle-info-font-weight;
            }
        }
        &__sidebar {
            @include mq($screen-l) {
                padding: 0 8px;
            }

            @include mq($screen-xl) {
                padding: 0 8px;
            }
            .dashboard-nav__mobile{
                display: none !important;
            }
        }
        &-welcome {
            .welcome {
                &-name {
                    font-size: $font-size-medium;
                    font-weight: $font-weight-base;
                    strong{
                        font-weight: $font-weight-base;
                    }
                }
            }
            div[data-element="main"] p {
                font-size: $font-size-base;
                font-weight: $font-weight-extra-light;
                line-height: 19px;
            }
        }
        &-table {
            &__header{
                flex-direction: row;
                align-items: center;
            }
            &__content {
                margin: 0;
                padding: 0;
                // padding: 0 0 $spacer--extra-large;
                // @include mq($max-screen: $screen-s) {
                //     padding: 0 0 $spacer--semi-large;
                // }
            }
        }
        &-items {

            &__client {
                font-size: $font-size-small;
                font-weight: $font-weight-light;
            }
            &__content {
                address{
                    font-size: $font-size-small;
                    font-style: normal;
                    min-height: 150px;
                }
                ul{
                    font-size: $font-size-small;
                    padding-left: $spacer--medium;
                }
            }
        }
        &-nav {
            &__mobile {
                @include mq($max-screen: $screen-m) {
                    padding: 8px 0;
                    margin-bottom: 16px;
                }
            }
        }
        &-form {
            .form {
                &-edit-account {
                    .fieldset {
                        .input {
                            @include mq($max-screen: $screen-m) {
                                &__label,
                                &__field {
                                    font-size: $font-size-small;
                                }
                                &__field {
                                    height: 32px;
                                }
                            }
                        }
                        .checkbox {
                            @include mq($max-screen: $screen-m) {
                                &__label {
                                    font-size: $font-size-small;
                                    color: $color-primary;
                                }
                            }
                        }
                        .field {
                            @include mq($max-screen: $screen-m) {
                                span {
                                    font-size: $font-size-small;
                                }
                            }
                        }
                    }
                    .actions-group {
                        @include mq($max-screen: $screen-m) {
                            &__handler {
                                margin: 0;
                            }
                            &__link {
                                padding: 0;
                                font-size: $font-size-small;
                            }
                        }
                    }
                }
            }
        }
        &-content {
            .search {
                @include mq($max-screen: $screen-m) {
                    .fieldset {
                        padding: 0;
                    }
                    .input {
                        &__field {
                            height: 32px;
                            font-size: $font-size-small;
                        }
                    }
                    .button {
                        min-height: 39px;
                    }
                }
            }
        }
    }

    .actions-group {
        &__handler {
            a:nth-child(even) {
                border-left: 1px solid $color-primary;
                margin-left: 0;
                padding-left: $spacer--medium;
            }
        }
    }

    .toolbar {
        &--secondary{
            margin-top: $spacer--large;
            @include mq($max-screen: $screen-s) {
                justify-content: center;
            }
        }
        &__amount {
            @include mq($max-screen: $screen-s) {
                //margin-bottom: $spacer--small ;
                padding-right: $spacer--small;
            }
            &-content {
                font-size: $font-size-base;
                color: $color-primary;
                font-weight: $font-weight-light;
                @include mq($max-screen: $screen-s) {
                    font-size: $font-size-small;
                }
            }
        }
        &__limiter {
            &-label,
            &-text {
                font-size: $font-size-base;
                color: $color-primary;
                font-weight: $font-weight-light;
                @include mq($max-screen: $screen-s) {
                    font-size: $font-size-small;
                }
            }
            &-text {
                white-space: nowrap;
            }
            .select2 {
                &-selection {
                    border: 0;
                    background: transparent;
                    padding-right: 36px;
                    padding-left: 12px;
                    &__arrow {
                        right: 0;
                        left: 32px;
                        top: -2px;
                    }
                    &__rendered {
                        padding: 0;
                        color: $color-primary;
                    }
                }
            }
            .select2-selection--single {
                @include mq($max-screen: $screen-s) {
                    font-size: $font-size-small;
                }
            }
        }
        &__pager {
            margin-bottom: 0;
            .pager {
                &__item {
                    height: auto;
                    flex-basis: inherit;
                    font-size: 18px;
                    color: $color-primary;
                    font-weight: $font-weight-light;
                    margin: 0 8px;
                    &:after {
                        content: none;
                    }
                    &--current {
                        font-weight: $font-weight-medium;
                    }
                    &.arrow {
                        &__left {
                            margin: 0 24px 0 0;
                        }
                        &__right {
                            margin: 0 0 0 24px;
                        }
                    }
                }
                &__link {
                    color: $color-primary;
                    font-weight: $font-weight-extra-light;
                    &:before {
                        content: none;
                    }
                    i {
                        font-weight: $font-weight-normal;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }

    &.sales-order-view {
        .dashboard-table__header{
            .dashboard-table__link{
                &:before{
                    left: 4px;
                }
                &:first-child{
                    &:before{
                        border-width: 0;
                    }
                }
            }
        }
    }

    &.sales-order-print{
        table{
            thead,tbody{
                tr{
                    th:last-child,
                    td:last-child{
                        text-align: right;
                    }
                }
            }
            tfoot{
                text-align: right;
            }
        }
    }

    &.nobodinoz_claim-delivery-form,
    &.nobodinoz_claim-quality-form{
        #form-claim-delivery,
        #form-claim-quality{
            max-width: 100%;
            @include mq($screen-m) {
                max-width: 60%;
            }
            .field-sku {
                .input {
                    position: relative;

                    .input__field.ui-autocomplete-input {
                        &.ui-autocomplete-loading {
                            // pointer-events: none;
                            ~.loader__icon {
                                border-width: 5px;
                                bottom: 5px;
                                display: block !important;
                                height: 35px;
                                top: unset;
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.customer-address-form {
        .dashboard-form {
            label[for="primary_shipping"] {
                strong {
                    padding: 0 $spacer--extra-small;
                }
            }
        }
    }
}
