// Table
$table__width                                                : 99% !default;
$table__padding                                              : 0 !default;
$table__background                                           : $white !default;
$table__margin                                               : $spacer 0 !default;
$table__border                                               : 0 $border-style-base $color-primary;
$table__border-radius                                        : $border-radius !default;
$table__border-collapse                                      : collapse;
$table__border-spacing                                       : 0 !default;
$table__color                                                : $color-primary;
$table__line-height                                          : $font-line-height !default;
$table__font-size                                            : $font-size-base;
$table__font-size\@medium                                    : $font-size-base;

// Clean variant
$table__th-font-weight--clean                                : $font-weight-bold !default;
$table__th-background--clean                                 : transparent !default;
$table__background-odd--clean                                : $gray-lighter !default;
$table__background-even--clean                               : $white !default;

// Table heading
$table__thead-border                                         : unset;

$table__th-background                                        : $white;
$table__th-padding                                           : 0 !default;
$table__th-padding\@medium                                   : $spacer !default; //$spacer $spacer--medium;
$table__th-border-bottom\@medium                             : $table__border !default;
$table__th-border-right\@medium                              : 0;
$table__th-text-weight                                       : 400;
$table__th-text-align                                        : center !default; //left !default;
$table__th-background-row                                    : $white !default;
$table__th-padding-row                                       : $spacer--medium !default;
$table__th-border-width-row                                  : 1px 0 !default;
$table__th-text-align-row                                    : left !default;
$table__th-width-row                                         : 50% !default;

// Table body
$table__tbody-border                                         : 0; //1px $border-style-base $color-primary;

// Table data
$table__td-background                                        : $white !default;
$table__td-padding                                           : $spacer $spacer--medium;
$table__td-padding\@medium                                   : $spacer !default; //$spacer $spacer--medium;
$table__td-border-bottom\@medium                             : $table__border !default;
$table__td-border-right\@medium                              : 0;
$table__td-font-weight-before                                : bold !default;
$table__td-margin-right-before                               : $spacer !default;
$table__td-width-before                                      : 25% !default;
$table__td-max-width-before                                  : 25% !default;

// Table row
$table__tr-padding                                           : $spacer--medium 0 !default;
$table__tr-padding\@medium                                   : 0 !default;
$table__tr-border-bottom                                     : $table__border;
$table__tr-border-bottom\@medium                             : 1px $border-style-base $color-primary;

// Table footer
$table__tfoot-tr-border-top-first-child                      : $table__border !default;
$table__tfoot-td-padding                                     : $spacer $spacer--medium !default;
$table__tfoot-td-padding\@medium                             : $spacer $spacer--medium;
$table__tfoot-td-background                                  : $table__background !default;
$table__tfoot-td-background\@medium                          : $table__background !default;
$table__tfoot-td-border-right\@medium                        : $table__border !default;
$table__tfoot-td-border-top\@medium                          : $table__border !default;

// Odd even variant
$table__background-odd--odd-even                             : $button__background-hover;
$table__background-even--odd-even                            : $white;
$table__td-font-weight--odd-even                             : $font-weight-extra-light;
$table__td-background--odd-even                              : transparent !default;
$table__border-top-left-radius-first-child--odd-even         : 0 !default;
$table__border-top-left-radius-first-child--odd-even\@medium : 0 !default;
$table__border-top-right-radius-first-child--odd-even        : 0 !default;
$table__border-top-right-radius-first-child--odd-even\@medium: 0 !default;


// Scope row variant
$table__tr-padding--scope-row                                : 0 !default;
$table__border-width--scope-row                              : 1px 0 0 0 !default;
$table__tr-background-odd--scope-row                         : $table__background-even--odd-even !default;
$table__th-font-wieght--scope-row                            : $font-weight-normal !default;

//link mobile

$table__link-font-size                                       : $font-size-small !important;
