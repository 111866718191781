@import "base_extend-variables";

.container {
    &.fluid {
        max-width: unset;
        padding-left: $container-fluid__padding-x;
        padding-right: $container-fluid__padding-x;
    }

    .full-width {
        left: 50%;
        padding-left: $container-fluid__padding-x;
        padding-right: $container-fluid__padding-x;
        position: relative;
        transform: translateX(-50%);
        width: 100vw;
    }
}
