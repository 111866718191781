.order-summary {
	background-color: $lighter-brown;

	&__label {
		font-weight: $font-weight-extra-light;
	}

	.button {
		&.checkout {
			@extend .button;
			@extend .button--secondary;
			@include mq($screen-xl) {
				margin-left: 10%;
				width           : 60%;
				float           : right;
			}
			// color           : $lighter-brown;
			// background-color: $color-primary;
			// width           : 240px;
			// float           : right;
			// &:hover{
			//   color: $color-primary;
			// }
		}
	}

	#block-shipping {
		display: none;
	}

	&:not(.opc-block-summary){

		.order-summary__list-item {
			&.order-summary__total {
				display: flex;
			}
            .discount.coupon {
                display: none;
            }
		}
		.order-summary__divider {
			display: none;
		}
	}

}

#gift-options-cart {
	display: none;
}
