$qty-btn-color          : $button__text-color;
$qty-btn-bg-color       : transparent;
$qty-btn-border-color   : transparent;
$qty-btn-width          : 2rem !default;
$qty-btn-max-width      : 60px !default;
$qty-btn-min-width      : 30px !default;
$qty-btn-min-height     : 56px !default;
$qty-btn-font-size      : $button__font-size !default;
$qty-input-color        : $input__field-color;
$qty-input-bg-color     : $white;
$qty-input-border-color : $white;
$qty-input-font-family  : $font-family-base !default;
$qty-input-font-size    : $font-size-base !default;
$qty-input-font-weight  : $font-weight-medium !default;
