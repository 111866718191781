@import 'actions-group-variables';

.actions-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: $actions-group__width;

    &__handler {
        flex-basis: 100%;
        margin: $actions-group__handler-margin;

        display: flex;
        align-items: center;
        a {
            margin-right: $actions-group__handler-a-margin-left;
            + a{
                margin-left: $actions-group__handler-a-margin-left;
            }
        }
        @include mq($screen-m) {
            flex-basis: auto;
            margin: $actions-group__handler-margin\@medium;
        }
    }

    &__button {
        margin: $actions-group__button-margin;
        width: $actions-group__button-width;

        @include mq($screen-m) {
            width: $actions-group__button-width\@medium;
            margin: $actions-group__button-margin\@medium;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        padding: $actions-group__link-padding;
        margin: $actions-group__link-margin;
        text-decoration: $actions-group__link-text-decoration;
        background-clip: $actions-group__link-background-clip;
        font-size: $actions-group__link-font-size;
        font-weight: $actions-group__link-font-weight;

        &:last-child {
            margin-right: 0;
        }
    }
}
