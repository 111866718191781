.ui-autocomplete{
    padding: 0 !important;
    li{
        a,span{
            font-weight: $font-weight-extra-light;
            transition: unset;
        }
        &:hover{
            background:transparent !important;
            a,span{
                font-weight: $font-weight-medium;
            }
        }
    }
}
