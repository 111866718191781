@import 'banner-variables';

.ampromo-gift a.product-image {
    text-decoration: none;
}

ul.ampromo-gift {
    padding: 0;
    list-style-type: none;
}

.ampromo-gift li {
    margin: 10px 10px;
    display: inline;
}

.ampromo-banner-tooltip {
    padding: 2px 5px;
    background: #FEC;
    border: 1px solid #FAA819;
    color: #1F5070;
    text-align: left;
    width: 300px;
}

.ampromo-product-name {
    font-weight: bold;
}

.ampromo-short-description {}

.ampromo-label {
    position: absolute;
    z-index: 1000;
}

// amasty-jet-theme

.amasty-jet-theme .ampromo-slick {

    // Disable override slick styles for JetTheme
    .slick-prev {
        left: 0 !important;
    }

    .slick-next {
        right: 0 !important;
    }
}

//  Promo

.ampromo-item .ampromo-options {
    margin-bottom: 20px;
    padding: 0 5px;
    overflow: hidden;
    transition: max-height .2s ease;
}

.ampromo-gallery {
    width: 850px;
    margin: 0 30px;

    &[data-count="1"] {
        width: 280px;
    }

    &[data-count="2"] {
        width: 570px;
    }
}

.ampromo-slick {

    // Override slick styles
    .slick-prev,
    .slick-next {
        width: $slick-button__size !important;
        height: $slick-button__size !important;
    }

    .slick-prev {
        left: $slick-button__distance !important;
    }

    .slick-next {
        right: $slick-button__distance !important;
    }

    .slick-dots {
        padding: 0 !important;
    }

    .slick-dots button {

        &:hover {

            .ampromo-item .ampromo-options {
                margin-bottom: 20px;
                padding: 0 5px;
                overflow: hidden;
                transition: max-height .2s ease;
            }

            .ampromo-gallery {
                & {
                    width: 850px;
                    margin: 0 30px;
                }

                &[data-count="1"] {
                    width: 280px;
                }

                &[data-count="2"] {
                    width: 570px;
                }
            }

            .ampromo-slick {

                // Override slick styles
                .slick-prev,
                .slick-next {
                    width: $slick-button__size !important;
                    height: $slick-button__size !important;
                }

                .slick-prev {
                    left: $slick-button__distance !important;
                }

                .slick-next {
                    right: $slick-button__distance !important;
                }

                .slick-dots {
                    padding: 0 !important;
                }

                .slick-dots button {
                    &:hover {
                        height: $slick-dot__height;
                        width: $slick-dot__width;
                        padding: 5px !important;
                        background: transparent !important;
                        border: none !important;
                        text-indent: 0 !important;
                    }
                }

                .slick-slide {
                    vertical-align: top;
                }
            }

            .ampromo-items-add {
                display: none;
                margin-bottom: 12px;
                padding: 7px;
                border: $ampromo-item__border;
                text-align: center;

                >a {
                    text-decoration: none;
                    border-bottom: 1px dashed;
                }
            }

            .checkout-index-index .ampromo-items-add:before {
                content: '';
                display: inline-block;
                width: 32px;
                height: 32px;
                margin-right: 12px;
                background: $ampromo-gift__background-image no-repeat;
                background-size: contain;
                vertical-align: middle;
            }

            .ampromo-items-form {
                background: $ampromo-items-form__background-color;
                padding: 5px;

                .ampromo-qty {
                    width: 30%;
                    margin-left: 35%;
                    margin-top: 10%;
                }

                .product-info-price {
                    padding-bottom: 10px;
                    text-align: center;
                    word-break: break-all;
                }

                .price-base-price .price {
                    font-size: 25px;
                    text-decoration: line-through;
                }

                .price-new-price .price-wrapper {
                    font-size: 36px;
                    line-height: 36px;
                    font-weight: bold;
                }

                .ampromo-item-qty-input>.mage-error {
                    float: left;
                }
            }

            .ampromo-loading {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: none;
                width: 80px;
                height: 80px;

                &.-show {
                    display: inline-block;
                }

                &:after {
                    display: block;
                    width: 64px;
                    height: 64px;
                    margin: 8px;
                    content: ' ';
                    border-radius: 50%;
                    border: 6px solid #ccc;
                    border-color: #ccc transparent #ccc transparent;
                    animation: load 1.2s linear infinite;
                }
            }

            .ampromo-carousel-product {
                &.-loading {
                    -webkit-filter: blur(10px);
                    filter: blur(10px);
                    opacity: 0.3;

                    pointer-events: none;
                }
            }

            @keyframes load {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }


            @media only screen and (max-width: 700px) {
                .ampromo-items-form .product-info-price {

                    >*:first-child,
                    >*:last-child {
                        padding: 0;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                .checkout-container .opc-estimated-wrapper {
                    margin-top: 10px;
                }

                .checkout-container .authentication-wrapper {
                    margin-top: -12px;
                }
            }

            width: $slick-dot__width;
            padding: 5px !important;
            background: transparent !important;
            border: none !important;
            text-indent: 0 !important;
        }
    }

    .slick-slide {
        vertical-align: top;
    }
}

.ampromo-items-add {
    display: none;
    margin-bottom: 12px;
    padding: 7px;
    border: $ampromo-item__border;
    text-align: center;

    >a {
        text-decoration: none;
        border-bottom: 1px dashed;
    }
}

.checkout-index-index .ampromo-items-add:before {
    content: '';
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    background: $ampromo-gift__background-image no-repeat;
    background-size: contain;
    vertical-align: middle;
}

.ampromo-items-form {
    background: #fff;
    padding: 5px;

    .ampromo-qty {
        width: 30%;
        margin-left: 35%;
        margin-top: 10%;
    }

    .product-info-price {
        padding-bottom: 10px;
        text-align: center;
        word-break: break-all;
    }

    .price-base-price .price {
        font-size: 25px;
        text-decoration: line-through;
    }

    .price-new-price .price-wrapper {
        font-size: 36px;
        line-height: 36px;
        font-weight: bold;
    }

    .ampromo-item-qty-input>.mage-error {
        float: left;
    }
}

.ampromo-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 80px;
    height: 80px;

    &.-show {
        display: inline-block;
    }

    &:after {
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        content: ' ';
        border-radius: 50%;
        border: 6px solid #ccc;
        border-color: #ccc transparent #ccc transparent;
        animation: load 1.2s linear infinite;
    }
}

.ampromo-carousel-product {
    &.-loading {
        -webkit-filter: blur(10px);
        filter: blur(10px);
        opacity: 0.3;

        pointer-events: none;
    }
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media only screen and (max-width: 700px) {
    .ampromo-items-form .product-info-price {

        >*:first-child,
        >*:last-child {
            padding: 0;
        }
    }
}


@media only screen and (max-width: 768px) {
    .checkout-container .opc-estimated-wrapper {
        margin-top: 10px;
    }

    .checkout-container .authentication-wrapper {
        margin-top: -12px;
    }
}


// POPUP

.ampromo-overlay {
    position: fixed;
    top: -9999px;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: $ampromo-overlay__background-color;
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s linear;
    display: flex;
    align-items: center;
    justify-content: center;

    &.-show {
        top: 0;
        opacity: 1;
    }
}

.ampromo-popup-wrapper {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    max-height: 95vh;
    overflow: auto;
    margin: 30px;
    padding: 24px;
    border: $ampromo-item__border;
    text-align: left;
    background-color: $ampromo-items__background-color;

    .ampromo-popup-title,
    .ampromo-item-buttons,
    .ampromo-title {
        text-align: center;
    }

    .ampromo-popup-title .ampromo-counter {
        font-weight: bold;
    }

    .ampromo-item {
        position: relative;
        box-sizing: border-box;
        padding: 20px;
        margin: 0 8px 7px;
        border: $ampromo-item__border;
        transition: .6s;

        &:hover {
            background: #ccc;
        }
    }

    .ampromo-item .fieldset .field {
        margin-bottom: 10px;
    }

    .ampromo-item .fieldset .field .label {
        margin-bottom: 3px;
    }

    .ampromo-popup-title {
        margin: 0 auto 20px;
    }

    .ampromo-close {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        background: $ampromo-overlay-close__background-image no-repeat;
        background-size: contain;
        font-size: 0;
        cursor: pointer;
        opacity: .8;

        &:hover {
            opacity: 1;
            transition: opacity .2s ease;
        }
    }

    .ampromo-button {
        box-shadow: none;
        border: none;
    }

    .ampromo-item.-selected {
        background: #1f78c2;
    }

    .ampromo-item.-selected .ampromo-options {
        max-height: 690px;
    }

    .ampromo-item-image {
        display: block;
        margin: 7px auto;
    }

    .ampromo-title {
        margin: 0;
    }

    .slick-dots {
        position: initial;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 2rem;
    }

    &.one-product {
        max-width: 566px;
        padding: 10px;
    }

    .one-product {
        padding: 0px;

        .slick-track {
            width: 100% !important;

            .item {
                width: 100% !important;
                display: flex !important;
                flex-direction: row-reverse;

                .item-title {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    width: 50%;

                    .title {
                        text-align: center;

                        a {
                            text-decoration: none;
                            font-weight: bold;
                        }
                    }
                }

                img {
                    width: 50%;
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) and (min-width: 701px) {
    .ampromo-overlay .ampromo-gallery {
        width: 570px;
    }

    .ampromo-popup-wrapper {
        padding: 24px 54px;
    }
}

@media only screen and (max-width: 700px) {
    .ampromo-overlay .ampromo-gallery {
        width: 280px;
        margin: 0 auto;
    }

    .ampromo-popup-wrapper {
        padding: 24px 54px;

        .ampromo-close {
            width: 32px;
            height: 32px;
        }

        .one-product {
            padding: 0px;

            .slick-track {
                width: 100% !important;

                .item {
                    width: 100% !important;
                    display: flex !important;
                    flex-direction: column;

                    .item-title {
                        display: flex;
                        align-items: center;
                        padding: 10px 35px 10px 10px;
                        width: 100%;

                        .title {
                            text-align: left;
                            font-size: x-large;
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .ampromo-overlay .ampromo-gallery {
        margin: 0 auto 30px auto;
    }

    // rewrite slick styles
    .ampromo-overlay .slick-prev,
    .ampromo-overlay .slick-next {
        display: none !important;
    }

    .ampromo-popup-wrapper {
        padding: 15px;
    }
}

@media only screen and (min-width: 1024px) {
    .ampromo-popup-wrapper {
        max-width: 960px;
        width: 100%;

        .slick-dotted.slick-slider {
            margin-bottom: 3rem;
        }
    }
}

.checkout-cart-index .ampromo-items-form,
.checkout-index-index .ampromo-items-form {
    display: none;
}
