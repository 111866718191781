$message__padding               : $spacer $spacer--medium !default;
$message__padding\@medium       : $spacer $spacer--extra-large !default;
$message__margin                : $spacer auto !default;
$message__font-size             : $font-size-base !default;
$message__transition            : $transition-base !default;

$message__background            : $white !default;
$message__background--success   : $success-background !default;
$message__background--error     : $error-background !default;
$message__background--info      : $info-background !default;
$message__background--notice    : $warning-background !default;

$message__color                 : $color-primary !default;
$message__color--success        : $success !default;
$message__color--error          : $error !default;
$message__color--info           : $color-info !default;
$message__color--notice         : $warning !default;

$message__border-width          : 0px !default; //1px 1px $spacer--extra-small 1px !default;
$message__border-color          : $border-color-base $border-color-base $color-info $border-color-base !default;
$message__border-style          : solid !default;

$message__border-color--success : $border-color-base $border-color-base $color-success $border-color-base !default;
$message__border-color--error   : $border-color-base $border-color-base $color-error $border-color-base !default;
$message__border-color--info    : $border-color-base $border-color-base $color-error $border-color-base !default;
$message__border-color--notice  : $border-color-base $border-color-base $color-error $border-color-base !default;


$message__border-radius         : 0 !default;
$message__icon-spacer           : 20px !default;//$spacer--semi-medium !default;
$message__icon-width            : $spacer--semi-medium + $spacer--small !default;
$message__icon-order            : -1 !default;
$message__icon-margin           : 0 $spacer 0 0 !default;

$message__icon-fill             : $color-info !default;
$message__icon-fill--success    : $color-success !default;
$message__icon-fill--error      : $color-error !default;
$message__icon-fill--info       : $color-info !default;
$message__icon-fill--notice     : $warning !default;

$message__icon-bg-image         : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path fill="#{$message__icon-fill}" d="M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333zm.667 10H6.333V9h1.334v1.333zm0-2.666H6.333v-4h1.334v4z"/></svg>') !default;
$message__icon-bg-image--success: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#{$message__icon-fill--success}" d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"/></svg>') !default;
$message__icon-bg-image--eror   : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#{$message__icon-fill--error}" d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z"/></svg>') !default;
$message__icon-bg-image--info   : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$message__icon-fill--info}" d="M492 8h-10c-6.627 0-12 5.373-12 12v110.627C426.929 57.261 347.224 8 256 8 123.228 8 14.824 112.338 8.31 243.493 7.971 250.311 13.475 256 20.301 256h10.016c6.353 0 11.646-4.949 11.977-11.293C48.157 132.216 141.097 42 256 42c82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12zm-.301 248h-10.015c-6.352 0-11.647 4.949-11.977 11.293C463.841 380.158 370.546 470 256 470c-82.608 0-154.672-46.952-190.299-116H180c6.627 0 12-5.373 12-12v-10c0-6.627-5.373-12-12-12H20c-6.627 0-12 5.373-12 12v160c0 6.627 5.373 12 12 12h10c6.627 0 12-5.373 12-12V381.373C85.071 454.739 164.777 504 256 504c132.773 0 241.176-104.338 247.69-235.493.339-6.818-5.165-12.507-11.991-12.507z"/></svg>') !default;
$message__icon-bg-image--notice   : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{$message__icon-fill--notice}" d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z"/></svg>') !default;
