.fotorama {
    .fotorama__zoom-in-icon {
        margin: $spacer--medium $spacer $spacer--small 0;
        opacity: .8;
        background-image: url('../images/zoom-in_1.svg');
        @include mq($screen-xl*2) {
            margin: $spacer--large $spacer $spacer--small 0;

        }
    }
    //@include mq($screen-m) {
    //    &:hover {
    //        .fotorama__zoom-in-icon {
    //            display: block;
    //        }
    //    }
    //}
    &.fotorama--fullscreen {
        .fotorama__stage{
            @include mq($max-screen: $screen-m - 1) {
                height: 100vh !important;
            }
        }
        .fotorama__fullscreen-icon {
            background: $color-primary;
            @include mq($max-screen: $screen-m - 1) {
               width: 40px;
               height: 40px;
            }
            .fullscreen-icon__close{
                @include mq($max-screen: $screen-m - 1) {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .fotorama__nav-wrap {
            &--horizontal {
                .fotorama__nav--thumbs {
                    display: flex;
                    align-items: center;
                    margin-left: 0;
                    bottom: $spacer--large;
                    > div {
                        margin: auto;
                    }
                }
            }
            &--vertical{
                left: 5px;
               .fotorama__nav--thumbs{
                   padding: 0;
               }
            }
        }

    }
    &__img{
        width: 100% !important;
    }
}

.gallery {
    &__placeholder-image {
        &.ratio-image {
            @include mq($screen-l) {
                top: 50%;
                // thumbwidth + thumbmargin (fixed in fotorama.js)
                padding-right: 0;//$gallery__placeholder-padding-right\@large;
                transform: $gallery__placeholder-transform\@large;
                max-height: 100%;
                width: auto;
            }
        }
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
            list-style-type: none;
            img {
                display: block;
                width: 100vw;
                object-fit: cover;
            }
        }

    }
}
