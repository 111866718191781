.success-page {
    @include mq($max-screen: $screen-m) {
        .heading {
            &--page {
                line-height: 32px;
            }
        }
        .checkout-success {
            p {
                line-height: 1.3;
                padding-bottom: 12px;
            }
        }
    }
}
