.button {
    &--light {
        background-color: $button__background--light;
    }

    &--icon {
        border: unset;

        &:hover,
        &.focus-visible {
          border: unset;
        }
    }
    &--s {
        font-size: $button__font-size__s;
        padding: $button__padding__s;
        min-height: $button__min-size__s;
    }
    &:hover,
    &.focus-visible {
        color: $button__color-hover--link;
        background: $button__background-hover--link;
        border-color: $button__color-hover--link;
        &:before {
            display: $button__before-display-hover--link;
        }
        &:after {
            display: $button__after-display-hover--link;
        }
    }

    &:disabled,
    &[disabled] {
        color: $button__color-hover--link;
        &:hover {
            background-color: $button__background-hover--link;
            color: $color-primary;
        }
    }
    &.button--add-to {
        //&.added {
        //    @extend .button--secondary;
        //    background-color: transparent;
        //    color: $button__color-hover--link;
        //    border: $button__border;
        //    &:not(.product-view__add-to-cart) {
        //        border: 0 solid;
        //    }
        //    &:hover {
        //        background-color: transparent;
        //        border: $button__border;
        //    }
        //}
    }
}
