@import "extend-variables";

.header-button {
  padding-left: 0;

  &__counter,
  &__total {
    align-items: center;
    background-color: $header-button__counter-background;
    border: unset;
    border-radius: unset;
    color: $header-button__counter-color;
    display: flex;
    font-size: $header-button__counter-font-size;
    height: unset;
    justify-content: center;
    line-height: $header-button__counter-size;
    position: static;
    z-index: $header-button__counter-z-index;
    width: unset;
    white-space: nowrap;
    padding: 0  $header-button__counter__padding;

    &--hidden {
      display: none;
    }
  }

  &__total {
    &-text {
      font-size: $header-button__total-font-size;
      font-weight: $header-button__total-font-weight;
    }
  }

  &__counter {
    font-size: $header-button__total-font-size;
    border-left: $header-button__counter-border;
    font-weight: $header-button__counter-font-weight;
  }
}
