$dashboard-items__padding                : $spacer--medium !default;

$dashboard-items__title-font-family      : $font-family-base !default;
$dashboard-items__title-font-size        : $font-size-medium !default;
$dashboard-items__title-font-weight      : $font-weight-base !default;
$dashboard-items__title-padding          : 0 !default;
$dashboard-items__title-background       : transparent !default;
$dashboard-items__title-border           : $border-secondary !default;
$dashboard-items__title-border-width     : 0 0 $border-width-secondary !default;
$dashboard-items__title-text-transform   : none !default;

$dashboard-items__subtitle-font-size     : 18px !default;
$dashboard-items__subtitle-font-weight   : $font-weight-extra-light !default;
$dashboard-items__subtitle-text-transform: inherit !default;

$dashboard-items__item-font-size         : $font-size-medium !default;

$dashboard-items__collapsible-transform  : rotate(180deg) !default;
