.quicksearch {
    &__more {
        &-link {
            border-top: 1px solid $color-primary;
        }
    }
    @include mq($max-screen: $screen-m) {
        overflow: scroll;
        z-index: 999;
        height: 100vh;
    }
    &__product-details{
        .price-box {
            display: none;
        }
    }
}
