$select__margin-bottom                   : $spacer--medium !default;
$select__padding                         : 0 $spacer--extra-large 0 $spacer--medium !default;
$select__border                          : 1px solid $color-primary;
$select__height                          : 48px !default;
$select__min-width                       : 72px !default;
$select__border-radius                   : $form-elements-radius !default;
$select__line-height                     : $select__height !default;
$select__font-family                     : $font-family-base !default;
$select__field-background                : $white !default;
$select__field-color                     : $font-color-base !default;
$select__field-font-size                 : $font-size-base !default;
$select__label-color-focus               : $color-primary !default;
$select__transition                      : $transition-base !default;
$select__background-disabled             : $gray-light !default;
$select__white-space--inline             : nowrap !default;
$select__field-font-color               : $color-primary;

$select__label-margin--inline            : 0 $spacer--medium 0 0 !default;

//native
$select__field-bg-image--native          : url('../images/icons/expand-down.svg') !default;
$select__field-bg-repeat--native         : no-repeat !default;
$select__field-bg-position--native       : calc(100% - #{$spacer}) center !default;

// Select 2
$select__option-list-shadow              : none !default;
$select__border-radius                   : 0 !default;
$select__field-padding                   : 0 $spacer--extra-large 0 $spacer--medium !default;
$select__arrow-position-top              : 0 !default;
$select__arrow-position-right            : $spacer--small !default;
$select__arrow-padding                   : $spacer--extra-small !default;
$select__arrow-bg-color                  : $color-primary !default;
$select__arrow-border-width              : 0 2px 2px 0 !default;
$select__arrow-margin-top                : -7px !default;
$select__arrow-transform                 : rotate(45deg) !default;
$select__arrow-transform--open           : rotate(225deg) !default;
$select__arrow-transition                : $transition-base !default;
$select__option-padding                  : 0 $spacer--medium !default;
$select__option-font-size                : $font-size-base !default;
$select__option-color--highlighted       : $color-primary !default;
$select__option-bg-color--highlighted    : $gray-light !default;
$select__option-selected-bg              : $white !default;
$select__option-overflow                 : hidden !default;
$select__option-text-overflow            : ellipsis !default;
$select__option-white-space              : nowrap !default;
$select__dropdown-border-width           : 0 1px 1px 1px !default;
