@import "table-variables";

.dashboard-table {
    &.pedidos {
        .item-info {
            background-color: $bg-color-base;
            display: flex;
            flex-direction: column;
            padding: 0;
            @include mq($screen-m) {
                padding: $spacer--extra-large 0 $spacer--large 0;
            }

            .line-wrapper {
                font-weight: $font-weight-light;
                border-color: $border-color-base;
                border-style: solid ;
                border-width: 0 1px;
                &.single-tr {
                    border-width: 1px;
                    @include mq($screen-m) {
                        border-width: 0 1px 1px 1px;
                    }
                }
                &.first-tr {
                    border-width: 1px 1px 0 1px;
                    @include mq($screen-m) {
                        border-width: 0 1px;
                    }
                }
                &.last-tr {
                    border-width: 0 1px 1px 1px;
                }
                &:not(.last-tr):not(.single-tr) {
                    &:before {
                        background-color: $extra-pale-brown;
                        content: ' ';
                        height: 1px;
                        left: 2%;
                        bottom: 0;
                        position: absolute;
                        width: 96%;
                        @include mq($screen-m) {
                           display: none;
                        }
                    }
                }
                .print,
                .qty-span {
                    display: block;
                    @include mq($screen-m) {
                        display: none;
                    }
                }

            }
            td {
                text-align: left;
                background-color: $white;
                border-bottom: unset;
                &:not(.text-right) {
                    &::before {
                        display: none !important;
                    }
                }
            }

            &-top,
            &-bottom {
                td {
                    background-color: $lighter-brown;
                    text-align: left;
                }
            }
            .col-order-number,
            .name,
            .payment-method{
                width: auto;
                @include mq($screen-m) {
                    width: 20%;
                }
                @include mq($screen-l) {
                    width: 320px;
                }
            }
            .name > span {
                white-space: normal;;
            }

            .col-date,
            .img,
            .billing-address{
                width: auto;
                @include mq($screen-m) {
                    width: 25%;
                }
                @include mq($screen-l) {
                    width: 320px;
                }
            }
            .tracking-info {
                span {
                    white-space: initial;
                }
            }
            .order-status {
                width: auto;
                @include mq($screen-m) {
                    width: 240px;
                }
            }
            .price.item-info-line,
            td.totals {
                @include mq($screen-m) {
                    width: 220px;
                }
            }
            .price {
                &.item-info-line {
                    .price {
                        display: flex;
                        @include mq($screen-m) {
                            padding-left: 47%;
                        }
                        @include mq($screen-xxl) {
                            padding-left: 110px;
                            text-align: right;
                        }
                    }
                }
            }
            .qty,
            .delivery-address {
                @include mq($screen-l) {
                    width: 280px;
                }
            }

            .img {
                .img-wapper {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    text-transform: uppercase;
                    letter-spacing: $dashboard-table__lines-line-letter-spacing;
                    img {
                        margin-right: $spacer--small;
                    }
                }
            }


            &-top {
                border-color: $border-color-base;
                label {
                    font-size: $font-size-small;
                    font-weight: $font-weight-light;
                }
                span {
                    font-size: $font-size-medium;
                }
                .tracking-info {
                    text-align: right;
                    @include mq($screen-m) {
                        order: unset;
                    }
                    &_wrapper {
                        display: flex;
                        justify-content: start;
                        text-align: left;
                        width: 100%;
                        @include mq($screen-m) {
                            justify-content: end;
                        }
                        @include mq($screen-l) {
                            display: flex;
                            padding: 0 $spacer--small 0 0;
                        }
                    }
                    a {
                        text-transform: uppercase;
                        letter-spacing: $dashboard-table__lines-line-letter-spacing;
                        padding: $spacer--extra-small $spacer--medium 0 $spacer--medium;
                        min-height: $spacer--large + $spacer--extra-small;
                        font-size: $font-size-small;
                    }

                }
                .order-status {
                    .status-wrapper {
                        display: flex;
                        flex-direction: column;
                    }
                    span {
                        //display: block;
                        &.status {
                            text-transform: uppercase;
                            letter-spacing: $dashboard-table__lines-line-letter-spacing;
                            font-size: $font-size-extra-medium;
                            padding-right: $spacer--small;
                        }
                        &:not(.status) {
                            font-weight: $font-weight-light;
                            font-size: $font-size-small;
                        }
                    }
                }
            }
            &-bottom {
                vertical-align: top;
                font-size: $font-size-small;
                td {
                    @include mq($screen-m) {
                        padding-top: $spacer--semi-medium;
                    }
                }
                label {
                    font-weight: $font-weight-medium;
                    font-size: $font-size-small;
                    @include mq($screen-m) {
                        font-size: $font-size-medium;
                    }
                }
                span {
                    font-weight: $font-weight-light;
                    > a {
                        font-weight: $font-weight-light;
                    }
                }

                .totals {

                    tr {
                        @include mq($screen-m) {
                            width: 100%;
                        }
                        th, td {
                            padding: 0 26px 0 8px;
                            text-align: left !important; //se sobreescribe estilo en linea
                            width: 100%;
                            @include mq($screen-m) {
                                padding: 0;
                                width: 50%;
                            }
                        }
                        th[scope="row"] {
                            background-color: $lighter-brown;
                            padding-right: 0;
                        }
                        &.grand_total {
                            th, td {
                                padding-top: $spacer--medium;
                            }
                        }
                    }
                    th,td::before {
                        font-weight: $font-weight-light;
                        font-size: $font-size-small;

                    }
                    td {

                        span {
                            font-weight: $font-weight-medium;
                            font-size: $font-size-small;
                            @include mq($screen-m) {
                                font-size: $font-size-medium;
                            }
                        }
                    }

                    //.text-right {
                    //    @include mq($max-screen: $screen-m) {
                    //        padding-right: $spacer--semi-medium !important;
                    //        &:before{
                    //            padding-left: $spacer--small/2 ;
                    //        }
                    //    }
                    //}
                }
            }

            //movil
            .line-wrapper {
                @include mq($max-screen: $screen-m + 1) {
                    display: grid;
                    grid-template-columns: 1fr 3fr 1fr;
                    grid-template-rows: 1fr 1fr 1fr;
                    gap: 0 0;
                    grid-template-areas:
                            "img name price"
                            "img print price"
                            "img qty price";
                    max-height: 135px;
                    margin-left: 0;
                    margin-right: $spacer--small;
                    padding: 0;
                    position: relative;
                    .print {
                        grid-area: print;
                        display: block;
                        letter-spacing: $dashboard-table__lines-line-letter-spacing;
                    }
                    .img {
                        grid-area: img;
                        align-items: center;
                        span {
                            display: none;
                        }
                    }
                    .name {
                        grid-area: name;
                    }
                    .qty {
                        grid-area: qty;
                        justify-content: left;

                        .qty-span {
                            display: block;
                        }
                    }
                    .price {
                        grid-area: price;
                        justify-content: flex-end;
                    }
                    .order-status {
                        &.item-info-line {
                            display: none;
                        }
                    }
                    td {
                        padding: $spacer--extra-small;
                        &.name,
                        &.img,
                        &.price{
                            padding-top: $spacer;
                        }
                    }
                }
            }
            &-top,
            &-bottom {
                @include mq($max-screen: $screen-m) {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    padding: 0;
                    .col-order-number,
                    .col-date,
                    .payment-method,
                    .billing-address,
                    .delivery-address {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }
                }
                td {
                    @include mq($max-screen: $screen-m) {
                        padding-left: 0;
                    }
                }
            }
            &-top {
                @include mq($max-screen: $screen-m) {
                    margin-bottom: $spacer--medium;
                    grid-template-areas:
                    "box1 box2"
                    "box4 box3"
                    "box5 box5";
                }
                .col-order-number {
                    grid-area: box1;
                }
                .col-date {
                    grid-area: box2;
                }
                .tracking-info {
                    &.has_btn {
                        grid-area: box3;
                    }
                    &.has_span {
                        grid-area: box5;
                    }
                }
                .order-status {
                    grid-area: box4;
                }
            }
            &-bottom {
                @include mq($max-screen: $screen-m) {
                    margin-top: $spacer--medium;
                    grid-template-areas:
                    "box1 box4"
                    "box2 box3";
                    width: 100%;
                    gap: $spacer;
                    .payment-method {
                        grid-area: box1;
                    }
                    .billing-address {
                        grid-area: box2;
                    }
                    .delivery-address {
                        grid-area: box3;
                    }
                    > td {
                        padding: 0;
                    }
                }
            }
        }
        .table {
            width: 100%;
            background: $bg-color-base;
            .linea-pedido {
                margin: 0;
                border-bottom: $dashboard-table__lines-border;
                &:first-child {
                    .item-info {
                        @include mq($screen-m) {
                            padding-top: 0;
                        }
                    }
                }
            }
            @include mq($screen-m) {
                white-space: nowrap;
            }
        }
    }
}
.sales-order-history {
    #maincontent {
        @include mq($max-screen: $screen-s) {
            margin: $spacer--medium;
        }
    }
}
