@import "extend-variables";

.messages {
    &__wrap {
        background-color: $messages__background-color;
        align-items: center;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 80px;
        width: 100%;
        z-index: 9999;
        @include mq($screen-m) {
           top: 150px;
        }
        &.aos-animate {
            margin-top: 0;//$header__height\@mobile - $spacer;
            @include mq($screen-m) {
                margin-top: 0; // $header__height - $spacer;
            }
        }
        .message {
            display: none;
            &:last-child {
                display: flex;
            }
        }
    }
}
