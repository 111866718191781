//imagen
$full__width__media-img-home-height         : 840px;
$builder-static__button-color-text          : $color-primary;
$builder-static__button-hover-bg            : $color-secondary;
$builder-static__button-border-color        : $white;
$builder-static__full-width-max-height      : initial;

$builder-static__with-btn-txt-button-bottom\@medium : $spacer--extra-large;
$builder-static__with-btn-txt-button-bottom :  $spacer--medium;

$builder-static__font-size                  : $font-size-large - 6;


$builder-static__with-btn-txt-text-bottom   : $spacer--extra-large*2.5;
$builder-static__with-btn-txt-padding-left  : $spacer--extra-large;
$builder-static__with-btn-txt-padding-right : $spacer--extra-large;

$builder-static__with-hover-figure-bg       : $white;
$builder-static__with-hover-figure-width    : 450px;
$builder-static__with-hover-figure-margin   : auto;

$builder-static__with-button-img-width      : 100%;
$builder-static__with-button-img-height     : 850px;
$builder-static__with-button-img-overflow   : hidden;

$builder-static__with-zoom-figure-width     : 550px;
$builder-static__with-zoom-figure-margin    : 0 0 $spacer--medium  0;
$builder-static__with-zoom-padding          : 40px 0px;
$builder-static__with-zoom-figure-width-450 : 450px;
$table-static-border-color                  : $color-primary;
