@import 'forgot-password-variables';

.customer-account-forgotpassword{

    .header__wrapper {
        width: 200px;
        justify-content: center;

        .header__logo {
            margin-left: 0px;
        }
    }
}
.forgot-password {
    margin-top:200px;

    &__info{
        color: $color-primary;
        font-size: $font-size-base;
        &.forgot{
            font-size: $font-size-extra-large;
            margin-bottom: 24px;
        }
    }

    &__actions {
        .actions-group__button{
            background-color: $color-primary;
            color: white;
            &:hover{
                transition: none;
                border: 1px solid $color-primary;
                color: white;
                background-color: $color-primary;
                transition: none;
            }
            &:before{
                transition: none;
                background-color: $color-primary;
            }
        }
    }

    &__captcha {
        color: $color-primary;
        border: 1px solid $color-primary;
        background-color: $white;
        &:before {
            background-color: $white;
            transition: none;
        }
        &:hover{
            background-color: $white;
            transition: none;
            border: 1px solid $color-primary;
        }
    }

    label {
        color: $color-primary;
        font-size: $font-size-base;

        &:after {
            color: $color-primary !important;
        }
    }

    &__validation-tips{
        font-size: $font-size-extra-small;
    }

}
