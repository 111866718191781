@import 'store-locator-variables';
.amlocator-index-index {
    .heading--page {
        text-align: center;
        margin-bottom: $spacer--medium;
        @include mq($screen-m) {
            margin-bottom: $spacer--large;
        }
    }
}
.store-locator {
    &__button-search {
        top: 0;
        &.-hidden {
            display: none;
        }
    }

    &__today-label {
        font-size: $store-locator__label-font-size;
        @include mq($screen-m) {
            font-size: $store-locator__label-font-size\@medium;
        }
    }

    &__stores {
        @include mq($screen-m) {
            flex-direction: column;
        }
        @include mq($screen-xl) {
            flex-direction: row;
        }
    }

    // adjust store-locator styles

    .amlocator {
        &-store-list {
            margin-top: $spacer--semi-medium;
            @include mq($screen-m) {
                margin-top: 0;
            }
        }

        &-store-information {
            font-size: $font-size-small;
            font-weight: $font-weight-extra-light;
            .amlocator-title {
                font-size: $store-locator__store-information-title-font-size;
                font-weight: $font-weight-base;
                margin-bottom: 0;
            }
        }

    }

    .amlocator-title {
        margin: $store-locator__search-title-margin;
        line-height: 1.3;
        .amlocator-link {
            word-break: keep-all;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .amlocator-search-container {
        border: $store-locator__search-border-width;
        background-color: $store-locator__search-bg;
        border-width: $store-locator__search-border-width;
        padding: $store-locator__search-padding;
        margin: $store-locator__search-margin;
    }

    .amlocator-filters-container {
        background-color: transparent;
        padding: $store-locator__filter-padding;
        margin: $store-locator__filter-margin;
    }

    .amlocator-store-desc {
        background-color: $store-locator__store-description-bg;
        &.-active {
            background-color: $store-locator__store-description-bg--active;
            color: $store-locator__store-description-color--active;
            border: $store-locator__store-description-border--active;

            .amlocator-title {
                .amlocator-link {
                    color: $store-locator__store-description-color--active;
                }

            }

            .amlocator-schedule-table {
                color: $store-locator__store-schedule-color--active;
            }

            & + .amlocator-schedule-container {
                background-color: $store-locator__store-schedule-bg--active;
            }

            .amlocator-today {
                color: $store-locator__store-schedule-color--active;
                background-color: $store-locator__store-schedule-label-bg--active;
                @include mq($screen-m, $screen-xl) {
                    flex-wrap: wrap;
                }

                .amlocator-time {
                    @include mq($screen-m, $screen-xl) {
                        width: 100%;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    .amlocator-input {
        margin: $store-locator__input-margin;
    }

    .amlocator-schedule-table {
        margin: $store-locator__store-schedule-margin;
    }

    // maps popup
    .amlocator-description {
        margin: $store-locator__store-description-margin;
    }
    .amlocator-info-popup {
        font-family: $font-family-base;
        font-size: $font-size-small;
        font-weight: $font-weight-extra-light;
        line-height: 1.3;
        .amlocator{
            &-image {
                margin-bottom: 0;
            }
            &-name {
                font-weight: $font-weight-base;
                font-size: $heading-font-size--page;
                margin-bottom: 0;
                .amlocator-title {
                    font-size: $store-locator__store-information-title-font-size;
                    margin-bottom: $spacer--extra-small;
                }
            }
        }
    }
    .amlocator-description {
        p {
            font-size: $font-size-base;
        }
    }

    .input__field {
        font-size: $font-size-base;
    }
    // to overwrite amasty styles loaded in php script :/
    &.amlocator-main-container {
        .amlocator-search-container,
        .amlocator-filters-container {
            .amlocator-button {
                color: $store-locator__button-color;
                background-color: $store-locator__button-bg;
                border: $store-locator__border;
                font-weight: $store-locator__button-font-weight;
                min-height: $store-locator__button-min-size;
                padding: $store-locator__button-padding;

                &:hover,
                &:focus,
                &.focus-visible {
                    background-color: $store-locator__button-bg--active;
                }
            }
        }


        .amlocator-search-container  {
            .amlocator-wrapper-bottons {
                display: flex;
                flex-direction: column;
                gap: $spacer--small;
                @include mq($screen-m) {
                    flex-direction: row;
                }
                .button {
                    font-weight: $font-weight-medium;
                    text-transform: uppercase;
                    @include mq($screen-m) {
                        margin-top: $spacer--small;
                    }
                }
            }
            .amlocator-button.-nearby {
                color: $store-locator__button-color;
                background-color: $store-locator__button-bg;

                &:hover,
                &:focus,
                &.focus-visible {
                    background-color: $store-locator__button-bg--active;
                }

                &:disabled,
                &[disabled] {
                    @extend .button:disabled;
                }
            }
        }

        .amlocator-filters-container {
            .amlocator-select[multiple] {
                background-image: none;
            }

            .amlocator-clear {
                color: $color-primary;

                &:hover,
                &:focus,
                &.focus-visible {
                    color: $store-locator__button-color;
                }
            }
        }
    }

    .amlocator-current-location .amlocator-text {
        height: $store-locator__action-height;

        &:hover,
        &:focus,
        &.ficus-visible {
            border: $store-locator__input-border--active;
        }
    }
}

.amlocator-location-container {
    .amlocator-modes {
        border: none;
    }

    .amlocator-column .amlocator-button {
        color: $store-locator__button-color;
        border: $store-locator__border;
        font-weight: $store-locator__button-font-weight;
        background-color: $store-locator__button-bg;

        &:hover,
        &:focus,
        &.focus-visible {
            color: $store-locator__button-color;
            background-color: $store-locator__button-bg--active;
        }
    }

    .amlocator-route-creator {
        background-color: $store-locator__route-creator-bg;

        .amlocator-mode,
        .amlocator-swap {
            display: unset;
            @include visually-hidden;

            &+ .amlocator-label {
                opacity: $store-locator__route-creator-icon-opacity;
            }

            &:focus-visible + .amlocator-label {
                opacity: 1;
                @include focus;
            }
        }

        .amlocator-mode:checked + .amlocator-label,
        .amlocator-mode:focus + .amlocator-label,
        .amlocator-mode + .amlocator-label:hover {
            background-color: $store-locator__route-creator-label-bg;
        }

        .amlocator-route-icon .amlocator-icon.-dot {
            opacity: $store-locator__route-creator-icon-opacity;
            background-color: $store-locator__route-creator-icon-bg;
        }
    }

    .amlocator-location-gallery {
        .amlocator-image {
            object-fit: cover;
        }
    }

    .amlocator-review-popup {
        .review-field-text textarea {
            width: 100%;
        }

        .review-form-actions .action.submit.primary {
            background-color: $store-locator__button-bg;

            &:hover,
            &:focus,
            &.focus-visible {
                color: $store-locator__button-color;
                background-color: $store-locator__button-bg--active;
            }
        }

        .review-rating-row {
            display: flex;
            align-items: $store-locator__rating-row-align;

            .radio {
                margin: $store-locator__rating-radio-margin;
            }
        }
    }
}

.amlocator-schedule-container {
    background-color: $gray-lighter;
    .amlocator-today {
        min-height: $store-locator__action-height;
        align-items: center;
        &:before {
            display: none;
        }
    }
}

.amlocator-range-slider {
    margin: $store-locator__range-slider-margin;
    background-color: $store-locator__range-slider-bg-color !important; // sass-lint:disable-line no-important
    .noUi-tooltip {
        display: block;
        font-size: $store-locator__range-slider-font-size;
    }
    .ui-slider-handle {
        display: none;
    }
}
.amlocator-main-container {
    .amlocator-map-container {
        .amlocator-block.-map {
            @include mq($screen-m) {
                height: 85vh !important; // sass-lint:disable-line no-important;
            }
        }
    }
}
