@import "extend-variables";
@import "styles/light";
@import "account-links/account-links";
@import "loginAsCustomer";


.header {
    background: transparent;
    width: 99%;
    z-index: 999;
    &--active, &--search_open {
        background: $white;
        box-shadow: $shadow;
        position: fixed;
        width: 100%;
    }

    &__wrapper {
        align-items: center;
        z-index: $header__wrapper-zindex;
        @include mq($max-screen: $screen-m - 2) {
            padding-left: $spacer--medium !important;
            padding-right: $spacer--medium !important;
        }
    }

    .logo {
        margin-left: auto;
        margin-right: auto;
        order: 2;
        @include mq($screen-m) {
            margin-left: 15px;
        }
        @include mq($screen-xxl) {
            margin-left: 40px;
        }

    }

    &__buttons {
        flex: unset;
        max-width: unset;
        order: 4;
        @include mq($screen-m) {
            flex: unset;
            max-width: unset;
        }
        @include mq($screen-xl) {
            flex: unset;
            max-width: unset;
        }
        .account-links {
            order: 1;
        }
        [data-block="minicart"]{
            order: 2;
        }

        .header__switchers{
            order: 3;
        }
    }

    &__switchers {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: $header__switchers-padding;

        @include mq($screen-s) {
            flex-direction: row;
            justify-content: center;
            width: $header__switchers-width\@small;
        }

        @include mq($screen-xl) {
            width: $header__switchers-width\@xl;

        }
        @include mq($screen-l) {
            margin-right: $header__switchers-margin-rigth\@large;
        }

    }

    &__dropdown-switcher {
        width: auto;
        background-color: $header__switcher-background-color;

        .dropdown-list__label  {
            background-color: $header__switcher-label-background-color;
            padding: $header__switcher-label-padding ;
            color: $header__switcher-label-color;
            fill: $header__switcher-label-color;
            white-space: nowrap;
            display: inline-flex;
            &:hover {
                .dropdown-list__icon {
                    fill: $header__switcher-label-color;
                }
            }
            @media (min-width: ($screen-m - 1)) and (max-width: $screen-xl){
                padding: 12px 20px 8px 0px;

                .dropdown-list__icon{
                    right: -5px;
                }

            }
            .customer-name{
                position: relative;
                // top: -2px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                @media (min-width: ($screen-m + 2)) and (max-width: $screen-xl){
                    width: 160px;

                }
                @media (min-width: ($screen-m - 1)) and (max-width: ($screen-m + 1)){
                    width: 130px;
                }
                @include mq($max-screen: $screen-s) {
                    width: 110px;
                }
            }

            .language-selected{
                @include mq($screen-m) {
                    font-weight: $font-weight-extra-light;
                    position: relative;
                    top: -1px;
                }
            }
        }

        .dropdown-list__content  {
            &#dropdown-customer-content{
                min-width: $header__switcher-content-min-width;
                a{
                    font-weight: $font-weight-extra-light;
                    &:hover{
                        text-decoration: none;
                    }
                }
                .link.authorization-link {
                    width: 100%;
                    .display-none {
                        display: flex;
                    }
                    .icon-user {
                        display: none;
                    }
                    a:before {
                        left: -22px;
                        top: 4px;
                    }
                }
            }
            &#dropdown-language-content{
                text-align: center;
            }
            width: $header__switcher-content-width;
            @include mq($screen-m) {
                position: absolute;
                bottom: $header__switcher-content-bottom;
                padding: $header__switcher-content-padding;
                background-color: $header__switcher-content-background-color;
                box-shadow: $header__switcher-content-shadow;
            }
            .button {
                font-weight: $header__switcher-button-font-weight;
                min-height: $header__switcher-button-min-height;
                text-decoration: $header__switcher-button-text-decoration;

                &:hover,
                &:focus,
                &.focus-visible {
                    text-decoration: $header__switcher-button-hover-text-decoration;
                }
            }
            li{
                padding: $header__switcher-content-link-padding;
                &.nav.item{
                    white-space: pre-wrap;
                }
                &:hover{
                    text-decoration: none;
                    a{
                        text-decoration: none;
                    }
                }
                &:last-child{

                    &.link.authorization-link{
                        margin-top: $header__switcher-li-logout-margin-top;
                        padding-left: $header__switcher-li-logout-padding-left;
                        position: relative;
                        &:hover{
                            a{
                                text-decoration: none;
                            }
                        }
                        a{
                            font-weight: $header__switcher-li-logout-font-weight;
                            &:before {
                                content: fa-content($fa-var-power-off);
                                @extend .fal;
                                position: absolute;
                                left: 0;
                            }
                        }
                    }

                }
            }
        }

        .dropdown-list__icon {
            fill: $header__switcher-label-color;
        }
    }


    &__search-toggler {
        order: 4;
        padding: 0;
        justify-content: flex-end;
        @include mq($screen-m) {
            display: none;
            padding: $button__padding--icon;
        }

        @include mq($max-screen: $screen-m - 1) {
            .button--icon {
                justify-content: flex-end;
                padding-right: 0;

                [class*="fa-"] {
                    margin-top: 10px;
                }

                &:hover {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        &[aria-expanded="true"] + .header__search-wrapper {
            display: flex;
        }
    }

    &__search-wrapper {
        display: none;
        flex-basis: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
        order: 6;
        @include mq($screen-m) {
            display: flex !important;
            flex-basis: auto;
            padding: $header__search-wrapper-padding;
            order: 3;
        }
        @media (min-width: $screen-m + 80) and (max-width: $screen-l)  {
            max-width: 170px;
        }
        @media (min-width: $screen-m) and (max-width: $screen-m + 81)  {
            max-width: 110px;
        }
    }

    &__minicart {
        padding-left: 0;
        .icon-bag{
            display: inline-block;
            position: relative;
            // top: -3px;
            width: 18px;
            height: 18px;
        }
    }

    &__minicart-modal {
        height: $header__minicart-height;
        z-index: 999;
        .modal__close-button{
            position: absolute;
        }
        @include mq($max-screen: $screen-s) {
            // se deja el hack por si el navegador no detecta el dinamic view
            height: $header__minicart-height\hack;
            height: $header__minicart-height\dynamic-view;
        }
    }

    &-button {
        &__counter {
            border-left: unset !important;
        }
        &__total {
            display: none !important;
        }
    }
    .search-form__input {
        font-family: $font-family-base;
        @include mq($max-screen: $screen-s) {
            background-color: $white;
            &.focus-visible {
                background-color: $white;
            }
            &::placeholder {
                color: $color-primary;
                font-size: $input__placeholder-font-size;
            }
            &::-webkit-input-placeholder {
                color: $color-primary;
            }

            &:-ms-input-placeholder {
                color: $color-primary;
            }
        }
    }
}
