.popup-authentication {
    .container {
        & .auth {
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
        }
    }
}
