@import 'toolbar-variables';

.toolbar {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: $toolbar__padding;
    border-style: $toolbar__border-style;
    border-color: $toolbar__border-color;
    border-width: $toolbar__border-width;

    @include mq($screen-m) {
        align-items: center;
        justify-content: flex-start;
        padding: $toolbar__padding\@medium;
    }

    @include mq($screen-l) {
        padding: $toolbar__padding\@large;
    }

    .pager {
        display: none;
    }

    &--secondary {
        justify-content: $toolbar__justify-content--secondary;
        flex-wrap: nowrap;
        padding: $toolbar__padding--secondary\@medium;

        @include mq($max-screen: $screen-s) {
            flex-wrap: wrap;
            padding: $toolbar__padding--secondary;
        }

        .pager {
            display: block;
        }

        .toolbar__amount {
            display: flex;
            order: $toolbar__amount-order--secondary;
            flex-grow: $toolbar__amount-flex-grow--secondary;
        }

        .toolbar__pager {
            order: $toolbar__pager-order--secondary;
            flex-grow: $toolbar__pager-flex-grow--secondary;
            flex-basis: $toolbar__pager-flex-basis--secondary;
            margin: $toolbar__pager-margin--secondary;
        }

        .toolbar__limiter {
            order: $toolbar__sorter-limiter-order--secondary;
            flex-grow: $toolbar__sorter-limiter-flex-grow--secondary;
        }
    }

    &__mode,
    &__limiter,
    &__amount,
    &__sorter {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    &__mode {
        display: flex;
        flex: 0 0 auto;
        margin: $toolbar__mode-margin;
    }

    &__mode-button {
        .icon {
            width: $toolbar__mode-button-icon-size;
            height: $toolbar__mode-button-icon-size;
            fill: $toolbar__mode-button-icon-fill;
        }

        &--active {
            .icon {
                fill: $toolbar__mode-button-icon-fill--active;
            }
        }
    }

    &__amount {
        display: none;
        color: $toolbar__amount-color;
        flex-grow: 1;
        justify-content: center;
        text-align: center;
        @include mq($screen-m) {
            display: flex;
        }

        &--visible {
            display: flex;
            flex: 0 0 auto;
            align-items: center;
            justify-content: flex-start;
            padding: $toolbar__amount-padding--visible;

            @include mq($screen-m) {
                flex: 1 0 0;
            }

            @include isIE() {
                flex-grow: 0;

                @include mq($screen-m) {
                    flex-grow: 1;
                }
            }
        }

        &-content {
            font-size: $toolbar__secondary--font-size\@medium ;
            color: $toolbar__secondary--color;
            font-weight: $toolbar__secondary--font-weight;
            @include mq($max-screen: $screen-s) {
                font-size: $toolbar__secondary--font-size;
            }
        }

    }

    &__amount-content {
        white-space: nowrap;
    }

    &__sorter {
        order: $toolbar__sorter-order;
        width: $toolbar__sorter-width;
        padding: $toolbar__sorter-padding;
        margin: $toolbar__sorter-margin;
        border-style: $toolbar__border-style;
        border-color: $toolbar__border-color;
        border-width: $toolbar__sorter-border-width;

        @include mq($screen-m) {
            order: $toolbar__sorter-order\@medium;
            width: $toolbar__sorter-width\@medium;
            padding: $toolbar__sorter-padding\@medium;
            margin: $toolbar__sorter-margin\@medium;
            border-width: $toolbar__sorter-border-width\@medium;
        }

        &-label {
            white-space: nowrap;
        }

        .select2 {
            flex-grow: 1;
            min-width: $toolbar__sorter-select-min-width;
        }

    }

    &__sorter-link {
        padding: $toolbar__sorter-link-padding;
        font-weight: $toolbar__sorter-link-font-weight;
        &:hover,
        &.focus-visible {
            background-color: $toolbar__sorter-link-bg-hover;
        }
    }

    ~ .toolbar {
        border-bottom: 0;

        .pager {
            display: block;
        }

        .toolbar {
            &__mode,
            &__sorter,
            &__amount,
            &__limiter {
                display: none;
            }
        }
    }
}
