@import 'page-builder-variables';

.full__width__banner-main-home--img,
.full__width__banner-main-home--video,
.builder__static__with-full-width,
.builder__static__with-btn-txt {

    [data-content-type="text"],
    [data-content-type="buttons"] {
        position    : absolute;
        left        : 0;
        right       : 0;
        margin-left : auto;
        margin-right: auto;
    }

    [data-content-type="buttons"] {
        bottom: $builder-static__with-btn-txt-button-bottom;

        @include mq($screen-m) {
            bottom: $builder-static__with-btn-txt-button-bottom\@medium;
        }
        div.pagebuilder-button-link{
            @include mq($max-screen: $screen-m - 1) {
                font-size: $builder-static__font-size;
            }
        }
    }



    //BUTTON-TEXT ---  DOWM
    &.btn-txt-down,
    &.btn-txt-left,
    &.btn-txt-right {

        [data-content-type="text"] {
            bottom: $builder-static__with-btn-txt-text-bottom;
        }

    }

    //BUTTON-TEXT ---  LEFT
    &.btn-txt-left {

        [data-content-type="buttons"],
        [data-content-type="text"] {
            text-align  : left;
            padding-left: $builder-static__with-btn-txt-padding-left;
        }

    }

    //BUTTON-TEXT --- RIGHT
    &.btn-txt-right {

        [data-content-type="buttons"],
        [data-content-type="text"] {
            text-align   : right;
            padding-right: $builder-static__with-btn-txt-padding-right;
        }

    }
}

.full__width {
    &__banner {

        &-main-home {
            &--img,
            &--video{
                position     : relative;
                left         : 50%;
                overflow     : hidden;
                transform    : translateX(-50%);
                width        : 99.5vw;
                height       : auto;



                &.btn-txt-down {
                    [data-content-type="text"] {
                        top   : unset;
                        bottom: $builder-static__with-btn-txt-text-bottom;
                    }
                }

                &.btn-txt-left {

                    [data-content-type="buttons"] {
                        text-align: left;
                    }

                    [data-content-type="text"] {
                        top   : unset;
                        bottom: $builder-static__with-btn-txt-text-bottom;

                        p {
                            text-align: left !important;
                        }
                    }
                }

                &.btn-txt-right {
                    [data-content-type="buttons"] {
                        text-align: right;
                        @include mq($max-screen: $screen-m) {
                            max-width: 90%;
                        }
                    }

                    [data-content-type="text"] {
                        top   : unset;
                        bottom: $builder-static__with-btn-txt-text-bottom;

                        p {
                            text-align: right !important;
                        }
                    }
                }

                [data-content-type="buttons"] {
                    text-align: center;

                }

                [data-content-type="text"] {
                    top: 50%;

                    p {
                        text-align: center !important;
                    }
                }
                .block-banners {
                    margin: 0 !important;
                    ul.banner-items {
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                }

            }

            &--video{
                height: 100vh;
               /* @include mq($max-screen: $screen-m + 1) {
                    height: 55vh;
                }
                @include mq($max-screen: $screen-s) {
                    height: 35vh;
                }*/
            }

            &--img{
                @include mq($screen-xl - 1) {
                    height: 100vh;
                }

                figure {
                    margin: 0;
                    img {
                        width: 100%;
                        @include mq($max-screen: $screen-m) {
                            height: 100vh;
                        }
                    }
                }
            }


        }
    }

}


.builder__static {
    margin: $spacer--large 0;

    &__with {

        &-full-width,
        &-btn-txt {

            position       : relative;
            align-items    : center;
            justify-content: center !important;
            text-align     : center;

        }

        &-full-width {
            width      : 99.5vw;
            left       : 50%;
            margin-left: 0;
            transform  : translateX(-50%);
            max-height : $builder-static__full-width-max-height;
            overflow   : hidden;

            figure {
                margin: 0;
                width : 100%;

                img {
                    width: 100%;
                }
            }
        }

        &-btn-txt {
            figure {
                margin: 0;

                img {
                    width   : $builder-static__with-button-img-width;
                    height  : auto;
                    overflow: unset;

                    @include mq($screen-m) {
                        height  : $builder-static__with-button-img-height;
                        overflow: $builder-static__with-button-img-overflow;
                    }
                }
            }

            &.align-border-left,
            &.align-border-right{
                position: relative;
            }
            &.align-border-right{
                @include mq($screen-l) {
                    right:  -40px;
                }
            }
            &.align-border-left{
                @include mq($screen-l) {
                    left: -40px;
                }
            }
        }

        &-hover {
            align-items: center;
            position   : relative;

            &-group {

                &.group-with-head {
                    .pagebuilder-column-group {
                        flex-direction : column;
                        justify-content: center;
                        align-content  : center;

                        @include mq($screen-m) {
                            flex-direction: row;
                        }

                        .pagebuilder-column {
                            @include mq($max-screen: $screen-m) {
                                width: 100% !important;
                                *{
                                    text-align: center !important;
                                }
                            }

                            .pagebuilder-button-link {
                                width: 100%;
                                @include mq($screen-m) {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            a:hover {
                text-decoration: none;
            }

            .hover-wrapper {
                position: relative;
                margin  : $spacer--semi-medium;

                .img-pre-hover{
                    background-color: $white;
                }

                &:hover {
                    .img-hover {
                        opacity: 1;
                    }
                }
            }

            .img-hover {
                background-color: $white;
                position  : absolute;
                top       : 0;
                left      : 0;
                opacity   : 0;
                transition: all .3s ease-in-out;
            }

            .img-text {
                margin-top: $spacer--medium;
                text-align: center;
                font-size: $builder-static__font-size;
                @include mq($screen-m) {
                    font-size : $font-size-medium;
                }
            }
            &.--single{
                [data-content-type="html"]{
                    @include mq($max-screen: $screen-m + 1) {
                        display: block;
                        width: 100%;
                    }
                }
            }
            &.--group {
                display        : flex;
                flex-direction : column;
                justify-content: center;
                @include mq($screen-m) {
                    flex-direction: row;
                }

                // slider movil
                &.slider{

                    display: none;
                    @include mq($max-screen: $screen-m ) {
                        display: block;
                    }

                    .img-hover {
                        background-color: $white;
                        position  : relative;
                        top       : unset;
                        left      : unset;
                        opacity   : unset;
                    }
                    .slider__navigation{
                        display: block !important;
                        .slider__nav-button{
                            width: 30px;
                            min-width: 30px;
                            fill: $color-primary;
                        }
                    }
                    .--max-w-550{
                        .hover-wrapper{
                            @include mq($max-screen: $screen-m ) {
                                max-width: 100%;
                            }
                        }

                    }
                }

                &:not(.slider){
                    display: flex;
                    @include mq($max-screen: $screen-m + 1) {
                        display: none;
                    }
                }
            }

            div[data-content-type='text'] {
                padding-top: $spacer--medium;
            }
        }

        &-hover,
        &-zoom {
            &:not(.slider){
                &.--max-w-550 {
                    figure,
                    .hover-wrapper {
                        max-width: 100%;

                        @include mq($screen-xxl) {
                            max-width: $builder-static__with-zoom-figure-width;
                        }

                        @include mq($max-screen: $screen-xxl - 1) {
                            max-width: $builder-static__with-zoom-figure-width - 150;
                        }
                        @include mq($max-screen: $screen-xl - 1) {
                            max-width: $builder-static__with-zoom-figure-width - 200;
                        }
                    }
                }
                &.--max-w-450 {
                    figure,
                    .hover-wrapper {
                        max-width: 100%;
                        @include mq($screen-m) {
                            max-width: $builder-static__with-zoom-figure-width-450;
                        }
                    }
                }
            }
        }

        &-zoom {
            align-items: center;
            @include mq($max-screen: $screen-m - 1) {
                padding: $builder-static__with-zoom-padding ;
            }
            figure {
                background-color: $white;
                width   : auto;
                margin  : $builder-static__with-zoom-figure-margin;
                overflow: hidden;

                img {
                    margin    : auto;
                    transition: transform .5s ease;
                }

                &:hover {
                    img {
                        transform: scale(1.5);
                    }
                }
            }
        }
    }
}

.img-h-850 {

    figure,
    img {
        height: auto;

        @include mq($screen-xl) {
            height: 850px !important;
        }
    }
}

.img-h-468 {

    figure,
    img {
        height: auto;
        width : auto;

        @include mq($screen-xl) {
            height: 468px !important;
        }
    }
}

.img-w-345 {

    figure,
    img {
        width: 100%;

        @include mq($screen-xl) {
            width: 345px !important;
        }
    }
}

[data-content-type="row"] {
    table {
        border-color: $table-static-border-color;
    }
    strong,
    b{
        font-weight: $font-weight-medium;
    }
}


// Bottones
.btn-outline-white {
    .pagebuilder-button-link {
        background-color: transparent;
        border-color    : $white;
        color           : $white;
    }

    &:hover {
        .pagebuilder-button-link {
            border-color    : $color-primary-light;
            background-color: $color-primary-light;
            color           : $white;
        }
    }
}


.btn-outline-primary {
    .pagebuilder-button-link {
        background-color: transparent;
        border-color    : $color-primary;
        color           : $color-primary;
    }

    &:hover {
        .pagebuilder-button-link {
            border-color    : $color-primary-light;
            background-color: $color-primary-light;
            color           : $white;
        }
    }
}


.btn-bg-primary {
    .pagebuilder-button-link {
        background-color: $color-primary;
        border-color    : $color-primary;
        color           : $white;
    }

    &:hover {
        .pagebuilder-button-link {
            border-color    : $color-primary-light;
            background-color: $color-primary-light;
            color           : $color-primary;
        }
    }

}

.btn-bg-white {
    .pagebuilder-button-link {
        background-color: $white;
        border-color    : $color-primary;
        color           : $color-primary;
    }

    &:hover {
        .pagebuilder-button-link {
            border-color    : $color-primary-light;
            background-color: $color-primary-light;
            color           : $color-primary;
        }
    }
}
.slider__next,
.slider__prev{
    svg{
        fill: $color-primary;
    }
}
