$dashboard-table__header-margin         : 0 0 $spacer--semi-medium !default;
$dashboard-table__header-padding        : 0 !default;
$dashboard-table__header-background     : transparent !default;
$dashboard-table__header-border         : $border-secondary !default;
$dashboard-table__header-border-width   : 0 0 $border-width-secondary !default;

$dashboard-table__content-margin        : 0 0 $spacer--semi-medium !default;

$dashboard-table__title-margin          : 0 $spacer--medium 0 0 !default;
$dashboard-table__title-font-family     : $font-family-base !default;
$dashboard-table__title-font-size       : $font-size-medium !default;
$dashboard-table__title-font-weight     : $font-weight-normal !default;
$dashboard-table__title-text-transform  : none !default;

$dashboard-table__link-font-size        : $font-size-base !default;
$dashboard-table__link-font-weight      : $font-weight-normal!default;
$dashboard-table__text-decoration       : none !default;
$dashboard-table__link-padding          : 0 0 0 $spacer/2 !default;
$dashboard-table__link-first-padding    : 0 $spacer/2 0 0 !default;

$dashboard-table___title_link-padding   : 0 0 0 $spacer--medium !default;

$dashboard-table__lines-border         : 1px solid $color-primary !default;
$dashboard-table__lines-padding        : $spacer--semi-medium 0 !default;
$dashboard-table__lines-line-letter-spacing : 0.11em;

$dashboard-table__totals-width         : calc(100% - #{$spacer});
