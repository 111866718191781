@import 'side-menu-variables';

.side-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: $spacer--extra-small;
    @include mq($screen-l) {
        left: $spacer--large - 4px;
    }
    @include mq($screen-m) {
        left: $spacer--semi-medium - 4px;
    }

    @include mq($side-menu__bq-visibility-max) {
        display: none;
    }

    &__trigger {
        background-color: $side-menu__trigger-bg-color;
        justify-content: flex-start;
        padding: 0;
        width: 1px;
        @include mq($screen-m) {
            border: $side-menu__trigger-border\@medium;
            border-width: $side-menu__trigger-border-width\@medium;
            justify-content: center;
            padding: $side-menu__trigger-padding\@medium;
            margin: $side-menu__trigger-margin\@medium;
        }
    }

    &__trigger-icon{
        @include mq($screen-m) {
            width: $side-menu__button-size;
            height: $side-menu__button-size;
        }
    }

    &__modal {
        box-shadow: $shadow;
        background: $side-menu__bg-color;
        width: $side-menu__modal-width;
        height: $side-menu__modal-height;
        @include mq($screen-m) {
            width: $side-menu__modal-width\@medium;
            background: $side-menu__bg-color\@medium;
        }
        @include mq($max-screen: $screen-s) {
            // se deja el hack por si el navegador no detecta el dinamic view
            height: $side-menu__modal-height\hack;
            height: $side-menu__modal-height\dynamic-view;
        }
    }

    .modal{

        &--tertiary{
            justify-content: flex-start;
            @include mq($screen-m) {
                justify-content: center;
            }
        }

        &--active{
            &::before{
                content: ' ';
                width: 99.8vw;
                height: 99.8vh;
                background-color: $modal__background-color;
                position: absolute;
                z-index: -1;
            }
            .side-menu {
                &__container-extra-head,
                &__container-extra-bottom {
                    left: 0;
                    position: relative;
                    width: 100%;
                }
            }
        }

    }


    &__modal-logo{
        // margin: $side-menu__modal-logo-margin;
        width: 100%;
        height: 120px;
        position: relative;
        background: $side-menu__modal-close-button-bg;
        -webkit-animation: animateleft 0.4s;
        animation: animateleft 0.4s;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        @include mq($screen-m) {
           // display: block;
            background-color: $side-menu__modal-close-button-bg\@medium ;
        }

        .logo{
            margin: 0;
            left: unset;
            @include mq($screen-m) {
                top: 0;
                left: 48px;
                position: relative;
            }
        }
    }
    .header__logo{
        margin-left: 0 !important;
        margin-right: 0 !important;
        @include mq(max-width $screen-m -1) {
            position: relative;
            top: -9px;
            left: 19px;
        }
    }
    .logo__image{
        height: $side-menu__modal-logo-height;
    }

    &__container {
        margin-top: $side-menu__modal-container-top;
        max-width: $side-menu__modal-container-max-width;
        box-shadow: $side-menu__modal-container-box-shadow;
        padding: $side-menu__modal-container-padding;
        max-height: $side-menu__modal-container-max-height;
        background: $side-menu__modal-close-button-bg;
        overflow-y: auto;
        @include mq($screen-m) {
            background: $side-menu__bg-color\@medium;
            max-height: $side-menu__modal-container-max-height\@medium;
            margin-top: $side-menu__modal-container-top\@medium;
        }
    }

    &__content {
        max-width: $side-menu__modal-content-max-width;
        margin: $side-menu__modal-content-margin;
        overflow-y: auto;
        @include hide-scrollbar();
    }

    &__content-middle {
        padding: $side-menu__modal-middle-padding;
        overflow: visible;


        .dropdown-list{
            background: $side-menu__bg-color;

            @include mq($screen-m) {
                background: $side-menu__bg-color\@medium;
            }
            &__label{
                font-size: $side-menu__container-content-menu-font-size;
                padding: 0 ;
                background-color: $white;
                @include mq($screen-m) {
                    background-color: inherit;
                }
                &.link--invert{
                    font-size: $side-menu__container-content-menu-n0-font-size;
                    font-variant: small-caps;
                }
            }
            &__link-label{
                padding: 0 ;
            }

            &__item{
                border-bottom: $dropdown-list__label-border-bottom--inner;

                &--collapse{
                    a{
                        display: inline-flex;
                        &.arrow-down{
                            float: right;
                            width: 10%;
                            height: $spacer--semi-large/2;
                        }
                    }

                    &.dropdown-list__item--level1,
                    &.dropdown-list__item--level2{
                        .arrow-down{
                            //@include mq($screen-l + 1) {
                                display: none;
                            //}
                        }
                        &:hover{
                            >.arrow-down{
                                display: none;
                                @include mq($screen-m) {
                                    display: block;
                                }
                            }
                        }
                        >.dropdown-list__content{
                            display: none;
                            li{
                                padding: 0; //$spacer--extra-small/2 0 ;
                            }
                        }
                        >.dropdown-list__label{
                            &[aria-expanded="true"],
                            &[aria-expanded="false"]{
                                >.dropdown-list__icon{
                                    transform: rotate(0deg);
                                }
                            }
                        }
                        &.li-active{
                            >.arrow-down{
                                display: block;
                            }
                            >.dropdown-list__content{
                                display: flex;
                            }
                            >.dropdown-list__label{
                                &[aria-expanded="true"],
                                &[aria-expanded="false"]{
                                    >.dropdown-list__icon{
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }
                    }
                }
                a{
                    background-color: transparent;
                    font-weight: $side-menu__container-content-menu-font-weight;
                    transition: unset;
                    &:hover{
                        font-weight: $side-menu__container-content-menu-font-weight-hover;
                        text-decoration: none;
                    }
                }
            }
            &--inner {
                .dropdown-list__item--all{
                    display: none;
                }
            }
            &--with-nested{

                .dropdown-list__icon{
                    right: 0;
                }
            }
        }
    }

    &__content-bottom {
        padding: $side-menu__modal-bottom-padding;
        border: $side-menu__modal-bottom-border;
    }

    &__bottom-actions {
        max-width: $side-menu__modal-bottom-actions-max-width;
        margin: $side-menu__modal-bottom-actions-margin;
    }

    &__bottom-action {
        width: $side-menu__modal-bottom-action-width;
        flex-basis: $side-menu__modal-bottom-action-width;
    }

    &__close-button {
        width: $side-menu__modal-close-button-width;
        border: $side-menu__modal-close-button-border;
        top: $side-menu__modal-close-button-top;
        left: $side-menu__modal-close-button-left;
        background: $side-menu__modal-close-button-bg;
        z-index: 999;

        @include mq($screen-m) {
            background: $side-menu__modal-close-button-bg\@medium;
            top: $side-menu__modal-close-button-top\@medium;
            left: $side-menu__modal-close-button-left\@medium;
        }

        &:before {
            content: unset;
        }
        .modal__close-button-icon{
            @include mq($screen-m) {
                width: $side-menu__button-size-close;
                height: $side-menu__button-size-close;
            }
        }


    }

    &__close-button-text {
        display: none;
        margin: $side-menu__modal-close-button-text-margin;
        color: $side-menu__modal-close-button-text-color;
        font-family: $side-menu__modal-close-button-text-font-family;
        font-size: $side-menu__modal-close-button-text-font-size;
        font-weight: $side-menu__modal-close-button-text-font-weight;
    }


    &__container-extra-tabs{
        margin: $side-menu__modal-container-extra-tabs-margin;
        padding: $side-menu__modal-container-extra-tabs-padding;
        display: block;
        margin: unset;
        width: auto;
        align-self: center;
        -webkit-animation: animateleft 0.4s;
        animation: animateleft 0.4s;
        a{
            color: $font-color-base;
            padding: $spacer 0;
            &.extra-tabs--active{
                color: $color-primary;
                text-decoration: underline;
            }
            &:hover{
                color: $color-primary;
            }
        }
        .menu {
            //display: none;
        }
        .authorization-link {
            a {
                .icon-user{
                    width: $side-menu__container-content-menu-n0-font-size + 2px;
                    height: $side-menu__container-content-menu-n0-font-size + 2px;
                    fill: $side-menu__container-extra-contacto-color;
                }
            }
        }
    }

    &__container-extra-head,
    &__container-extra-bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        //padding: $side-menu__modal-container-padding;
        background: $side-menu__modal-close-button-bg;
        position: relative;
        opacity: 1;
        visibility: visible;
        -webkit-animation: animateleft 0.4s;
        animation: animateleft 0.4s;
    }
    &__container-extra-bottom {
        padding: 0;
        @include mq($max-screen: $screen-s) {
            padding: 0 0 $spacer--semi-large 0;
        }
    }
    &__container-extra-head{
        padding: $side-menu__modal-container-extra-head-padding;
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include mq($screen-m) {
            display: none;
        }
    }

    &__container-extra-social{
        padding: 0;//$side-menu__container-extra-social-pading ;
        margin-top: 0; //$side-menu__container-extra-social-margin-top;
        border-top: none;
        text-align: center;
        @include mq($screen-m) {
            margin-top: $side-menu__container-extra-social-margin-top;
            padding: $side-menu__container-extra-social-pading\@medium ;
            display: none;
        }
        .pagebuilder-column-group {
            flex-wrap: nowrap;
            .pagebuilder-column {
                padding: $side-menu__container-extra-social-pading-column;
                width: auto !important;
                @include mq($max-screen: $screen-m) {
                    border-top: $side-menu__container-extra-contacto-border ;
                    border-bottom: $side-menu__container-extra-contacto-border ;
                    border-right: $side-menu__container-extra-contacto-border ;
                }
                &:last-child {
                    border-right: 0;
                }
                a {
                    text-decoration: none;
                    font-weight: 200;
                    @include mq($max-screen: $screen-m) {
                        padding: $spacer 0;
                    }
                }
            }
        }
    }
    &__container-extra-minicart{
        margin: unset;
        align-self: center;
    }
    //movil estilos despues de haber movido los elementos
    &__container-extra-lang {
        order: -1;
        @include mq($screen-m) {
            order: unset;
        }
        .dropdown-list{
            width: 100%;
            &__list{
                display: flex;
                flex-direction: row;
                width: 100%;

                .dropdown-list__label {
                    span {
                        @include mq($max-screen: $screen-m) {
                            text-transform: uppercase;
                        }
                    }
                }
            }

            &__icon{
                display: none;
            }
            &__item{
                display: flex;
                justify-content: center;
                .language-selected, a {
                    text-transform: lowercase;
                    font-weight: $font-weight-light;
                }
            }
            &__content{
                display: flex !important;
                padding: 0 !important;
                position: relative !important;
                background: transparent !important;
                width: auto !important;
                .list{
                    display: flex;
                    &__item{
                        padding: $side-menu__container-extra-lang-list-item-pading;
                    }
                }
                a{
                    @include mq($max-screen: $screen-m) {
                        text-transform: uppercase;
                    }
                }
            }
        }
        @include mq($screen-m) {
            display: none;
        }
    }

    &__container-extra-login{
        .customer-welcome.list__item{
            width: 100%;

            a{
                font-size: $side-menu__container-content-menu-n0-font-size;;
            }
            .dropdown-list__label{
                display: none;
            }
            .dropdown-list__list{
                width: 100%;
            }
            #dropdown-customer-content{
                display: block !important;
            }

            .authorization-link{
                a{
                    color: $red;
                    width: 100%;
                    &::before{
                        @extend .far;
                        @extend .fa-power-off;
                        font-size: $side-menu__container-content-menu-font-size;
                        content: '\f011';
                        position: absolute;
                        right: 0;
                        color: $red;
                    }
                }

                // padding: $side-menu__container-extra-login-link-padding;
            }
        }

    }

    &__container-extra-contacto {
        h6 {
            font-size: $side-menu__container-extra-contacto-font-size-h6;
            margin-top: $side-menu__container-extra-contacto-margin-top;
            margin-bottom: 0;
            text-transform: uppercase;
            letter-spacing: 0.11em;
            text-align: center;
        }
        span {
            font-size: $side-menu__container-extra-contacto-font-size;
            color: $side-menu__container-extra-contacto-color;
            font-weight: $font-weight-light;
        }
        @include mq($screen-m) {
            margin-bottom: $side-menu__container-extra-contacto-margin-bottom;
        }
        @include mq($max-screen: $screen-m) {
            order: 2;
        }
    }
    &__contacto {
        border-top: none;
        //margin-left: $side-menu__container-extra-contacto-border-left;
        //margin-right: $side-menu__container-extra-contacto-border-right;
        @include mq($screen-m) {
            border-top: $side-menu__container-extra-contacto-border;
        }
    }

    &__container-dashboard-navigator{
        width: 100%;
        .dashboard-nav__mobile{
           display: none;

        }
        .dashboard-nav__content{
            display: block !important;
            padding: 0;
        }
    }


    //clase añaddida a través del BO en el  para reposnive de img añadida al menú
    &__block-img{
        display: none;

        @include mq($screen-m) {
            margin-top: $side-menu__modal-block-img-margin-top;
            display: block;
        }

        figure{
            margin: 0;
        }
    }
}

.body-open-menu {
    iframe#launcher {
        z-index: 99!important;
    }
}
