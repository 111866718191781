@import "extend-variables";
.dashboard-form {
    &__title {
        padding: $dashborad-form__title-padding;
        background: $dashborad-form__title-background;
        font-family: $dashborad-form__title-font-family;
        font-size: $dashborad-form__title-font-size;
        font-weight: $dashborad-form__title-font-weight;
        border: $dashborad-form__title-border;
        border-width: $dashborad-form__title-border-width;
    }

    &--hidden {
        display: none;
    }

    .label,
    .input__label,
    .input__field,
    select {
        font-size: $dashboard-form__label-font-size;
    }
}
