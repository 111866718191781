
@import "extend-variables";

.catalog-category-view,
.catalogsearch-result-index {

    .catalog-list{
        display: flex;
        flex-direction: column;
        flex-flow: column;

        &__banner{
            margin: 0;
            border: none;
            @include mq($screen-m) {
                padding: 0 !important;
            }
            .banner__heading{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                padding-left: $spacer;

                &-wrapper{
                    margin: $catalog-category__heading-margin;
                }

                .heading{
                    font-weight: $font-weight-light;
                    letter-spacing: normal;
                    padding-right: 16px;
                    text-align: left;
                    white-space: nowrap;
                    @include mq($max-screen: $screen-m - 1) {
                        white-space: normal;
                        width: 100%;
                    }
                }
                .dropdown-list{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @include mq($max-screen: $screen-m - 1) {
                        width: 10%;
                    }
                    .product-list-item-menu__toggle{
                        @include mq($max-screen: $screen-m) {
                            padding: 8px 0;
                        }
                        span{
                            @include mq($max-screen: $screen-m - 1) {
                                display: none;
                            }
                        }
                    }

                }

            }
        }

        &__products{
            a.skip-nav{
                display: none;
            }
            .search.results{
                dl{
                    &.block{
                        padding-left: $catalog-list__block-search-padding-left;
                        padding-right: $catalog-list__block-search-padding-right;
                        @include mq($screen-m) {
                            padding-left: $catalog-list__block-search-padding-left\@medium;
                            padding-right: $catalog-list__block-search-padding-right\@medium;
                        }
                    }
                    dt{
                        padding-right: $catalog-list__block-search-result-padding-right;
                        &:after{
                            content: ":";
                        }
                    }
                    dd{
                        padding-left: $catalog-list__block-search-padding-left;
                        &:last-child{
                            margin-bottom: $catalog-list__block-search-result-margin-bottom;
                        }
                    }
                }
            }

            .product-list-item__info{
                .table{
                   border: 0px solid transparent;
                   tbody{
                    border: 0px solid transparent;
                   }
                    tr{
                       border: 0px solid transparent;
                        &:nth-child(odd) {
                            background: $catalog-category__info-table-odd;
                        }
                        &:nth-child(even) {
                            background: $catalog-category__info-table-even;
                        }
                    }

                }
            }
        }


        &__sidebar{
            margin: $catalog-category__heading-margin;
        }

        &__nav-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @include mq($screen-m) {
                flex-wrap: nowrap;
            }
            >* {
                @include mq($screen-m) {
                    flex: 1 1 33%;
                    align-self: center;
                    margin: 0;
                }
            }
            .breadcrumbs.container {
                width: 100%;
                @include mq($screen-m) {
                    padding: $spacer $spacer--medium;
                    width: auto;
                }
            }
            .catalog-list__banner,
            .catalog-list__filter-link {
                width: 50%;
                @include mq($screen-m) {
                    width: auto;
                }
            }


            .banner__heading-wrapper.container {
                margin: auto;
                align-items: start;
                @include mq($screen-m) {
                    align-items: center;
                    padding-left: $spacer--medium;
                }
                .banner__heading {
                    justify-content: left;
                }
            }
        }

        &__filter-link {
            @include mq($screen-m) {
                text-align: right;
            }

            input#show_filters[type="checkbox"] {
                display: none;
            }

            label[for="show_filters"], button.modal-trigger {
                cursor: pointer;
                padding: 20px $spacer--medium 0 0;
                margin: 0;
                line-height: normal;
                text-align: right;
                @include mq($screen-m) {
                    padding: $spacer 20px;
                }
                @include mq($screen-xl) {
                    padding: $spacer $spacer--semi-large;
                }
            }

            label {
                &.show-filters {
                    display: block;
                }
                &.modal-trigger {
                    display: none;
                }
            }

        }
    }

    .page-main{
        &.container{
            margin: 0;
            max-width: 100%;
        }
    }

    .primary.amscroll-load-button {
        display: flex;
        align-items: center;
        margin: 40px auto 10px auto;
        padding: 5px 40px;
        max-width: 100%;
        width: max-content;
        background-color: $white;
        text-align: center;
        text-transform: capitalize;
        letter-spacing: .03em;
        font-family: $font-family-base;
        font-weight: $font-weight-medium;
        font-size: 1.6rem;
        line-height: 2rem;
        cursor: pointer;
        border: 1px solid $color-primary !important;
        transform: perspective(1px) translateZ(0);
        transition: all 0.5s ease-in-out;

        &:hover {
            background-color: $color-primary;
            >.amscroll-text {
                color: $white;
            }
        }

        .amscroll-loading-icon.-amscroll-animate {
            animation: amScrollRotate 1.5s linear infinite;
        }

        >.amscroll-loading-icon {
            display: none;
            margin-right: 10px;
        }

        >.amscroll-text {
            word-wrap: break-word;
            word-break: break-word;
            transition: filter .2s;
            pointer-events: none;
            user-select: none;
            font-size: $font-size-base;
            font-family: $font-family-base;
            color: $color-primary;
        }
    }

    :not(.filter-options) > .toolbar-products{
        display: none !important;
    }
    .catalog-grid {
        grid-column-gap: $spacer--extra-small;
        grid-row-gap: $spacer--extra-small;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-flow: dense;
        -ms-grid-columns: 1fr 1fr; /* For IE */
        -moz-grid-columns: 1fr 1fr; /* For Firefox */
        -webkit-grid-columns: 1fr 1fr; /* For Safari */
        @include mq($screen-m) {
            grid-template-columns: repeat(4, 1fr);
        }
        //padding: $catalog-list__block-grid-padding;
        padding-top: $spacer--extra-small;
        padding-bottom: 0;
    }
        
    .toolbar__amount {
        padding-top: $spacer--semi-large;
    }
}
