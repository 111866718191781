$side-menu__bq-visibility-max : $screen-l !default;

$side-menu__trigger-bg-color : transparent;

$side-menu__trigger-padding\@medium : 0 $spacer 0 0 !default;
$side-menu__trigger-margin\@medium  : 0 !default;

$side-menu__trigger-border\@medium : unset !important;

$side-menu__trigger-border-width\@medium : 0 1px 0 0 !default;

$side-menu__bg-color         : $bg-color-body;;
$side-menu__bg-color\@medium : $bg-color-body;

$side-menu__modal-width         : 100%;
$side-menu__modal-width\@medium : 350px;
$side-menu__modal-height        : 100vh;
$side-menu__modal-height\dynamic-view  : 100dvh;
$side-menu__modal-height\hack   : calc(var(--vh, 1vh) * 100);

$side-menu__modal-logo-height         : 26px;
$side-menu__modal-logo-margin         : $spacer--medium auto;
$side-menu__modal-logo-margin\@medium : $spacer--semi-medium auto $spacer--semi-large auto;
$side-menu__modal-logo-top            : unset;

$side-menu__modal-container-top : 0px !default;

$side-menu__modal-container-top\@medium : 0;

$side-menu__modal-container-padding            : 0 $spacer--semi-medium 0 $spacer--large;
$side-menu__modal-container-max-width          : 100% !default;
$side-menu__modal-container-max-height         : calc(100vh - #{$side-menu__modal-container-top}) !default;
$side-menu__modal-container-max-height\@medium : calc(100vh - #{$side-menu__modal-container-top\@medium}) !default;
$side-menu__modal-container-box-shadow         : none !default;

$side-menu__modal-container-extra-head-padding : 0 0 $spacer--large 0;
$side-menu__modal-container-extra-tabs-margin  : $spacer--semi-medium 0;
$side-menu__modal-container-extra-tabs-padding : 0 $spacer--semi-medium 0 $spacer--large;

$side-menu__modal-container-extra-margin : $spacer--extra-large 0 0 0;

$side-menu__modal-content-max-width : 100%;
$side-menu__modal-content-margin    : 0 auto !default;

$side-menu__modal-middle-padding      : 0;
$side-menu__modal-middle-link-padding : $spacer--extra-small 0;

$side-menu__modal-bottom-border            : 0 !default;
$side-menu__modal-bottom-padding           : $spacer--semi-large 0 0 !default;
$side-menu__modal-bottom-actions-max-width : 100% !default;
$side-menu__modal-bottom-actions-margin    : 0 !default;
$side-menu__modal-bottom-action-width      : 100% !default;

$side-menu__button-size : 32px !important;
$side-menu__button-size-close: 34px;

$side-menu__modal-close-button-border          : none !important;
$side-menu__modal-close-button-width           : 48px;
$side-menu__modal-close-button-top             : 14px;
$side-menu__modal-close-button-top\@medium     : 32px;
$side-menu__modal-close-button-left            : 4px;
// $side-menu__modal-close-button-left         : $spacer--small;
$side-menu__modal-close-button-left\@medium    : 30px; // $side-menu__modal-close-button-left;

$side-menu__modal-close-button-bg         : $bg-color-body;
$side-menu__modal-close-button-bg\@medium : $bg-color-body;

$side-menu__modal-close-button-text-margin     : 0 0 0 $spacer--medium !default;
$side-menu__modal-close-button-text-color      : $color-primary !default;
$side-menu__modal-close-button-text-font-family: $font-family-secondary !default;
$side-menu__modal-close-button-text-font-size  : $font-size-medium !default;
$side-menu__modal-close-button-text-font-weight: $font-weight-normal !default;

$side-menu__modal-block-img-margin-top     : $spacer--semi-large * 2;
$dropdown-list__label-border-bottom--inner : 0;

$side-menu__container-extra-lang-list-item-pading: $spacer--small $spacer--semi-large $spacer--small $spacer--small;
$side-menu__container-extra-social-margin-top    : $spacer--small;
$side-menu__container-extra-social-pading\@medium: $spacer--semi-medium 0;
$side-menu__container-extra-social-pading        : $spacer--semi-medium 0 $spacer--small 0;
$side-menu__container-extra-social-border-top    : 1px solid $color-primary;
$side-menu__container-extra-social-pading-column : $spacer--extra-small;

$side-menu__container-extra-login-link-padding : $spacer--semi-medium 0;

$side-menu__container-content-menu-font-size        : $font-size-base;
$side-menu__container-content-menu-n0-font-size     : $font-size-base; //18px;

$side-menu__container-content-menu-font-weight      : $font-weight-extra-light;
$side-menu__container-content-menu-font-weight-hover: $font-weight-normal;

$side-menu__container-extra-contacto-font-size        : 14px;
$side-menu__container-extra-contacto-font-size-h6     : $spacer--medium;
$side-menu__container-extra-contacto-margin-top       : $spacer--semi-medium;
$side-menu__container-extra-contacto-color            : $color-primary;
$side-menu__container-extra-contacto-border           : 1px solid $color-primary;
$side-menu__container-extra-contacto-border-right      : -#{$spacer--semi-medium};
$side-menu__container-extra-contacto-border-left     : calc(-#{$spacer--semi-medium} - 4px);
$side-menu__container-extra-contacto-margin-bottom   : $spacer--semi-medium;
