@import "extend-variables";

.cart-grid {

	&__footer {
		flex-direction: row;
		justify-content: space-between;
		.button {

			&.clear {
				background: transparent;
				border: 0px solid;
				padding: 0;
				svg.icon {
					width         : 18px;
					height        : 18px;
					padding       : 0;
					fill          : $color-primary;
					color         : $color-primary;
					/* transition : all 0.3s ease-in-out; */
					z-index       : 1;
					margin-right  : $spacer--small;
				}
			}

			&.update,
			&.continue {
				border: 1px solid $color-primary;
			}

		}
		&--right{
			.continue{
				@include mq($screen-xl) {
					margin-left: $spacer--semi-medium;
				}
			}
		}
	}

	&__header {
		background   : $cart-grid__header-background;
		border-bottom: 0;
		padding      : 0 $spacer--large;

		@include mq($screen-xl) {
			display: flex;
		}

		display: none;

		.header {
			&_product_type {
				width: 15%;
			}

			&_products {
				width     : 65%;
				text-align: center;
			}

			&_total {
				width: 20%;
			}
		}

	}

	&__header-items {

	}

	&__header-productos {
		@include mq($screen-xl) {
			text-align: center;
		}
	}

	&__header-product-info {

	}

	&__header-price,
	&__header-subtotal {
		@include mq($screen-xl) {
			text-align: right;
		}
	}

	&__header-qty {

	}

	.grouped {


		&-item-container {
			.cart-list-item {
				border-bottom: 1px;
			}
		}

		&-desktop-tittle {
			display: none;
			flex: 0 0 auto;
			width: 35%;
			@include mq($screen-xl) {
				display       : block;
				word-break    : break-word;
				padding       : 10px 30px;
			}
		}
		&-desktop-tittle,
		&-mobile-tittle {
			span {
				display: block;
				font-size: $font-size-extra-small;
			}
		}
        &-items.free {
            .cart-price{
                    text-decoration:line-through;
            }

        }
		&-items {
			width: 100%;

			@include mq($screen-xl) {
				// width         : 65%;
			}
		}

		&-desktop-price {
			display: none;

			@include mq($screen-xl) {
				display        : block;
				// width          : 15%;
				text-align     : right;
				padding        : 10px 30px;

			}
		}


		&-mobile-tittle {
			text-align      : center;
			background-color: #FFFEFC;
			font-size       : $font-size-base;
			border-left     : 1px solid $border-color-base;
			border-right    : 1px solid $border-color-base;
			padding         : 10px;
			font-weight     : $font-weight-medium;

			@include mq($screen-xl) {
				display: none;
			}

		}


		&-mobile-price {
			text-align      : center;
			background-color: #FFFEFC;
			font-size       : $font-size-base;
			border-left     : 1px solid $border-color-base;
			border-right    : 1px solid $border-color-base;
			display         : flex;
			justify-content : flex-end;
			padding         : 10px 18px 0px 18px;
			border-bottom   : 1px solid $border-color-base;
			font-weight     : $font-weight-medium;

			@include mq($screen-xl) {
				display: none;
			}
		}



		&-row-container {
			display: flex;

			@include mq($screen-xl) {
				border-left     : 1px solid $color-primary;
				border-bottom   : 1px solid $color-primary;
				border-right    : 1px solid $color-primary;
				display         : flex;
				align-items     : center;
				background-color: #FFFEFC;
			}

			.grouped-desktop-tittle {
				align-self: flex-start;
				padding   : 28px 30px;
			}
		}

		&-table {
			width: 100%;

			.cart-list-item {
				&__image {
					.ratio-container {
						padding: 0 !important;
						max-width: 80px;
						height: auto;
						.product-image-photo {
							transform: unset;
							left     : 0;
							top      : 0;
							position : relative;
						}

					}
				}
				&__qty {
					@media (min-width: $screen-xl) and (max-width: 1400px){
						margin-left: 24px;
					}
				}
			}
		}
	}
}


.checkout-cart-index {
	.heading--page {
		font-size    : $heading-font-size--page;
		text-align   : left;
		margin-bottom: 0px;

		@include mq($screen-xl) {
			font-size    : $heading-font-size--page\@screen-m;
			text-align   : center;
			margin-bottom: 50px;
		}
	}

	#maincontent {
		@include mq($screen-xl) {
			margin: 0 150px;
		}
	}
    .order-summary {
        .order-summary__list-item {
            &.order-summary__total {
                display: flex;
            }
            .discount.coupon {
                display: none;
            }
        }
        .order-summary__divider{
            display: none;
        }
    }
}

.cart {
	&__summary {
		order: 3;

		@include mq($screen-l) {
			margin-left: auto;
			order      : 2;
		}
	}
	&-discount {
		width: 100%;
		@include mq($screen-l) {
			width: 29.6%;
			margin-left: auto;
			margin-right: 24px;
			margin-top: 40px;
		}

		&.active {
			.cart-discount__button {
				border-color: $color-primary;
			}
		}
		&__button{
			justify-content: flex-end;
			border-color: transparent;

			&-text {
				font-size: $font-size-medium;
			}
			svg {
				fill: $color-primary;
			}
		}
		&__content-input {
			margin-bottom: $spacer;
		}

		&__content-input,
		&__content-button {
			width: 60%;
			@include mq($max-screen: $screen-m) {
				width: 100%;
			}
		}

		&__controls {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

	}
}
