@import "qty_increment-variables";

.box-tocart-qty,
.product-item-actions .actions-primary .box-tocart-qty,
td.qty .control.qty,
.details-qty,
.cart-list-item__qty,
.quantity-update {
    align-items: stretch;
    display: flex;
    flex-basis: 50%;
    flex-direction: row;
    height: 1px;
    max-height: 40px;
    min-height: $qty-btn-width;
    padding-right: 4px;

    label {
        margin-bottom: 0;
        margin-top: 0;
        align-self: center;
        margin-right: .5rem;
        order: 0;
    }
    button[class*="qty-"] {
        @extend .button;
        color: $qty-btn-color;
        background-color: $qty-btn-bg-color;
        border-color: $qty-btn-border-color;
        width: $qty-btn-width;
        max-width: $qty-btn-max-width;
        min-width: $qty-btn-min-width;
        min-height: unset;
        padding: 0;
        font-size: $qty-btn-font-size;
        cursor: pointer;
    }

    button.qty-min {
        border-right: 0;
        order: 1;
        transition: unset !important;
        &:before{
            transition: unset !important;
        }
    }

    button.qty-max {
        border-left: 0;
        order: 3;
        transition: unset !important;
        &:before{
            transition: unset !important;
        }
    }

    input#qty, input.qty, input.cart-item-qty, input[name*=qty] {
        width: calc(100% - #{$qty-btn-width * 2});
        max-width: $qty-btn-max-width;
        min-width: $qty-btn-min-width;
        height: 32px;
        border-radius: 0;
        border: solid 1px $qty-input-border-color;
        background-color: $qty-input-bg-color;
        font-family: $font-family-base;
        font-size: $qty-input-font-size;
        font-weight: $qty-input-font-weight;
        color: $qty-input-color;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;
        -moz-appearance: textfield;
        order: 2;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &:focus{
            box-shadow: none;
        }
    }
}
