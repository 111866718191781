$header__wrapper-zindex                                    : 999 !default;

$header__switchers-padding                                 : $spacer 0 !default;
$header__switchers-width\@small                            : 100% !default;
$header__switchers-width\@xl                               : auto !default;
$header__switchers-margin-rigth\@large                     : 10px;
$header__switchers-order                                   : 3 !default;

$header__switcher-background-color                         : transparent !default;

$header__switcher-label-background-color                   : transparent !default;
$header__switcher-label-padding                            : $spacer--small $spacer--semi-large #{$spacer--small - 4px} $spacer--small;
$header__switcher-label-color                              : $body-color !default;

$header__switcher-content-background-color                 : $body-background; //$gray-white !default;
$header__switcher-content-bottom                           : unset !default;
$header__switcher-content-width                            : 100% !default;
$header__switcher-content-min-width                        : 180px !default;
$header__switcher-content-padding                          : $spacer--small !default;
$header__switcher-content-link-padding                     : $spacer--extra-small 0 !default;
$header__switcher-content-shadow                           : $shadow !default;

$header__switcher-button-font-weight                       : $font-weight-normal !default;
$header__switcher-button-min-height                        : 0 !default;
$header__switcher-button-text-decoration                   : none !default;
$header__switcher-button-hover-text-decoration             : underline !default;

$header__switcher-li-logout-font-weight                    : $font-weight-medium !important;
$header__switcher-li-logout-margin-top                     : $spacer !default;
$header__switcher-li-logout-padding-left                   : $spacer--semi-medium !default;
$header__minicart-height                                   : 100vh;
$header__minicart-height\dynamic-view                      : 100dvh;
$header__minicart-height\hack                              : calc(var(--vh, 1vh) * 100);
