.lac-notification-sticky {
    .top-container {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 34px;
        margin: 0 20px;
        padding: 5px;
        position: fixed;
        z-index: 99999;
        .wrapper {
            &.lac-notification-text {
                margin: auto 15px;
            }
            strong {
                font-weight: 600 !important;
            }
        }
    }
}