@import 'nosto-variables';

.nosto_element {
    @extend .container;
    .nosto-carousel {
        max-width: 768px;
        margin-left: auto;
        margin-right: auto;
        @include mq($screen-m) {
            max-width: 1199px;
        }
    }
    .page-title {
        &.category-title {
            text-align: center;

            h1 {
                font-family: $font-family-base;
                font-weight: $font-weight-light;
                font-size: $nosto_title;
                letter-spacing: normal;
                margin: $spacer 0 0 0;
                @include mq($screen-m) {
                    font-size: $nosto_title\@screen-m;
                }
            }
        }
    }
    .slider-container {
        .slick {
            &-slider{
                background-color: $nosto-item__slick-slider-bg;
            }
            &-track {
                @include mq($screen-m) {
                    display: flex;
                    justify-content: center;
                }
            }
            &-dots {
                bottom: -30px;
                display: block;
                list-style: none;
                margin: 0;
                padding: 0;
                position: absolute;
                text-align: center;
                width: 100%;
                li {
                    cursor: pointer;
                    display: inline-block;
                    height: 14px;
                    margin: 0 5px;
                    padding: 0;
                    position: relative;
                    vertical-align: middle;
                    width: 14px;

                    button {
                        background: $white;
                        border: 1px solid $gray-lighter;
                        border-radius: 10px;
                        box-shadow: none;
                        cursor: pointer;
                        display: block;
                        height: 14px;
                        padding: 0;
                        text-indent: -99999px;
                        transition: 0.3s;
                        width: 14px;

                        &:hover {
                            border: 1px solid $gray-lighter;
                        }

                        &:active,
                        &:focus,
                        &:not(.primary) {
                            box-shadow: none;
                        }
                    }

                    &.slick-active {
                        button {
                            background: $color-primary;
                        }
                    }
                }
            }
        }
        .item {
            display: flex !important;

            .nosto-link {
                font-size: $nosto-item__info-wrapper--name--font-size;
                font-weight: $nosto-item__info-wrapper--name-font-weight;
                width: 100%;
                &:hover {
                    text-decoration: none;
                }
            }
            > .nosto-link {
                align-items: center;
                min-height: 380px;
                padding-top: $spacer--medium;
                @include mq($screen-m) {
                    min-height: 250px;
                }
                @include mq($screen-l) {
                    min-height: 214px;
                }
            }
           .nosto-add-to-cart-wrapper {
               position: relative;
               top: unset;
                .nosto-link {
                    font-size: $nosto-item__info-wrapper-addtocart--font-size;
                    display: flex;
                    justify-content: center;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    span {
                        position: relative;
                        &:after {
                            background-color: $color-primary;
                            bottom: 0;
                            content: '';
                            height: 1px;
                            left: 0;
                            position: absolute;
                            width: 100%;
                        }
                    }
                }
           }
           .nosto-product-info {
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               margin: 0;
               width: 100%;
               @include mq($screen-m) {
                   flex-direction: row;
               }
                .nosto-product-name {
                    //flex: 0 0 80%;
                    text-align: left;
                    @include mq($screen-m) {
                        padding-right: $spacer--extra-small;
                    }
                    .nosto-link {
                        height: $font-size-large;
                        span {
                            display: -webkit-box;
                            line-height: 1.3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: left;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                    .nosto-print {
                        display: block;
                        height: $font-size-small-xs;
                        font-size: $font-size-small-xs;
                        font-weight: $font-weight-light;
                        text-transform: lowercase;
                    }
                }
                .nosto-product-price-box {
                    padding-top: $spacer--extra-small;
                    text-align: left;
                    //flex: 0 0 20%;
                    @include mq($screen-m) {
                        text-align: right;
                    }
                    * {
                        font-size: $nosto-item__info-wrapper--name--font-size;
                        font-weight: $nosto-item__info__price-label-font-weight;
                    }
                }
                &-wrapper {
                    padding: $nosto-item__wrapper-padding;
                }
           }

        }
        .slick-slide {
            //width: 230px !important;
            img {
                width: 100%;

            }
            > div {
                padding: $nosto-item__slide--padding;
            }
        }
    }
}

