.minicart-product {
    border-bottom: 1px solid $color-secondary;

    &__field {
        background: $color-primary-light;
        border: 0;
        color: $color-primary;
        text-align: center;
    }

    &__price {
        color: $color-primary;
        justify-content: flex-end;
        font-weight: $font-weight-normal;
    }

    &__name{
        font-weight: $font-weight-extra-light;
    }
    &__bottom{
        flex-wrap: wrap;
        @include mq($max-screen: $screen-m) {
            height: 35px;
        }
    }

    &__actions{
        justify-content: flex-end;
        position: relative;
        top: -$spacer/2;
        @include mq($screen-m) {
            top: -$spacer;
        }
    }

    &__name-wrapper{
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        gap: $spacer--small;
    }
    &__data{
        flex-flow: row wrap;
    }
    &__info {
        width: 100%;
    }
}
