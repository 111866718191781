@import 'message-variables';

.message {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: $max-content-width;
    padding: $message__padding;
    margin: $message__margin;
    background-color: $message__background;
    border-color: $message__border-color;
    border-width: $message__border-width;
    border-style: $message__border-style;
    border-radius: $message__border-radius;
    color: $message__color;
    font-size: $message__font-size;
    transition: $message__transition;

    &[style="display: block;"] {
        display: flex !important; // sass-lint:disable-line no-important
    }

    p {
        color: $message__color;
        font-size: $message__font-size;
        line-height: inherit;
        margin: 0;
    }
    a{
        color: inherit;
    }
    @include mq($screen-m) {
        padding: $message__padding\@medium;
    }

    &:after {
        content: '';
        position: relative;
        order: $message__icon-order;
        height: $message__icon-spacer;
        width: $message__icon-width;
        min-width: $message__icon-width;
        margin: $message__icon-margin;
        background-image: $message__icon-bg-image;
        background-repeat: no-repeat;
        background-size: $message__icon-spacer;
    }

    &--success {
        background-color: $message__background--success;
        border-color: $message__border-color--success;
        color: $message__color--success;

        &:after {
            background-image: $message__icon-bg-image--success;
        }
    }

    &--error {
        background-color: $message__background--error;
        border-color: $message__border-color--error;
        color: $message__color--error;

        &:after {
            background-image: $message__icon-bg-image--eror;
        }
    }
    &--info{
        background-color: $message__background--info;
        border-color: $message__border-color--info;
        color: $message__color--info;

        &:after {
            background-image: $message__icon-bg-image--info;
        }
        
    }
    &--notice {
        background-color: $message__background--notice;
        border-color: $message__border-color--notice;
        color: $message__color--notice;

        &:after {
               background-image: $message__icon-bg-image--notice;
        }
        
    }
    .link {
        text-decoration: underline;
    }
}

//extend messages generated in backend or 3rd party modules
.message.success,
.message-success {
    @extend .message--success;
}

.message.error,
.message-error {
    @extend .message--error;
}

.message.info,
.message-info {
    @extend .message--info;
}
.message.notice,
.message-notice {
    @extend .message--notice;
}
