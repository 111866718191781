@import 'table-variables';

.table {
    width: $table__width;
    margin: $table__margin;
    padding: $table__padding;
    background: $table__background;
    border: $table__border;
    border-radius: $table__border-radius;
    border-collapse: $table__border-collapse;
    border-spacing: $table__border-spacing;
    color: $table__color;
    font-size: $table__font-size;
    line-height: $table__line-height;
    white-space: nowrap;
    @include mq($screen-m) {
        font-size: $table__font-size\@medium;
    }

    &--odd-even {
        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--odd-even;
                }

                &:nth-child(even) {
                    background: $table__background-even--odd-even;
                }

                &:first-child {
                    border-top-left-radius: $table__border-top-left-radius-first-child--odd-even;
                    border-top-right-radius: $table__border-top-right-radius-first-child--odd-even;

                    @include mq($screen-m) {
                        border-top-left-radius: $table__border-top-left-radius-first-child--odd-even\@medium;
                        border-top-right-radius: $table__border-top-right-radius-first-child--odd-even\@medium;
                    }
                }
            }

            td {
                background-color: $table__td-background--odd-even;
                font-weight :$table__td-font-weight--odd-even;


            }
        }
    }

    &--clean {
        margin: 0;
        border: 0;
        border-radius: 0;

        thead {
            tr {
                padding: 0;
                border-bottom: 0;
            }
        }

        th {
            border-bottom: 0;
            border-right: 0;
            background: $table__th-background--clean;
            text-align: left;
            font-weight: $table__th-font-weight--clean;
        }

        td {
            border-bottom: 0;
            border-right: 0;
            background: $table__th-background--clean;
        }

        tbody {
            tr {
                &:nth-child(odd) {
                    background: $table__background-odd--clean;
                }

                &:nth-child(even) {
                    background: $table__background-even--clean;
                }
            }
        }
    }

    &--scope-row {
        border-width: $table__border-width--scope-row;

        tbody {
            tr {
                padding: $table__tr-padding--scope-row;

                &:nth-child(odd) {
                    th[scope="row"],
                    td {
                        background-color: $table__tr-background-odd--scope-row;
                    }
                }

                &:last-child {
                    border-bottom: $table__tr-border-bottom;

                    td {
                        @include mq($screen-m) {
                            border-bottom: $table__td-border-bottom\@medium;
                        }
                    }
                }

                @include mq($screen-s) {
                    th {
                        &[scope="row"] + td {
                            &:before {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            th {
                font-weight: $table__th-font-wieght--scope-row;
            }
        }
    }

    &__caption,
    &__visually-hidden {
        @include visually-hidden();
    }

    &__heading-wrapper {
        display: flex;
        align-items: center;
    }

    tr {
        display: block;
        padding: $table__tr-padding;
        border-bottom: $table__tr-border-bottom;

        @include mq($screen-m) {
            display: table-row;
            padding: $table__tr-padding\@medium;
            border-bottom: $table__tr-border-bottom\@medium;
        }

        &:last-child {
            border-bottom: 0;

            td {
                @include mq($screen-m) {
                    border-bottom: 0;
                }
            }
        }

        &.tfoot{
            border-bottom: 0px solid;
            background: unset !important;
            @include mq($max-screen: $screen-m) {
                padding: 0 !important;
            }
            &:not(:last-child){
                th,
                td{
                    padding-top: $spacer;
                    padding-bottom: 0;
                }
            }
            &:last-child{
                th,
                td{
                    padding-top: $spacer;
                }
            }
            th{
                border-bottom: 0px solid;
            }
            td{
                flex-flow: row  !important;
                &:before {
                    max-width: 50% !important;
                    width: 50% !important;
                }
            }
        }
    }

    th {
        display: none;
        padding: $table__th-padding;
        background: $table__th-background;
        text-align: $table__th-text-align;
        font-weight:  $table__th-text-weight;
      
        @include mq($screen-m) {
            display: table-cell;
            padding: $table__th-padding\@medium;
            border-bottom: $table__th-border-bottom\@medium;
            border-right: $table__th-border-right\@medium;
            line-height: normal;
        }

        &:last-child {
            border-right: 0;
        }

        &[scope="row"] {
            padding: $table__th-padding-row;
            border-width: $table__th-border-width-row;
            background: $table__th-background-row;
            text-align: $table__th-text-align-row;
            width: $table__th-width-row;

            & + td {
                flex-flow: column nowrap;
                padding: $spacer--medium;

                @include mq($screen-s) {
                    flex-flow: row nowrap;
                }

                &:before {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: $spacer;

                    @include mq($screen-s) {
                        max-width: 50%;
                        width: 50%;
                    }
                }
            }
        }

    }

    td {
        display: flex;
        justify-content: space-between;
        padding: $table__td-padding;
        background: $table__td-background;

        &:not(.col-text):not(.col-name){
            text-align: center;
        }
      
      
        &:before {
            display: block;
            content: attr(data-th);
            font-weight: $table__td-font-weight-before;
            margin-right: $table__td-margin-right-before;
            max-width: $table__td-max-width-before;
            width: $table__td-width-before;
            min-width: $table__td-width-before;
            text-align: left;
            @include mq($screen-m) {
                display: none;
            }
        }

        &:last-child {
            @include mq($screen-m) {
                border-right: 0;
            }

            &.col-actions{
                @include mq($max-screen: $screen-m) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                }
                &:before{
                    @include mq($max-screen: $screen-m) {
                        display: none;
                    }
                }
                a{
                    font-size: $table__link-font-size;
                    white-space: nowrap;
                }
            }
        }
        &.col-text-large,
        &.col-text{
            text-align: right;
            white-space: pre-wrap;
            @include mq($screen-m) {
                text-align: left;
            }
        }

        &.col-text-large{
            @include mq($screen-m) {
                width: 20%;
            }
        }
        
        &.col-text-max-20 {
            white-space: nowrap;
            @include mq($screen-m) {
                width: 18%;
            }
        } 
         
        @include mq($screen-m) {
            display: table-cell;
            padding: $table__td-padding\@medium;
            border-bottom: $table__td-border-bottom\@medium;
            border-right: $table__td-border-right\@medium;
        }
    }

    thead {
        border: $table__thead-border;
        tr {
            padding: 0;
            border-bottom: 0;

            @include mq($screen-m) {
                border-top-left-radius: $table__border-radius;
                border-top-right-radius: $table__border-radius;
            }
        }

        th {
            &:not(.col-number):not(.col-customer_reference){
                @include mq($screen-m) {
                    white-space: normal;
                }
            }
            &.col-customer_reference,
            &.col-name{
                @include mq($screen-m) {
                    width: 20%;
                }

                &.col-text-max-20 {
                    white-space: nowrap;
                    width: 18%;
                }
                
            }
           

            &.col-in_preparation_amount{
                @include mq($screen-m) {
                    min-width: 145px;
                }
            }
            &:first-child {
                @include mq($screen-m) {
                    border-top-left-radius: $table__border-radius;
                }
            }

            &:last-child {
                @include mq($screen-m) {
                    border-top-right-radius: $table__border-radius;
                }
            }
        }
    }

    tbody {
        border: $table__tbody-border;
        @include mq($screen-m) {
            font-size: $font-size-small;
        }
        tr {

            &:last-child {
                td {
                    &:first-child {
                        @include mq($screen-m) {
                            border-bottom-left-radius: $table__border-radius;
                        }
                        .list{
                            font-size: $font-size-small;
                        }
                    }

                    &:last-child {
                        @include mq($screen-m) {
                            border-bottom-right-radius: $table__border-radius;
                        }
                    }
                }
            }
        }
    }

    tfoot {
        tr {
            &:first-child {
                border-top: $table__tfoot-tr-border-top-first-child;

                @include mq($screen-m) {
                    border-top: 0;
                }
            }

            &:last-child {
                border-bottom: 0;

                td {
                    &:first-child {
                        @include mq($screen-m) {
                            border-bottom-left-radius: $table__border-radius;
                        }
                    }

                    &:last-child {
                        @include mq($screen-m) {
                            border-bottom-right-radius: $table__border-radius;
                        }
                    }
                }
            }
        }

        td {
            display: block;
            padding: $table__tfoot-td-padding;
            background: $table__tfoot-td-background;

            @include mq($screen-m) {
                display: table-cell;
                padding: $table__tfoot-td-padding\@medium;
                background: $table__tfoot-td-background\@medium;
                border-top: $table__tfoot-td-border-top\@medium;
                border-right: $table__tfoot-td-border-right\@medium;
                border-bottom: 0;
            }
        }
    }
}
