@import "extend-variables";

.breadcrumbs {
    @include mq($max-screen: $breadcrumbs__display-mq) {
        display: block;
    }
    &__item {
        // line-height: 22px;

        &:first-child {
            a {
                align-items: flex-start;
                top: 3px;
                position: relative;
                &:hover {
                    text-decoration: none;
                }
                .fa-home-alt {
                    &:before {
                        width: 11px;
                        height: 14px;
                        font-size: 16px;
                    }
                }
            }
        }
        &:after {
            color: $color-primary;
            content: '|';
        }
        a {
            color: $color-primary;
            font-weight: $font-weight-extra-light;
        }
        &[aria-current="true"]{
            font-weight: $font-weight-medium;
        }
    }
}

.catalog-product-view {
    .breadcrumbs {
        .breadcrumbs__list {
            .breadcrumbs__item {
                &:nth-last-child(2) {
                  &:after {
                      display: none;
                  }
                }
                &:last-child {
                    display: none;
                }
            }
        }
    }
}
