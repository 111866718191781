@import "extend-variables";

.product-list-item{
    padding-left: $product-list-item-padding-left;
    padding-right: $product-list-item-padding-right;
    @include mq($screen-m) {
        padding-left: $product-list-item-padding-left\@medium;
        padding-right: $product-list-item-padding-right\@medium;
    }
    &:nth-child(odd) {
        background: $lighter-brown-alt;
        .product-item-description,
        .dropdown-list__label{
            background-color: $lighter-brown-alt;
        }
    }
    &:nth-child(even) {
        background: $lighter-brown;
        .product-item-description,
        .dropdown-list__label{
            background-color:  $lighter-brown;
        }
    }

    .dropdown-list{
        background-color: transparent;
        @include mq($max-screen: $screen-m) {
            margin-top: $spacer !important;
        }
        &--inner{
            padding: 0 !important;
        }
        &__item{
            border-bottom: 0px;
        }
        &__label{
            font-size: $product-list-item-info-right__font-size ;
            font-weight: $font-weight-medium;
            text-transform: none;
            padding: 0;
        }
    }

    &__container{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    &__actions{
        margin-bottom: $spacer--small;
        top: 0;
        @include mq($screen-xl) {
            min-width: 250px;
        }
        @include mq($screen-m) {
            margin-bottom: 0;
            position: relative;
            top: -4px;
        }
        .product-view__stock{
            border: 0px;
        }
    }

    &__details{
        width: 100%;
        @include mq($screen-m) {
            padding: $product-list-item-products__details-padding\@medium;
        }
        @include mq($screen-xxl) {
            padding: $product-list-item-products__details-padding\@xlarge;
        }
        .product-list-item__name{
            display: none;
            @include mq($screen-m) {
                display: block;
                line-height: 1;
                margin: 0;
                margin-bottom: 5px;

                height: 90px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                -webkit-box-orient: vertical;
            }
        }
    }
    &__name {
        width: 100%;
    }

    &__price{
        @include mq($screen-m) {
            //margin: $product-list-item__name-margin\@medium;
            margin: 0;
            text-align: left;
            .price {
                align-items: flex-start;
            }
        }
        .final{
            height: 42px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-end;
            white-space: normal;
            width: 100%;

            >span{
                display: flex;
            }
            .price__value {
                font-size: 16px;
            }

            .price__label{
                font-size: 16px;
                font-weight: $font-weight-extra-light;
                padding-left: 5px;

                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }
        .recommended {
            display: block;
            line-height: 1;
            padding: 20px 0;

            @include mq($screen-m) {
                position: relative;
                height: 48px;
                padding: 0;
            }

            .price__value {
                font-size: 28px;
                display: block;
                line-height: normal;
                position: relative;
                top: 1px;
            }
            .price__label{
                font-size: $product-list-item-info-right__font-size;
                font-weight: $font-weight-extra-light;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                -webkit-box-orient: vertical;

                @include mq($screen-xxl) {
                    display: inline-block;
                }
            }
        }
    }

    &__info{
    @include mq($screen-m + 1) {
        display: grid;
        grid-template-columns: $product-list-item-product__block__data-img-width\@medium $product-list-item-product__block__products-width\@medium;
    }

    @include mq($max-screen: $screen-m) {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
    }
    &-left{
        padding: 0;
        @include mq($screen-m) {
            padding:$product-list-item-info-left;
            position: relative;
        }

        .inner{
            display: flex;
            flex-direction: column;
            @include mq($screen-m) {
                flex-direction: row;
            }
        }
        .dropdown-list__label{
            width: auto;
        }
        .product-list-item__description{
            display: block !important;
            top: 0;
            @include mq($max-screen: $screen-m) {
                display: none !important;
            }
            @include mq($screen-m) {
                position: relative;
                top: -8px;
            }
            .dropdown-list__list{
                position: relative;
            }
            .product-item-description{
                max-width: 100% !important;
                font-weight: $font-weight-extra-light;
                margin-top: $spacer--small;
                font-size: $product-list-item-info-right__font-size;
                @include mq($screen-m) {
                    padding: $spacer $spacer--small;
                }
                *{
                    font-weight: $font-weight-extra-light;
                }
                p,span{
                    font-size: $product-list-item-info-right__font-size;
                }
                strong,
                b{
                    font-weight: $font-weight-medium;
                    font-size: $product-list-item-info-right__font-size;
                }
            }
        }
    }
    &-right{
        padding: 0;

        @include mq($screen-m + 1) {
            padding:$product-list-item-info-right;
            display: flex;
        }

        .inner{
            top: 0;
            padding-bottom: 15px;

            @include mq($screen-m) {
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 0;
                width: 100%;
            }

            .label, .value{
                margin: 0;
                padding: 0;
                line-height: normal;
                @include mq($screen-m) {
                    line-height: 1.1;
                }
            }

            .value{
                text-transform: lowercase;
            }

            &-info{
                width: 100%;
                @include mq($screen-m) {
                    width: 50%;
                }

                &-nob_materials {
                    order: 2;
                    height: 40px;
                    .value {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        -webkit-box-orient: vertical;
                        @include mq($screen-m) {
                            padding-right: $spacer--medium;
                        }
                    }
                }
                &-nob_medida {
                    order: 1;
                    height: auto;
                    margin: $spacer 0;
                    @include mq($screen-m) {
                        height: 48px;
                        margin: 0;
                    }
                    .value{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        -webkit-box-orient: vertical;
                        @include mq($screen-m) {
                            padding-right: $spacer--medium;
                        }
                    }
                    &_is_empty{
                        display: none;
                        margin: 0;
                        height: 48px;
                        width: 100%;
                        order: 1;
                        @include mq( ($screen-m +1 )) {
                            display: flex;
                        }
                    }
                }
                &-manufacturer{
                    order: 3;
                    height: auto;
                    width: 100%;
                    @include mq($screen-m) {
                        width: 50%;
                    }
                }
            }


        }
        .label, value, td {
            font-size: $product-list-item-info-right__font-size;
            line-height: normal;
            @include mq($screen-m) {
                line-height: 1;
            }
        }
        .label {
            font-weight: $font-weight-normal;
        }
        .value{
            font-weight: $font-weight-extra-light;
        }

        .value-img{
            &-wrapper{
                position: relative;
                right: unset;
                max-height: 88px;
                margin: 16px 0;
                @include mq($screen-m) {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 0;

                    display: flex;
                    justify-content: center;
                }

                img{
                    width: 100%;
                    height: auto;
                    max-height: 88px;
                    object-fit: contain;
                }
            }

            &-tooltip{
                position: relative;
                padding: $spacer--small;
                max-width: $value-img-tooltip-width;
                text-align: center;
                background-color: $white;
                border: 1px solid $color-primary;
                border-radius: 10px;
                bottom: 100%;
                @include mq($screen-l) {
                    position: absolute;
                }

            }
            &-flag{
                position: relative;
                width: $value-img-tooltip-width/2;
                height: 5px;
                left: $value-img-tooltip-width/4;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }

        }

        &-tech {
            margin-bottom: $product-list-item__description-margin-bottom;
            padding-top: $spacer--small/2;
            order: 3;
            @include mq($screen-m) {
                margin: $product-list-item__description-margin;
                padding-top: 0;
            }

            .table {
                background: transparent;
                width: 100%;
                white-space: unset;
                @include mq($screen-m) {
                    margin-bottom: 0;
                }
                tbody {
                    border-left: 0;
                    border-right: 0;
                    tr{
                        @include mq($max-screen: $screen-m) {
                            display: flex;
                            width: 100%;
                        }
                        td {
                            background: transparent;
                            text-align: left !important;
                            &:first-child{
                                font-weight: $font-weight-medium ;
                                white-space: nowrap;
                                @include mq($max-screen: $screen-m) {
                                    white-space: normal;
                                    width: 40%;
                                }
                            }
                            &:last-child{
                                font-weight: $font-weight-extra-light ;
                                white-space: normal;
                                line-height: 1.5;
                                @include mq($max-screen: $screen-m) {
                                    width: 60%;
                                }
                            }
                            &:before{
                                content: unset;
                            }
                        }
                    }
                }
            }
        }

        .product-list-item__description{
            @include mq($max-screen: $screen-m) {
                display: block !important;
            }
            .product-item-description{
                font-weight: $font-weight-extra-light;
                margin-top: $spacer--small;
                *{
                    font-weight: $font-weight-extra-light;
                }
            }
        }
    }
}

    &__data{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        @include mq($max-screen: $screen-m) {
            flex-direction: column !important;
        }
        &-img {
            @include mq($screen-m + 1) {
                width: $product-list-item-product__block__data-img-width\@medium;
            }

            @include mq($max-screen: $screen-m) {
                width: 100%;
            }
            .product-image-photo{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .product-list-item__name{
                display: block;
                margin-bottom: $spacer--medium;
                line-height: normal;
                @include mq($screen-m) {
                    display: none;
                }
            }
            .img-gallery{
                .fotorama__zoom-in-icon{
                    display: none;
                    @include mq($screen-m) {
                        display: block;
                    }
                }
            }
        }
        &-products{
            padding:$product-list-item-products__padding;
            @include mq($screen-xxl  - 1) {
                padding:$product-list-item-products__padding\@xlarge;
            }
            @include mq($screen-m + 1) {
                padding:$product-list-item-products__padding\@medium;
                width: $product-list-item-product__block__products-width\@medium ;
            }
            @include mq($max-screen: $screen-m) {
                width: 100%;
            }
            .product {
                margin-bottom: $spacer--large;
                width: $product-list-item-product__width;
                min-width: $product-list-item-product__min-width;

                .fotorama__zoom-in-icon{
                    display: none;
                }
            }

        }
        &.even{
            flex-direction: row;

        }
        &.odd{
            flex-direction: row-reverse;

        }
    }

    &__main{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: auto;
        grid-gap: $spacer--medium;

        flex-basis: unset;
        max-width: unset;
        @include mq($screen-xxl) {
            //grid-template-columns: repeat(4,1fr);
            grid-template-columns: repeat(4, minmax(180px, 1fr));;
        }
        @include mq($max-screen: $screen-xxl - 1) {
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        }
        //@include mq($max-screen: $screen-l) {
        //    grid-template-columns: repeat(3,1fr);
        //}
        @include mq($max-screen: $screen-m) {
            grid-template-columns: repeat(2,1fr);
        }

    }

    &__actions-inner {

        .box-tocart-qty {
            justify-content: space-between;
            .qty-min {
                order: 1 !important;
            }
            .qty {
                order: 2 !important;
                width: auto !important;
            }
            .qty-max {
                order: 3 !important;
            }
        }
        &--ee {
            .product-list-item__add-to-action {
                width: 100% ;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .button {
                    &--add-to{
                        width: 80%;
                        @include mq($screen-l ) {
                            padding: 0 $spacer;
                        }
                    }
                    .button__text {
                        display: block;
                    }

                }
            }
        }
    }

    &__child-name {
        font-size: $product-list-item__child-name-font-size;
        font-weight: $font-weight-extra-light;

        height: 40px;

        margin-bottom: $spacer--small;
        text-align: center;
        text-transform: lowercase;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;

        &:first-line {
            text-transform: capitalize;
        }
    }

    &__stock {
        &.tooltip {
            display: inline-block;
            width: auto;
            &:after {
                left: -5px;
                top: -30px;
            }
            &:hover {
                text-decoration: none;
            }

            .back-date{
                color:$color-primary;
                font-size: 12px;
                text-transform: lowercase;
                padding: 2px 5px 2px 5px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .tooltip__content-wrapper {
            top: -10px;
            left:15%;
        }
        &--in-stock {
            color: $product-list-item--in-stock__color;
        }
    }

    .product-list__gallery,
    .product-list-item__child__gallery {
        .fotorama__wrapper {
            &._block-content-loading {
                margin: 0 0 $spacer--small 0 !important;
                .gallery__placeholder-image {
                    padding: 0;
                }
            }
            .fotorama__stage {
                margin: 0 0 $spacer--small 0 !important;
                width: 100% !important;
                @include mq($max-screen: $screen-m - 1) {
                    margin: 0 !important;
                    padding-bottom: 0;
                }
            }

        }
    }

    &:nth-child(odd) {
        .fotorama__stage__frame {
            background: $lighter-brown-alt;
        }
    }
    &:nth-child(even) {
        .fotorama__stage__frame {
            background: $lighter-brown;
        }
    }

    .product-list__gallery {
        .fotorama{

            &__stage{
                min-height: auto;
                @include mq($screen-xl ) {
                    min-height: $product-list-item-product__stage-img-max-height;
                }
            }
            &__img{
                // min-height: auto;
                // @include mq($screen-xl ) {
                //     min-height: $product-list-item-product__img-max-height;
                // }
            }
            &__arr {
                &--prev,
                &--next {
                    display: flex;
                    align-items: center;
                    margin-top: $spacer--semi-large;
                    > div {
                        display: flex;
                        align-items: center;
                        padding: $spacer--small;
                        @extend .far;
                        font-size: 30px;
                    }
                }
                &--prev {
                    > div {
                        justify-content: flex-start;
                        @extend .far;
                        @extend .fa-chevron-left;
                    }
                }
                &--next {
                    > div {

                        justify-content: flex-end;
                        @extend .fa-chevron-right;
                    }
                }
            }

            &__zoom-in-icon {
                display: none !important;
            }
        }

    }
    .product-list-item__child__gallery {
        .fotorama__wrapper {
            &._block-content-loading {
                .gallery__placeholder-image {
                    width: 100%;
                }
            }
        }
        .fotorama__arr {
            display: none !important;
        }
    }
}
