$price__spacing                : $spacer--small !default;
$price__font-size              : $font-size-small-xs !default;
$price__font-size\@medium      : $font-size-large !default;
$price__font-weight            : $font-weight-normal;

$price__color--old             : $color-primary !default;
$price__font-size--old         : $font-size-small-xs - 2px !default;
$price__font-size--old\@medium : $font-size-base !default;
$price__font-weight--old       : $font-weight-normal !default;

$price__color--special         : $color-primary !default;
$price__font-weight--special   : $font-weight-normal !default;

$price__text-color             : $color-secondary !default;
$price__text-font-size         : $font-size-base !default;
$price__text-font-size\@medium : $font-size-medium !default;
