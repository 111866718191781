$catalog-category__banner : 0 $spacer--semi-large;

$catalog-category__heading-margin             : auto $spacer--semi-large;

$catalog-category__info-table-odd : #FFFEFC;
$catalog-category__info-table-even: #FCF5ED;

$catalog-list__block-search-padding-left  : $spacer--semi-medium;
$catalog-list__block-search-padding-right : $spacer--semi-medium;

$catalog-list__block-search-padding-left\@medium : $spacer--semi-large;
$catalog-list__block-search-padding-right\@medium : $spacer--semi-large;

$catalog-list__block-search-result-padding-right: $spacer;
$catalog-list__block-search-result-margin-bottom: $spacer--semi-large;

$catalog-list__block-grid-padding:  $spacer--medium 0;


