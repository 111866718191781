@import "extend-variables";

.cart-list-item {
    background: #FFFEFC 0 0 no-repeat padding-box;
    border-top: 0;
    border-left: $cart-list-item__border;
    border-right: $cart-list-item__border;
    border-bottom: $cart-list-item__border;
    border-top: 0px;
    border-bottom: 0px;
    padding: 0px 18px;
    display: flex;
    flex-wrap: wrap;

    @include mq($screen-xl) {
        flex-wrap: nowrap;
        border-left: 0px;
        border-right: 0px;
        padding: 10px 0;
    }

    &__input{
        height: 20px;
    }

    .cart-list-item__input{
        height: 20px;
    }

    .cart-list-item__content{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

    }
    &__image{
        &.mobile{
            @include mq($screen-xl) {
                display: none;
            }

        }

        &.desktop{
            display: none;
            @include mq($screen-xl) {
                display: block;
            }
        }

        @include mq($screen-xl) {
            width: 50px;
        }
    }

    &:last-of-type {
       /* border: 1px solid #F2D9BD; */
    }

    &__left {
         width: 100%;
    }

    &__content{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 0 0 26px;
        @include mq($screen-xl) {
            // flex-basis: 100%;
        }
    }

    &__right {
        @include mq($screen-xl) {
            // flex-basis: 600px;
        }

        display: flex;
        width: 100%;
        flex-direction: row;
        margin:0;
        padding: 0;
        @include mq($screen-m) {
            // flex-basis: 100%;
            flex-wrap: nowrap;
        }
    }

    &__print {
        display: block;
        font-family: $cart-list-item__name-font-family;
        font-size: $cart-list-item__name-font-size;
        font-weight: $cart-list-item__name-font-weight;
        line-height: $cart-list-item__name-line-height;
        text-transform: $cart-list-item__name-text-transform;
    }

    &__gift-dropdown{
        background-color: #FFFEFC;
    }

    &__qty {
        align-items: center;
        justify-content: flex-end;
        @include mq($screen-xl) {
            margin-left: 210px;
        }

        @include mq($screen-xl) {
            margin-right: 50px;
        }
    }
    &__input {
        &.input__field {
            height: auto;
        }
    }

    &__details {
        width:100%;
        display: flex;
        align-items: center;

        .cart-list-item__wrapper-price{
            display: none;
        }

        .button--icon .icon, .button--icon [class*="fa-"], .button--icon .button__icon {
            width: 20px;
            height: 20px;
            padding: 0;
            //fill: #af7c58;
            //color: #af7c58;
            transition: all 0.3s ease-in-out;
            z-index: 1;
            pointer-events: none;
        }
    }

    &__wrapper-print {
        @include mq($screen-xl) {
            // flex-basis: 45%;
            padding: 0 32px;
        }
    }

    &__wrapper-price {
        // width: 100%;
        display: flex;
        text-align: right;
        justify-content: flex-end;
        align-items: center;
        @include mq($screen-xl) {
            // flex-basis: 100%;
        }
    }

    &__name {
        font-weight: $cart-list-item__name-font-weight;
        font-size: $cart-list-item__name-font-size;
        padding: $cart-list-item__name-padding;
        width: auto;
    }

    &__stock {
        display: inline-block;
        font-family: $cart-list-item__name-font-family;
        font-size: $cart-list-item__name-font-size;
        font-weight: $cart-list-item__name-font-weight;
        line-height: $cart-list-item__name-line-height;
        text-transform: $cart-list-item__name-text-transform;
    }

    &__in-stock {
        padding: $cart-list-item__padding;
        border-bottom: $cart-list-item__border;

        .cart-list-item__content{
            display:block;

        }
        .cart-list-item__stock{
            font-size: 16px;
        }
        padding: 12px 0;

        @include mq($screen-m) {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &__action {
        margin-right: 0;
    }

    &__actions {

        @include mq($screen-m) {
            // flex-basis: 100%;
        }
        @include mq($screen-xl) {
            order: 2;
        }

        a.button--icon{
            display:none;
        }
        button.button--icon{
            padding: 0px;
            /* margin: 0px; */
            /* width: 4%; */
            text-align: left;
            display: flex;
            flex-direction: row;
            justify-content: left;
            @include mq($screen-xl) {
                justify-content: center;
            }
        }
        flex-flow: row nowrap;
        width: 100%;

        .gift-options-cart-item{
            display: none;
        }

    }
}

#failed-products-table {
    th {
        text-align: center;
    }

    td {
        &:before {
            content: unset;
        }
    }

    .cart-list-item {
        &__image {
            @include mq($screen-xl) {
                width: 100px;
            }
        }
        &__qty {
            @include mq($screen-m) {
                margin-left: 60px;
            }
        }
    }

    .row {
        & > .item {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
        }

        &.item {
            @include mq(0, $screen-m) {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .product-details {
        display: inline-flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: flex-start;
    }

    .item-content {
        display: inline-flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
}
