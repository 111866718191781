$actions-group__spacing               : $spacer--medium !default;
$actions-group__width                 : 100% !default;

$actions-group__handler-margin        : 0 0 $actions-group__spacing !default;
$actions-group__handler-margin\@medium: 0 !default;
$actions-group__handler-a-margin-left : $spacer--medium !default;

$actions-group__button-margin         : 0 0 $actions-group__spacing !default;
$actions-group__button-margin\@medium : 0 $actions-group__spacing auto 0 !default;
$actions-group__button-width          : 100% !default;
$actions-group__button-width\@medium  : auto !default;

$actions-group__link-margin           : 0 !default;
$actions-group__link-padding          : 0 !default;
$actions-group__link-text-decoration  : none !default;
$actions-group__link-background-clip  : content-box !default;
$actions-group__link-font-size        : $font-size-small !default;
$actions-group__link-font-weight      : $font-weight-medium !default;
