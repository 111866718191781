$widget-product-block-margin              : $spacer--large auto;
$widget-product-block-title-font-size     : $font-size-extra-large;
$widget-product-list-item-padding         : $spacer;
$widget-product-list-item-margin          : $spacer;
$widget-product-list-item-details-padding     : 0 $spacer $spacer--small $spacer; //$spacer--medium 0 0 0;
$widget-product-list-item-details-padding\@xl : 0 $spacer; //$spacer--medium 0 0 0;

$widget-product-list-item-name-padding            : 0 $spacer 0 0; //0 0 16px 0;
$widget-product-list-item-actions-padding         : $spacer--small 0 0 0;
$widget-product-list-item-actions-button-min-width: 120px;

$widget-product-list-item-price-padding : 0;

$widget-product-list-item-min-width : 250px;
$widget-product-list-item-max-width : 300px;

$widget-product-list-photo-min-height : auto; //360px;
