$input__field-color                              : $body-color;
$input__field-background-color                   : unset;
$input__field-padding                            : 0 $spacer--medium !default;
$input__field-spacing                            : $spacer--semi-large;
$input__field-border                             : $form-elements-border !default;
$input__field-border-radius                      : $form-elements-radius !default;
$input__margin-bottom                            : $spacer--medium !default;
$input__placeholder-color                        : $color-secondary !default;
$input__placeholder-font-size                    : $font-size-base !default;
$input__label-margin                             : 0 $spacer--medium 0 0 !default;
$input__label-focus-color                        : $color-primary !default;
$input__date-trigger-left                        : 0 !default;
$input__date-trigger-bottom                      : 0 !default;
$input__date-trigger-width                       : 100% !default;
$input__date-trigger-border                      : 0 !default;
$input__date-trigger-background                  : transparent !default;
$input__date-trigger-box-shadow                  : none !default;
$input__transition                               : $transition-base !default;
$input__white-space--inline                      : nowrap !default;

// Input password
$input__button-pass-bg                           : transparent !default;
$input__button-pass-top                          : 26px !default;
$input__button-pass-right                        : $form-element-border-width !default;
$input__button-pass-height                       : $input__field-spacing - (4 * $form-element-border-width) !default;
$input__button-pass-icon-fill                    : $color-secondary !default;

//textarea
$input__field-padding--textarea                  : $spacer !default;
$input__field-border-radius--textarea            : $form-elements-radius--small !default;
$input__field-line-height--textarea              : $font-line-height !default;
$input__min-height--textarea                     : 72px !default;

// disbaled
$input__background-disabled                      : $gray-light !default;
