.minicart-content {
    &__heading {
        padding: 0;
        text-align: center;
    }

    &__top {
        @include mq($max-screen: $screen-m) {
            padding: 0 0 20px 0;
        }
        border-color: $color-primary;
    }
    &__edit {
        text-transform: unset;
        font-size: $font-size-base ;
        @include mq( $screen-m) {
            font-size: $font-size-medium;
        }
    }

    &__checkout {
        font-weight: $font-weight-extra-light;
        font-size: $font-size-base ;
        @include mq( $screen-m) {
            font-size: $font-size-medium;
        }
    }

    &__counter {
        color: $color-primary;
        font-weight: $font-weight-extra-light;
        text-align: center;
    }
    &__bottom{
        @include mq($max-screen: $screen-m) {
            position: fixed;
            box-shadow: unset;
            padding: $spacer--semi-medium;
        }
        @include mq($max-screen: $screen-s) {
            top: calc(100vh - 160px);
            top: calc(100dvh - 160px);
            padding: $spacer--medium $spacer--medium 0;
            height: 120px;
        }
        border-color: $color-primary;
    }
    &__summary-label{
        text-transform: unset;
    }

    .minicart-product{
        &__field{
            &.cart-item-qty{
                &:disabled {
                    background: $white;
                    color: $color-primary;
                    text-align: center;
                    border: 0;
                    cursor: default;
                }
            }
        }
        &__image-link{
            .ratio-container{
                .ratio-image{
                    top: 45%;
                    &.lazyloaded {
                        max-height: 78px;
                    }
                }
            }

        }
    }

}
.body-open-minicart,
.body-open-menu {
    overflow: hidden;
}
