@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-BlackItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Black.woff2') format('woff2'),
    url('../fonts/SofiaPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Bold.woff2') format('woff2'),
    url('../fonts/SofiaPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-UltraLightItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-SemiBold.woff2') format('woff2'),
    url('../fonts/SofiaPro-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-UltraLight.woff2') format('woff2'),
    url('../fonts/SofiaPro-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-ExtraLight.woff2') format('woff2'),
    url('../fonts/SofiaPro-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Italic.woff2') format('woff2'),
    url('../fonts/SofiaPro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-Light.woff2') format('woff2'),
    url('../fonts/SofiaPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro Medium';
    src: url('../fonts/SofiaPro-Medium.woff2') format('woff2'),
    url('../fonts/SofiaPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-LightItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro.woff2') format('woff2'),
    url('../fonts/SofiaPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-BoldItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Sofia Pro';
    src: url('../fonts/SofiaPro-MediumItalic.woff2') format('woff2'),
    url('../fonts/SofiaPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

