// Colors
$white: #ffffff;
$black: #000000;

$gray-darker: #333333;
$gray-white :#fafafa;
$gray: #d8d8d8;

$brown            : #af7c58;
$pale-brown       : #f2d9bd;
$light-brown      : #fdf0df;
$lighter-brown    : #fdf6f0;
$lighter-brown-alt: #f8efe4;

$success           : #29922E;
$success-background: #C5F2C7;
$error             : #DA100B;
$error-background  : #FCD0CF;
$warning           : #FF7000;
$warning-background: #FBB961;

$info-background: #cce5ff;
$extra-pale-brown: #DFBCA0;

// Semantic Colors
//$color-primary      : $brown;
//$color-primary-light: $light-brown;
//$color-secondary    : $pale-brown;
$color-primary      : #4B4747;
$color-primary-light: #696161;
$color-secondary    : #747474;


// Background
$body-color    : $color-primary;
$bg-color-body : $lighter-brown;
$bg-color-base : $lighter-brown;
$bg-color-alt  : $lighter-brown-alt;

$body-background: $lighter-brown;

// Spacers
$spacer--extra-small : 4px !default;
$spacer              : 8px !default;
$spacer--small       : 12px !default;
$spacer--medium      : 16px !default;
$spacer--semi-medium : 24px !default;
$spacer--large       : 32px !default;
$spacer--semi-large  : 40px !default;
$spacer--extra-large : 48px !default;


// Layout
$max-content-width          : calc(100vw - 25px);
$container-fluid__padding-x : 8px;
$maincontent-margin-top     : 24px !default;

//Logo
$logo__image-height\@medium : 40px;

//Loader
$loader__border : 8px solid $color-primary !default;

// Border
$border-color-base      : $color-primary;
$border-width-base      : 1px;
$border-style-base      : solid;
$border-color-secondary : $color-secondary;
$border-width-secondary : 1px;
$border-style-secondary : solid;

$border-base      : $border-width-base $border-style-base $border-color-base;
$border-secondary : $border-width-secondary $border-style-secondary $border-color-secondary;

// Typography$font-size-extra-large
$font-family-sans-serif : 'Sofia Pro', sans-serif;
$font-family-serif : 'Sofia Pro', serif;
$font-family-medium : 'Sofia Pro Medium',  serif;
$font-family-base      : $font-family-sans-serif;
$font-family-secondary : $font-family-base;
$body-font-family      : $font-family-sans-serif;

$font-color-base       : $color-primary;

$font-weight-light       : 200;
$font-weight-extra-light : 300;
$font-weight-medium      : 400;
$font-weight-bold        : 700;

// Size
// $font-size-super-extra-large : 49px;
// $font-size-extra-large       : 39px;
// $font-size-large             : 25px;
// $font-size-medium            : 20px;
// $font-size-base              : 16px;
// $font-size-small             : 14px;
// $font-size-extra-small       : 11px;

$font-size-super-extra-large : 42px;
$font-size-extra-large       : $font-size-super-extra-large;
$font-size-large             : 30px;
$font-size-medium            : 16px; //$font-size-large;
$font-size-base              : $font-size-medium;
$font-size-small             : 14px;
$font-size-extra-small       : $font-size-small;
$font-size-small-xs          : 12px;
$font-size-extra-medium      : 18px;


// Paragraph
$paragraph-font-size : $font-size-base;

// Headings
$headings-color : $font-color-base;
$headings-text-transform       : none;
$headings-text-transform--page : none;

$heading-font-size--page\@screen-m         : 30px;
$heading-font-size--page                   : 24px;
$heading-font-size--first-level\@screen-m  : $font-size-super-extra-large;
$heading-font-size--first-level            : $font-size-extra-large;
$heading-font-size--second-level\@screen-m : $font-size-extra-large;
$heading-font-size--second-level           : $font-size-large;
$heading-font-size--third-level\@screen-m  : $font-size-large+5;
$heading-font-size--third-level            : $font-size-large;
$heading-font-size--fourth-level           : $font-size-large;
$heading-font-size--fifth-level            : $font-size-medium;
$heading-font-size--sixth-level            : $font-size-medium;

$heading-font-weight--fourth-level : 300;
$heading-font-weight--fifth-level  : 300;

$heading-letter-spacing--page\@screen-m        : 2.44px;
$heading-letter-spacing--page                  : 2.44px;
$heading-letter-spacing--first-level\@screen-m : 2.44px;
$heading-letter-spacing--first-level           : 2.44px;
$heading-letter-spacing--second-level\@screen-m: .31px;
$heading-letter-spacing--second-level          : .31px;
$heading-letter-spacing--fifth-level           : .15px;
$heading-letter-spacing--sixth-level           : .15px;

// Buttons
$button__background          : #fff;
$button__background-hover    : $light-brown;
$button__border              : 1px solid $color-primary;
$button__font-size           : $font-size-medium;
$button__font-weight         : 300;
$button__text-color          : $color-primary;
$button__text-transform      : lowercase;
$button__letter-spacing      : .28px;
// Hover
$button__border-hover        : 1px solid $button__background-hover;
// before used in hover state
$button__before-background   : $button__background-hover;
// after used in hover state
$button__after-display       : none;
// after hover
$button__after-display-hover : none;

// Secondary
$button__color--secondary               : $bg-color-body;
$button__border--secondary              : 1px solid $bg-color-body;
// Secondary hover
$button__color-hover--secondary         : $button__text-color;
$button__background-hover--secondary    : $button__before-background;
$button__border-hover--secondary        : $button__border-hover;
$button__after-display-hover--secondary : $button__after-display-hover;

// Link
$link__color-hover : $color-primary !default;

// - Icon
$button__bg--icon                      : unset;
$button__before-background--icon-light : unset;

// Form
$form-elements-border-focus : 1px solid $color-primary;
$form-elements-border-color : $border-color-secondary;

//checkbox
$checkbox__label-color : $color-primary;
$checkbox__icon-fill : $color-primary;
//Input
$input__field-border  : 1px solid $color-primary;
$input__required-color: $color-primary;

// List
$list__color : $body-color;

// Dropdowns
$dropdown-list__label-text-decoration--detailed-content: none;
$dropdown-list__label-bg-color                         : $bg-color-body;
$dropdown-list__label-bg-color-hover                   : $bg-color-body;
$dropdown-list__label-bg-color--open                   : $bg-color-body;
$dropdown-list__label-color                            : $color-primary;
$dropdown-list__label-color--inner                     : $color-primary;
$dropdown-list__label-color-hover                      : $color-primary;
$dropdown-list__icon-fill--with-nested                 : $color-primary;

$dropdown-list__bg-color : $bg-color-body;

// Header
$side-menu__bq-visibility-max          : 99999px; // Hacemos que siempre sea visible.
$header__minicart-border\@medium       : 0;
$header__minicart-border-width\@medium : 0;
$header__border                        : unset;
$header__height                        : 100px;
$header__height\@mobile                : 70px;

// Breadcrumb
$breadcrumbs__display\@mobile : block;
$breadcrumbs__display-mq      : 768px;

// Footer
$footer__background-color          : $bg-color-body;
$footer__link-color                : $body-color;
$footer__links-dropdown-background : $footer__background-color;
$footer__links-label-background    : $footer__background-color;
$footer__border                    : $border-width-base $border-style-base $color-primary !default;
// - Copyright
$footer__copywrite-color           : $footer__link-color;
// - Bottom bar
$footer__bottom-bar-background     : $footer__background-color;
$footer__bottom-bar-color          : $footer__link-color;

//Cms Page
$cms-page__max-width         : 95%;
$cms-page__max-width\@medium : 90%;
$cms-page__max-width\@large  : 100%;
$cms-page__max-width\@xlarge : 100%;
$cms-page__margin            : 0 auto;
$cms-contact__max-width      : 100%;

//Dashboard
$dashboard-nav__link-bg-current : $lighter-brown;
$dashboard-nav__link-bg-hover   : $lighter-brown;

$dashboard-nav__link-color-hover          : $link__color-hover !default;
$dashboard-nav__link-text-decoration-hover: none !default;

$dashboard__subtitle-font-size   : $font-size-base !default;
$dashboard__subtitle-font-weight : 300 !default;

$dashboard__subtitle-info-font-size   : $font-size-small !default;
$dashboard__subtitle-info-font-weight : 200 !default;

$dashboard__content-padding\@extra-large: 0 $spacer 0 $spacer--extra-large;

//List product
$product-list-item__name-font-size         : $font-size-large;//$spacer--semi-medium;
$product-list-item__name-font-size\@medium : $font-size-large; //$spacer--semi-medium;

$product-list-item__price-font-size            : $product-list-item__name-font-size;
$product-list-item__price-font-size\@medium    : $product-list-item__name-font-size\@medium;
$product-list-item__price-old-font-size\@medium: $font-size-base;

$product-list-item__name-margin\@xlarge : 0 $spacer--semi-large $spacer 0;

$checkout-shipping-item-tablet__width   : 100%;

$catalog-list__banner-padding                 : $spacer 0 !default;
$catalog-list__banner-padding\@medium         : $spacer--medium 0 !default;

$catalog-grid__template-columns                 : repeat(1, 1fr) !default;
$catalog-grid__template-columns\@small          : repeat(2, 1fr) !default;
$catalog-grid__template-columns\@medium         : repeat(4, 1fr) !default;

//cart
$minicart-product__attributes-color            : $color-primary;
$minicart-product__attributes-label-font-weight: $font-weight-medium;
$minicart-content__summary-value-font-weight   : $font-weight-medium;
$cart-list-item__name-font-size                : $font-size-base;
$cart-list-item__label-color                   : $color-primary;
$cart-list-item__price-font-size\@medium       : $font-size-base;

//Sumary

$order-summary__amount-font-weight             : $font-weight-medium;
$order-summary__label-font-size                : $font-size-base;

$order-summary__title-font-weight              : $font-weight-medium;
$order-summary__title-font-size                : $font-size-base;
$order-summary__title-total-font-weight        : $font-weight-medium;

$order-summary__title-total-font-size          : $font-size-base;
$order-summary__title-total-font-weight        : $font-weight-extra-light;
$order-summary__list-item-min-height           : $spacer--large;

$order-summary__amount-font-size               : $font-size-base;

//checkout
$checkout__max-width\@medium                   : 100%;
$shipping-information__padding                 : 0 $spacer--semi-medium $spacer--medium;


//Loader
$loader__background                             :  rgba(253, 246, 240, 0.5) !default;
