$search-form__input-height\@medium : 28px;
$search-form__input-width\@medium  : 170px;

.search-form{
    @include mq($screen-m) {
        width: 85%;
    }

    @include mq($screen-l) {
        width: 90%;
    }

    @include mq($screen-xxl) {
        width: 100%;
    }
    &.processing{
        .icon-container {
            position: absolute;
            right: -5px;
            top: calc(50% - 10px);
            display: block !important;
            @include mq($screen-m) {
                right: -10px;
            }
        }
    }
  &__input-wrapper{
    @include mq($screen-m) {
      margin-left: 50px;
      max-width: $search-form__input-width\@medium;
    }
    .nested {
      display: none;
    }
  }
  &__input{
    border: unset;
    border-bottom: 1px solid $color-primary;
    font-weight: $font-weight-extra-light;
    &::placeholder {
      color: $gray;
    }
    @include mq($screen-m) {
      background: unset;
      height: $search-form__input-height\@medium;
      min-height: $search-form__input-height\@medium;
      padding-left: 0;
      outline: unset !important;
      &::placeholder {
        color: $color-primary;
      }
      &:focus {
        border-top: unset;
        border-right: unset;
        border-left: unset;
      }
    }
  }

  &__button{
    border: 0;
    height: $search-form__input-height\@medium;
    left: -50px;
    min-height: $search-form__input-height\@medium;
    position: absolute;
    top: 0;
    width: 20px;
    @include mq($max-screen: $screen-m - 1) {
      display: none !important;
    }
    &:hover{
        background-color: unset;
    }
  }
}
