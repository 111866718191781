.account-links,
#customer-dropdown .dropdown-list__list {
    margin-left: 0;
    padding-left: 0;
    position: relative;
    white-space: nowrap;
    @include mq($screen-m) {
        margin-left: $spacer--medium;
        padding-left: $spacer--medium;
    }
    @media (min-width: $screen-m) and (max-width: $screen-m + 81)  {
        margin-left: 0;
        padding-left: 0;
    }
    .icon-user{
        display: inline-block;
        position: relative;
        top: 1px;
        width: 18px;
        height: 18px;
        margin-right: $spacer;
    }


    .customer-welcome {
        padding: 0 !important;
    }
    .authorization-link {
        padding: 0 $spacer--medium 0 0 ;//$header__switcher-label-padding;
        a {
            //line-height: 1rem;
            //font-weight: $font-weight-extra-light;
            position: relative;
            top: -1px;
            .icon-user {
                fill: $color-primary;
            }
        }
    }
    .dropdown-list__label {
        button {
            line-height: 1rem;
        }
    }
}
