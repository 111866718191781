.modal-popup{
    .modal__close-button-icon{
        fill: $color-primary !important;
    }

    &.confirm{
        z-index: 9999;
        .modal-footer{
            display: flex;
            flex-direction: column;
            @include mq($screen-m) {
                flex-direction: row;
            }
            button{
                margin-bottom: $spacer--medium;
                width: 100%;
                @include mq($screen-m) {
                    width: auto;
                }
            }
        }
    }
    &.agreements-modal{
        .modal-footer{
            button.action.button--secondary{
                color: $color-primary;
                border: 1px solid $color-primary;
                background: $color-primary;
            }
        }
    }
    .modal-footer {
        .button--secondary {
            border: 1px solid $color-primary;
            margin-right: 15px;
        }
    }

    &.modal-slide{
        &.product-view__modal {
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            pointer-events: none;
            touch-action: none;
            @include mq($screen-m) {
                grid-template-columns: repeat(3, 1fr);
            }
            .modal-inner-wrap {
                background-color: $bg-color-body !important;
                box-shadow: unset;
                grid-column-start: 1;
                grid-column-end: 3;
                width: 100%;
                height: 100vh;
                @include mq($screen-m) {
                    margin: auto;
                    grid-column-start: 3;
                    width: 95%;
                    transform: translateX(100%);
                    transition-duration: 0.3s;
                    transition-timing-function: ease-in-out;
                }

                .modal-body-content {
                    padding: $spacer--extra-large $spacer--semi-medium;
                    .items {
                        display: flex;
                        flex-wrap: wrap;
                        .content {
                            min-height: unset;
                            width: 100%;
                            @include mq($screen-m) {
                                min-height: 690px;
                            }
                        }
                        .item {
                            &.title {
                                display: inline-flex;
                                justify-content: center;
                                width: 50%;
                                display: none;
                            }
                        }
                        .table {
                            white-space: normal;
                            width: 100%;
                            th[scope="row"] {
                                width: 25%;
                            }
                            &.table--scope-row tbody tr:last-child td {
                                border-bottom: 1px solid;
                            }
                        }
                    }
                }
                .modal-header {
                    display: flex;
                    justify-content: end;
                    .modal__close-button {
                        top: $spacer--semi-large*2;
                    }
                }
            }
            &._show {
                ~ .modals-overlay {
                    background-color: rgba(0, 0, 0, 0.7);
                }
                .modal-inner-wrap {
                    transform: translateX(0);
                }
            }
        }

        &.requisition-popup.add_to_requisition_popup {
            .modal-footer {
                button.button--secondary,
                button.primary.confirm {
                    @extend .button--secondary;
                }
            }
        }

        &.popup-authentication {
            .modal-inner-wrap {
                background-color: $bg-color-body;
                border: $border-base;
                box-shadow: unset;
                margin-top: 20%;
                min-height: auto !important;
                width: 100%;
                @include mq($screen-m) {
                    margin-top: 20%;
                }
                @include mq($screen-l) {
                    margin-top: 10%;
                    width: 95%;
                }
                @include mq($screen-xxl) {
                    margin-top: 15%;
                    width: 60%;
                }
                .modal-header {
                    padding: 0;
                }
                .modal-content {
                    padding: $spacer--large;
                    margin-top: $spacer--large;
                    @include mq($screen-m) {
                        margin-top: 0;
                        padding: $spacer--large $spacer--large $spacer--small $spacer--large;
                    }

                }

                .container {
                    &.auth {
                        display: flex;
                        align-content: center;
                        justify-content: space-between;
                        align-items: flex-start;
                        flex-direction: column;
                        margin: 0;
                        @include mq($screen-m) {
                            flex-direction: row;
                        }
                        .messages {
                            position: absolute;
                            left: 3%;
                            top: 10px; // $spacer--small/2;

                            @include mq($screen-m) {
                                left: $spacer--medium;
                            }
                            @include mq($screen-l) {
                                left: 10%;
                            }
                            @include mq($screen-xxl) {
                                left: 3%;
                            }
                        }
                        .account-form {
                            padding: 0;
                            width: 100%;
                            @include mq($screen-m) {
                                width: 50%;
                            }
                            h1 {
                                font-size: $font-size-small;
                                margin-bottom: 0;
                                text-transform: uppercase;
                            }
                            &__login {
                                padding-right: 0;
                                @include mq($screen-l) {
                                    padding-right: $spacer--semi-large;
                                }
                            }
                            &__new {
                                margin-top: $spacer--large;
                                padding-left: 0;
                                @include mq($screen-m) {
                                    margin-top: 0;
                                    padding-left: $spacer--semi-large;
                                }
                                .account-form__info {
                                    padding-right: 0;
                                    @include mq($screen-m) {
                                        padding-right: 35%;
                                    }
                                    @include mq($screen-l) {
                                        padding-right: 55%;
                                    }
                                }
                                .account-form__input {
                                    margin-top: 0;
                                    @include mq($screen-m) {
                                        margin-top: 29px;
                                    }
                                }

                            }
                            &__fieldset {
                                margin-bottom: 0;
                                max-width: unset;
                            }

                            &__form {
                                align-items: start;
                            }
                            &__info {
                                font-size: $font-size-small-xs;
                                font-weight: $font-weight-extra-light;
                                margin: $spacer 0 $spacer--medium  0;
                                text-align: left;
                            }
                            &__input {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                position: relative;
                                margin-bottom: $spacer;
                                label {
                                    display: flex;
                                    align-items: center;
                                    font-size: $font-size-small;
                                    font-weight: $font-weight-extra-light;
                                    margin: 0;
                                    width: 28%;
                                    @include mq($screen-l) {
                                        width: 30%;
                                    }
                                }
                                .input__field {
                                    width: 70%;
                                }
                                .mage-error {
                                    &:not(.input__field) {
                                        font-size: $font-size-small-xs;
                                        margin: 0 0 $spacer 0;
                                        padding-left: 30%;
                                        width: 100%;
                                    }
                                }
                            }
                            &__actions {
                                padding-left: 0;
                                margin: 0;
                                width: 100%;
                                @include mq($screen-m) {
                                    //padding-left: 150px;
                                    padding-left: 28%;
                                    align-items: end;
                                }
                                @include mq($screen-l) {
                                    //padding-left: 220px;
                                    padding-left: 30%;
                                }
                            }
                            &__button {
                                padding-top: $spacer--extra-small;
                                text-transform: uppercase;
                                letter-spacing: 1.5px;
                            }

                            &__forgot-password {
                                color: $body-color;
                                font-size: $font-size-small-xs;
                                font-weight: $font-weight-medium;
                                margin-top: 0;
                            }
                            &__validation-tips {
                                font-size: 10px;
                                text-align: right;
                                margin-bottom: $spacer--extra-small;
                            }
                        }
                    }
                }
            }
        }
    }

}
.modals-wrapper {
    .modals-overlay {
        z-index: 999 !important;
    }
}
.checkout-index-index {
    .modals-wrapper {
        .modal-popup {
            .modal-inner-wrap {
                @include mq($screen-l) {
                    width: 50%;
                }
            }
        }
    }
}
