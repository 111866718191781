.cms-index-index {
    .header {
        position: fixed;
    }
    @-moz-document url-prefix() {
        .page-wrapper{
            overflow-x: hidden;
        }
    }
}
