.amgdprcookie{
    &-text,
    &-link{
        color: $color-primary !important;
    }
    &-caption{
        font-weight: $font-weight-medium !important;
    }
    &-text{
        font-size: $font-size-small !important;
    }
    &-link{
        cursor: pointer;
    }
    &-toggle-cookie{
        .amgdprcookie-input:checked:not([disabled])+.amgdprcookie-label{
            background: $color-primary !important;
        }
    }
    &-policy-container{ 
        @include mq($max-screen: $screen-m) {
            padding: 5px !important;
        }
    }
}
