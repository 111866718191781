$radio__size                    : 18px !default;
$radio__dot-size                : 8px !default;
$radio__margin                  : 0 0 $spacer--medium 0 !default;
$radio__label-font-size         : $font-size-base !default;
$radio__label-color             : $color-secondary !default;
$radio__label-color-active      : $color-primary !default;
$radio__label-font-weight-active: $font-weight-normal !default;
$radio__label-line-height       : $font-line-height !default;
$radio__label-margin            : $spacer--small 0 !default;
$radio__icon-margin-right       : $spacer !default;
$radio__icon-border             : 2px solid $color-primary !default;
$radio__icon-background         : $color-primary !default;
$radio__icon-transition         : $transition-base !default;
$radio__legend-margin           : $radio__margin !default;
$radio__text-width              : calc(100% - #{$radio__icon-margin-right + $radio__size}) !default;
