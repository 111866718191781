.account {
    .dashboard-nav {

        .nav.item,
        .nav.item.current {
            padding: 8px 0;
            strong,
            span {
                padding: 0 16px;
                vertical-align: middle;
            }
            strong {
                position: relative;
                top: -1px;
                @include mq($screen-m) {
                   top: auto;
                   margin-left: -4px;
                }
            }
            > a:after,
            &:after {
                content: none;
            }
            &:hover {
                background: none;
            }
        }
        .nav.item{
            > a{
                display: inline-block;
            }
        }
        .nav.item:not(.current) > a {
            padding: 0 16px;
            &:hover {
                background: none;
            }
        }

        .current,
        .nav.item,
        .nav.item.current {
            @include mq($screen-m) {
                background: none;
            }
            &:after {
                content: none;
            }
        }

        .current,
        .nav.item.current {
            &:before {
                @include mq($screen-m) {
                    content: '';
                    border: 2px solid $color-primary;
                    background-color: $color-primary;
                }
            }
        }

    }
}
