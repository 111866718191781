@import 'filter-variables';

.filter {
    //Filter options custom design
    &-actives {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        .active-filters__heading {
            display: inline-flex;
            align-items: center;
            padding: 0 0 $spacer 0;
        }
        .list {
            display: inline-flex;
        }
    }
    &-options {
        display: flex;
        justify-content: center;
        &-item {
            position: relative;
            &.active {
                .icon {
                    fill: $color-primary;
                    transform: rotateZ(-180deg);
                }
            }
        }
        &-title {
            position: relative;
            span {
                padding-right: 24px;
            }
            .icon {
                fill: $color-primary;
                right: 0;

            }
        }
        &-content {
            background-color: $bg-color-alt;
            left: -100px;
            min-width: 330px;
            padding: $spacer--medium;
            position: absolute;
        }

    }
    &--checkbox {
        .filter__content {
            max-height: $filter__content-max-height--checkbox;
        }

        .checkbox__label {
            color: $color-primary;
            font-size: $filter__label-font-size--checkbox;
        }
        .checkbox--link {
            margin: $filter__margin--checkbox-link;
            @include mq($screen-m) {
                margin: $filter__margin--checkbox-link\@medium;
            }
        }
        .checkbox--link-checked {
            .checkbox__label {
                font-weight: $filter__label-font-wieght-checked--checkbox;
            }
        }
    }

    &--category {
        @include mq($screen-l) {
            padding: $filter__padding--category\@large;
            border: $filter__border--category\@large;
            border-width: $filter__border-width--category\@large;
        }
    }

    &__heading {
        margin: $filter__heading-margin;
        font-family: $filter__heading-font-family;
        font-size: $filter__heading-font-size;
        text-transform: $filter__heading-text-transform;
    }

    &__content {
        position: relative;
        // overflow-y: $filter__content-overflow-y;
        padding: $filter__content-padding;
        @include mq($screen-m) {
            padding: $filter__content-padding\@medium;
        }
        @include mq($screen-l) {
            padding: $filter__content-padding\@large;
        }
    }

    &__label-wrapper {
        //display: flex;
        display: none;
        @include mq($screen-m) {
            padding: $filter__label-wrapper-padding\@medium;
        }
        @include mq($screen-l) {
            padding: $filter__label-wrapper-padding\@large;
        }
    }

    &__list {
            columns: 1;
            -webkit-columns: 1;
            -moz-columns: 1;
        @include mq($screen-m) {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
        }
    }

    &__swatch-item {
        margin: $filter__swatch-margin;

        @include mq($screen-l) {
            display: flex;
            justify-content: flex-start;
            flex-basis: auto;
        }

        &.selected {
            .filter__swatch-option {
                border-color: $filter__swatch-option-selected-border-color;
            }
        }
    }

    &__swatch-option {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: $filter__swatch-option-size;
        min-height: $filter__swatch-option-size;
        border: $filter__swatch-option-border;
        background-color: $filter__swatch-option-background;
        color: $filter__swatch-option-color;
        transition: $filter__transition;
        border-radius: 50%;
        cursor: pointer;
        text-decoration: none;
        margin-left: $spacer--semi-medium;
        &:hover,
        &.focus-visible {
            text-decoration: none;
            border-color: $filter__swatch-option-border-color-hover;
            font-weight: $font-weight-bold;
            color: $color-primary;
        }

        &--active {
            font-weight: $font-weight-bold;
            border-color: $filter__swatch-option-border-color--active;
        }
    }

    &__color-item {
        padding: $filter__color-padding;
        margin: $filter__swatch-margin;

        &.selected {
            .filter__color-option {
                &:after {
                    border-color: $filter__color-option-after-border-color;
                }
            }
        }
    }

    &__color-option {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-width: $filter__color-option-width;
        width: $filter__color-option-width;
        height: $filter__color-option-height;
        max-height: $filter__color-option-height;
        text-decoration: none;
        margin: $filter__color-option-margin;
        &:after {
            content: '';
            position: absolute;
            top: $filter__color-option-after-top;
            left: $filter__color-option-after-left;
            width: $filter__color-option-after-size;
            height: $filter__color-option-after-size;
            background-color: transparent;
            // border: $filter__color-option-border;
            border-radius: $filter__color-option-after-border-radius;
            transition: $filter__transition;
        }

        &--active,
        &:hover,
        &.focus-visible {
            text-decoration: none;

            &:after {
                border-color: $filter__color-option-after-border-color;
            }
        }
    }

    &__color-sample {
        width: $filter__color-sample-size;
        height: $filter__color-sample-size;
        margin: $filter__color-sample-margin;
        border: $filter__color-sample-border;
        border-radius: $filter__color-sample-border-radius;
    }

    &__color-label {
        max-width: 100%;
        font-size: $filter__color-label-font-size;
        color: $filter__color-label-color;
        word-break: break-all;
        text-align: center;

        @include visually-hidden();
    }
}

.catalog-list__filters {
    //background-color: $light-brown;
    display: none;
    .filters {
        &__modal {
            display: block !important;
            @include mq($max-screen: $screen-l) {
                z-index: 999;
            }
        }
        &__item {
            margin: 0;
            .filters__modal {
                .filters__container {
                    //background-color: $light-brown;
                    @include mq($max-screen: $screen-s) {
                        padding: $filter__container-padding;
                        .filters__content {
                            max-height: 90%;
                        }
                    }
                    .active-filters {
                        padding: 0;
                        border: none;
                    }
                }
                &.modal--active {
                    @include mq($max-screen: $screen-s) {
                        z-index: 999999;
                    }
                }
            }
            .dropdown-list__list {
                .filter-options {
                    @include mq($max-screen: $screen-l) {
                        flex-direction: column;
                    }
                }
            }
        }
    }


   .block-content.filter-content {
        padding: $spacer--semi-large;
        display: flex;
        flex-direction: column;
        .filter-options-title {
            font-weight: $font-weight-medium;
        }
        .active-filters.filter-actives {
            display: flex;
            justify-content: flex-end;
            order: 2;
        }
        //.dropdown-list {
        //    background-color: $light-brown;
        //}
    }
    .filter-options-content {
        position: relative;
        background-color: transparent;
        left: 0;
        padding: $spacer--medium 0;
        .dropdown-list__item  {
            border-bottom: none;
            .checkbox.checkbox--link, .checkbox.checkbox--link + div {
                .checkbox__field {
                    display: none;
                    width: $spacer--extra-small;
                    height: $spacer--extra-small;
                }
                .checkbox__label {
                    padding: $spacer $spacer--semi-medium;
                    font-size: $font-size-small;
                    font-weight: $font-weight-light;
                    &::after {
                        content: none;
                    }
                }
                &.checkbox--link-checked,  &.checkbox--link-checked + div {
                    .checkbox__label {
                        // text-decoration: underline;
                    }
                }
            }
            .checkbox__icon--checked {
                display: none;
            }
            .checkbox__icon--unchecked {
                display: block;
            }
            .checkbox--link-checked {
                display: flex;
            }
            .checkbox--link-checked, .filter-active {
                .checkbox__icon--checked{
                    display: block;
                    fill: $filter__swatch-option-selected-border-color;
                    background: $filter__swatch-option-selected-border-color;
                    border-radius: $spacer--extra-small;
                    opacity: 1;
                    width: $filter__swatch-option-selected-size;
                    height: $filter__swatch-option-selected-size;
                }
                .checkbox__icon--unchecked {
                    display: none;
                }
            }
            .checkbox__icon {
                width: $spacer--medium;
                height: $spacer--medium;
            }
        }
        .filters__modal {
            background-color: transparent;
            .filters__container {
                .categories-list__link {
                    padding: $spacer 0;
                    text-transform: capitalize;
                }
            }
            .modal__heading {
                display: none;
            }
            .filter--category {
                border: none;
                .categories-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

        }
        .swatch-layered {
            padding: $filter__swatch-wrapper-padding;
            ul {
                li {
                    position: relative;
                    .checkbox__field {
                        z-index: unset;
                    }
                    a {
                        &::after {
                            content: none;
                        }
                        div {
                            border: none;
                            margin: $filter__swatch-option-margin;
                            border-radius: $filter__color-border-radius-selected;
                            width: $filter__color-option-width;
                            height: $filter__color-option-width;
                            margin-left: $spacer--semi-medium;
                            min-width: $filter__color-option-width;
                        }

                    }
                    &.selected div, .filter-active div{
                            border: 1px solid $filter__color-border-bg-selected;
                            border-radius: $filter__color-border-radius-selected;
                            max-width: $filter__color-option-width;
                            width: $filter__color-option-width;
                            height: $filter__color-option-width;
                            div {
                                width: $filter__color-option-width;
                                height: $filter__color-option-width;
                                margin: 1px;
                            }

                    }
                    &.disabled {
                        display: none;
                    }
                    &.unavailable {
                        a div {
                            opacity: 0.5;
                        }
                    }
                }
            }
            .checkbox {
                &__label {
                    padding-left: $spacer;
                    text-transform: capitalize;
                    margin-bottom: 0;
                    font-size: $font-size-small;
                    font-weight: $font-weight-light;
                    padding: $spacer;
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
        ol {
            &.items {
                li {
                    position: relative;
                    .checkbox__field {
                        z-index: unset;
                    }
                }
            }
        }
    }
}
.block-actions {
    background-color: $filter__swatch-option-background;
    display: flex;
    justify-content: end;
    @include mq($max-screen: $screen-s) {
        justify-content: center;
    }
    .filter-current {
        margin-right: $spacer--semi-large;
    }
}

.page-with-filter {
    .filters__modal {
        .filters__container {
            background-color: $filter__swatch-option-background;
        }
    }
}
.body-open-filters {
    overflow-y: hidden;
    touch-action: none;
    -ms-touch-action: none;
}
